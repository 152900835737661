import React, { createContext, useState, useContext, useMemo } from 'react'
import api from '../api'

const FilterContext = createContext()

export function FilterProvider({ children }) {
  /*
  Filter Logic
  */
  // Current selected filters
  const [filterSets, setFilterSets] = useState([
    { filter: [{}], filterAndOr: { value: 'and', label: 'All' }, options: [] },
  ])
  // List of potential categories
  const [filterCategories, setFilterCategories] = useState([])

  // Add filter to filter sets!
  const addFilter = async ({ key, value, snackbarFunctions }) => {
    // 1. Create filter list
    let newFilterList = filterSets
    let newFilter = {
      filter: [{ category: { group: 'demographic', value: '' }, option: {} }],
      filterAndOr: { value: 'and', label: 'All' },
      options: [],
    }

    // 2. Crete new filter
    let newOptions
    try {
      newOptions = await api.filters.getFilterOptions(key)
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: 'Error getting initial options',
        type: 'danger',
      })
      console.log(err)
      return
    }
    // Set new filter values
    newFilter.options = [newOptions]
    newFilter.filter[0].category.value = key
    newFilter.filter[0].category.label = key
    newFilter.filter[0].option.value = value
    newFilter.filter[0].option.label = value

    // 3. Append new filter
    newFilterList.push(newFilter)

    // 4. Set New Filter List
    setFilterSets(newFilterList)
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      filterSets,
      setFilterSets,
      filterCategories,
      setFilterCategories,
      addFilter,
    }),
    [filterSets, filterCategories]
  )

  return <FilterContext.Provider value={memoedValue}>{children}</FilterContext.Provider>
}

// Let's only export the `useLocation` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useFilter() {
  return useContext(FilterContext)
}
