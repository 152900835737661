import React, { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import PropTypes from 'prop-types'

import './Table.scss'

const Table = ({ handleModal, data, download }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'School',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Emails',
        accessor: 'emails',
        Cell: props => (props.value ? props.value.toLocaleString() : 0),
      },
      {
        Header: 'Numbers',
        accessor: 'phones',
        Cell: props => (props.value ? props.value.toLocaleString() : 0),
      },
      {
        Header: 'Users',
        accessor: 'users',
        Cell: props => (props.value ? props.value.toLocaleString() : 0),
      },
      {
        Header: '30 Day: Completes',
        accessor: 'uniqueRecentSubmits',
        Cell: props => (props.value ? props.value.toLocaleString() : 0),
      },
      {
        Header: '90 Day Opens',
        id: 'openRate',
        accessor: d => Number(d['openRate']), // Strange numbers fixes sorting
        Cell: props => (props.value ? (props.value * 100).toFixed(2) + '%' : 'NA'),
        sortType: 'basic',
      },
      {
        Header: '90 Day Clicks',
        id: 'clickRate',
        accessor: d => Number(d['clickRate']),
        sortType: 'basic',
        Cell: props => (props.value ? (props.value * 100).toFixed(2) + '%' : 'NA'),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  return (
    <section className=" box has-background-grey-lighter">
      <table className="table is-fullwidth has-background-grey-lighter" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  className={column.isSorted ? 'has-text-info' : undefined}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <img alt="down" width="15" className="is-arrow is-inline" src="/down.svg" />
                      ) : (
                        <img alt="up" width="15" className="is-arrow is-inline" src="/up.svg" />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      className={
                        row.cells.indexOf(cell) === 0 // Give school a background
                          ? 'box has-background-success has-text-white has-text-weight-bold'
                          : undefined
                      }
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
                <td className="is-marginless is-paddingless button-container">
                  <button className="button is-primary" onClick={download}>
                    <img className="button-icon" alt="Download" src="/icons8-download-60.png" />
                  </button>
                  <button
                    onClick={() => {
                      handleModal('send-email', {
                        preview: true,
                        data: data.find(e => e.name === row.cells[0].value), // Find school by title
                      })
                    }}
                    className="button is-success"
                    style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
                  >
                    <img className="button-icon" alt="Send Email" src="/icons8-send-email-60.png" />
                  </button>
                  <button
                    onClick={() => {
                      handleModal('send-sms', {
                        preview: true,
                        data: data.find(e => e.name === row.cells[0].value), // Find school by title
                      })
                    }}
                    className="button is-success"
                    style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
                  >
                    <img className="button-icon" alt="Send Sms" src="/icons8-sms-60.png" />
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}

Table.propTypes = {
  download: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  data: PropTypes.array,
}

export default Table
