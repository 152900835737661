import request from './request'
import prefix from './prefix'
import Promise from 'bluebird'

export const send = async data => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.post(prefix + '/api/panel/outreach/send').send(data)

      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * A route to get the targes for outreach given target data.
 * @param {Object} data The data to send to the server.
 * @param {String} data.surveyId
 * @param {String} data.sendType
 * @param {String} data.templateName
 * @param {Array|Null} data.selectedInstitutionsList
 * @param {String} data.sendChannel
 * @param {String} data.sampleName
 * @returns {Promise} Resolves with the response body, and gives the number of tragets.
 */
export const getTargets = async data => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.post(prefix + '/api/panel/outreach/available-targets').send(data)

      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}

export const getSchoolsNeedingOutreach = async ({ surveyId, sendType }) => {
  sendType = encodeURIComponent(sendType)
  surveyId = encodeURIComponent(surveyId)

  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(
        prefix + `/api/panel/outreach/schools-for-survey/${sendType}/${surveyId}`
      )
      return resolve(res.body)
    } catch (err) {
      console.log('err: ', err)
      reject(err)
    }
  })
}
