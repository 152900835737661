import request from './request'
import prefix from './prefix'
import { formatFilters } from './filters'
import Promise from 'bluebird'

export const scheduleEmails = ({
  schedules,
  filters,
  andor,
  campaignName,
  templateId,
  messageLimit,
  subject,
  selectedSchoolId,
  message,
  surveyId,
  maxEmails,
  maxEmailsPerSchool,
  globalVariables,
  onlyUsers,
  onlyEdVisors,
  allowAlumni,
  filterSets,
  provider,
  onlyPersonal,
  ...rest
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Format data
      const formattedQuery = formatFilters(filterSets)

      if (selectedSchoolId) {
        formattedQuery.push([{ variable: 'school', value: selectedSchoolId }])
      }

      let data = {
        filters: formattedQuery,
        campaignName: campaignName,
        templateId: templateId,
        messageLimit: messageLimit,
        subject: subject,
        message: message,
        surveyId: surveyId,
        maxEmails: maxEmails,
        maxEmailsPerSchool,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        allowAlumni,
        provider,
        onlyPersonal,
        ...rest,
      }

      await Promise.mapSeries(schedules, async schedule => {
        let date = schedule.utc().toDate()

        await request.post(prefix + '/api/scheduledjobs/create').send({
          date,
          data,
          type: 'email-job',
        })

        console.log('Created a job')

        return
      })

      resolve()
    } catch (err) {
      //Error
      console.log(err)
      reject()
    }
  })
}

export const generateEstimate = async ({
  filters,
  andor,
  campaignName,
  templateId,
  messageLimit,
  subject,
  selectedSchoolId,
  message,
  surveyId,
  maxEmails,
  maxEmailsPerSchool,
  globalVariables,
  onlyUsers,
  onlyEdVisors,
  allowAlumni,
  filterSets,
  provider,
  onlyPersonal,
  ...rest
}) => {
  const formattedQuery = formatFilters(filterSets)
  if (selectedSchoolId) {
    formattedQuery.push([{ variable: 'school', value: selectedSchoolId }])
  }
  let data = {
    filters: formattedQuery,
    campaignName: campaignName,
    templateId: templateId,
    messageLimit: messageLimit,
    subject: subject,
    message: message,
    surveyId: surveyId,
    maxEmails: maxEmails,
    maxEmailsPerSchool,
    globalVariables,
    onlyUsers,
    onlyEdVisors,
    allowAlumni,
    provider,
    onlyPersonal,
    ...rest,
  }
  try {
    let res = await request.post(prefix + '/api/panel/getAccurateEstimate').send(data)
    const counts = res.body
    return counts
  } catch (err) {
    return { error: err }
  }
}

export const sendEmail = ({
  filters,
  andor,
  campaignName,
  templateId,
  messageLimit,
  subject,
  selectedSchoolId,
  message,
  surveyId,
  maxEmails,
  maxEmailsPerSchool,
  globalVariables,
  onlyUsers,
  onlyEdVisors,
  allowAlumni,
  filterSets,
  provider,
  onlyPersonal,
  ...rest
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Format data
      const formattedQuery = formatFilters(filterSets)

      if (selectedSchoolId) {
        formattedQuery.push([{ variable: 'school', value: selectedSchoolId }])
      }

      let data = {
        filters: formattedQuery,
        campaignName: campaignName,
        templateId: templateId,
        messageLimit: messageLimit,
        subject: subject,
        message: message,
        surveyId: surveyId,
        maxEmails: maxEmails,
        maxEmailsPerSchool,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        allowAlumni,
        provider,
        onlyPersonal,
        ...rest,
      }

      let res = await request.post(prefix + '/api/panel/send-campaign').send(data)
      if (res && res.body && res.body.error) return reject(res.body.error)
      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const sendSelf = dataIn => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.post(prefix + '/api/panel/send2self').send(dataIn)
      if (res && res.body && res.body.error) return reject(res.body.error)
      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const getEmails = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + '/api/panel/campaigns')

      if (res && res.body && res.body.error) return reject(res.body.error)

      // Format filters for front-end
      res.body.forEach(row => {
        let formattedFilters = []
        const filters = row.filters || []
        filters.forEach(outer => {
          outer.forEach(inner => {
            formattedFilters.push({
              category: {
                value: inner.variable,
                label: inner.variable,
              },
              option: {
                value: inner.value,
                label: inner.value,
              },
            })
          })
        })

        row.filters = formattedFilters
        row.filterAndOr =
          row.filters[0] && row.filters[0][1]
            ? { value: 'or', label: 'Any' }
            : { value: 'and', label: 'All' }
      })

      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const campaignNames = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + '/api/panel/campaign-names')

      if (res && res.body && res.body.error) return reject(res.body.error)
      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}

export const getCampaignInfo = async ({ campaignName, surveyId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(
        prefix +
          `/api/panel/campaign/${encodeURIComponent(campaignName)}/${encodeURIComponent(surveyId)}`
      )

      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}
