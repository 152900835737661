import React, { useState } from 'react'
import _ from 'lodash'
import './UploadFile.scss'
import PropTypes from 'prop-types'
import Select from 'react-select'

const defaultFilterState = {
  exact: false,
  empty: false,
  method: { value: 'only', label: 'Only' },
  column: null,
  value: null,
  id: 1,
}

const UploadFilterConfig = ({ columnNames, rows, filters, setFilters }) => {
  const [collapsed, setCollapsed] = useState([])
  const [selectedColumn, setSelectedColumn] = useState('')
  const [previewCount, setPreviewCount] = useState(20)

  const toggleCollapsed = column => setCollapsed(_.xor(collapsed, [column]))
  const getUniqueValuesForColumn = column => {
    const index = columnNames.findIndex(col => col === column)
    return _.uniq(rows.map(row => row[index]))
  }
  const onChangeColumn = column => {
    const inFilters = !!filters.find(filter => filter.columnTitle === column)
    if (inFilters) {
      return setFilters(filters.filter(filter => filter.columnTitle !== column))
    }

    setFilters([
      ...filters,
      {
        columnTitle: column,
        validValues: getUniqueValuesForColumn(column),
      },
    ])
  }
  const isolatePotentialBadValues = values => {
    const POTENTIAL_TRIGGER_REGEX = [
      /law/,
      /[^ur]grad/,
      /alum/,
      /assistant/,
      /faculty/,
      /staff/,
      /dean/,
      /admission/,
      /president/,
      /provost/,
      / it /,
      /admin/,
      /mba/,
      /phd/,
      /employee/,
      /dentist/,
      /med/,
      /prof/,
    ]
    const potentialBad = values.filter(val =>
      POTENTIAL_TRIGGER_REGEX.some(reg => val && reg.test(val.toLowerCase()))
    )

    const rest = _.difference(values, potentialBad)

    return [potentialBad, rest]
  }
  const onToggleValue = (column, value) => {
    const filterIndex = filters.findIndex(filter => filter.columnTitle === column)
    if (filterIndex < 0) {
      return
    }

    const newValidValues = _.xor(filters[filterIndex].validValues, [value])

    setFilters([
      ...filters.slice(0, filterIndex),
      {
        ...filters[filterIndex],
        validValues: newValidValues,
      },
      ...filters.slice(filterIndex + 1),
    ])
  }
  return (
    <React.Fragment>
      <div>
        <label className="label">Preview Data</label>
        <div
          style={{
            border: '1px solid #00000040',
            borderRadius: '4px',
            padding: '1rem',
            maxHeight: '20rem',
            overflow: 'auto',
          }}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className="upload-filter-label">Preview row count</label>
              <input
                type="number"
                className="input"
                style={{ width: 150 }}
                value={previewCount}
                onChange={e => setPreviewCount(e.target.value)}
              />
            </div>
            <hr />
            {columnNames &&
              columnNames.map(name => (
                <button
                  onClick={() => setSelectedColumn(name === selectedColumn ? '' : name)}
                  className={`button ${selectedColumn === name ? 'is-success' : ''}`}
                  style={{ marginRight: '1rem' }}
                  key={name}
                >
                  {name}
                </button>
              ))}
          </div>
          {selectedColumn?.length > 0 && <hr />}
          {selectedColumn?.length > 0 && (
            <div className="content">
              <ul>
                {selectedColumn &&
                  rows &&
                  rows
                    .slice(0, previewCount)
                    .map(row => (
                      <li key={Math.random()}>{row[columnNames.indexOf(selectedColumn)]}</li>
                    ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <hr />

      <div>
        <label className="label">Filter</label>
        <div style={{ border: '1px solid #00000040', borderRadius: '4px', padding: '1rem' }}>
          <div id="buttons">
            <button
              className="button"
              onClick={() => {
                setFilters(prev => [...prev, { ...defaultFilterState, id: Math.random() }])
              }}
            >
              Add a row
            </button>
          </div>
          <hr />
          <div id="filter-rows">
            {filters &&
              filters.map((f, i) => (
                <div key={f.id}>
                  <div>
                    <div
                      className="checkbox"
                      style={{
                        marginRight: '1.5rem',
                      }}
                      onClick={() => {
                        let newFRs = [...filters]
                        newFRs[i].exact = !newFRs[i].exact
                        setFilters(newFRs)
                      }}
                    >
                      <input type="checkbox" style={{ marginRight: '0.5rem' }} checked={f.exact} />
                      Exact
                    </div>

                    <div
                      className="checkbox"
                      onClick={() => {
                        let newFRs = [...filters]
                        newFRs[i].empty = !newFRs[i].empty
                        setFilters(newFRs)
                      }}
                    >
                      <input type="checkbox" style={{ marginRight: '0.5rem' }} checked={f.empty} />
                      Empty
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 2 }}>
                      {/* Method */}
                      <label className="upload-filter-label">Method</label>
                      <Select
                        value={f.method}
                        onChange={e => {
                          let newFRs = [...filters]
                          newFRs[i].method = e
                          setFilters(newFRs)
                        }}
                        options={[
                          { value: 'only', label: 'Only' },
                          { value: 'donot', label: 'Do Not' },
                        ]}
                      />
                    </div>
                    <div
                      className="filter-column-styles"
                      style={{
                        flex: 2,
                      }}
                    >
                      {/* Pre-column */}
                      include entries where
                    </div>
                    <div style={{ flex: 2 }}>
                      {/* Column */}
                      <label className="upload-filter-label">Column</label>
                      <Select
                        value={f.column}
                        onChange={e => {
                          let newFRs = [...filters]
                          newFRs[i].column = e
                          setFilters(newFRs)
                        }}
                        options={columnNames.map(n => ({ value: n.toLowerCase(), label: n }))}
                      />
                    </div>
                    <div
                      className="filter-column-styles"
                      style={{
                        flex: 2,
                      }}
                    >
                      {/* Pre-Value */}
                      {f.exact || f.empty ? 'Is' : 'Includes'} {f.empty ? 'Empty' : ''}
                    </div>
                    <div
                      style={{
                        flex: 2,
                        opacity: f.empty ? 0 : undefined,
                      }}
                    >
                      {/* Value */}
                      <label className="upload-filter-label">Value</label>
                      <input
                        onChange={e => {
                          let newFRs = [...filters]
                          newFRs[i].value = e.target.value
                          setFilters(newFRs)
                        }}
                        value={f.value || ''}
                        style={{ width: '100%' }}
                        className="input"
                        placeholder="value..."
                        type="text"
                      />
                    </div>
                    <div
                      className="filter-column-styles"
                      style={{
                        flex: 1,
                      }}
                    >
                      {/* Delete */}
                      <div
                        className="filter-row-delete"
                        onClick={() => {
                          let newFRs = [...filters]
                          console.log(i)
                          newFRs.splice(i, i === 0 ? 1 : i)
                          setFilters(newFRs)
                        }}
                      >
                        -
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

UploadFilterConfig.propTypes = {
  columnNames: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
}

export default UploadFilterConfig

// <label className="label">Would you like to filter emails based on any columns?</label>
//       {columnNames.map(column => (
//         <React.Fragment>
//           <div className="field">
//             <label className="checkbox">
//               <input
//                 type="checkbox"
//                 checked={!!filters.find(filter => filter.columnTitle === column)}
//                 onChange={() => onChangeColumn(column)}
//                 className="margin-right"
//               />
//               {column}
//             </label>
//           </div>
//           {!!filters.find(filter => filter.columnTitle === column) && (
//             <div className="field filter-list has-background-grey-dark has-text-light">
//               <label className="label has-text-light">
//                 Which values <em>are</em> allowed for {column}?{' '}
//                 <span className="has-text-warning">Yellow</span> indicates match on potential
//                 keywords
//               </label>
//               <div className="field">
//                 <button className="button is-small" onClick={() => toggleCollapsed(column)}>
//                   {collapsed.includes(column) ? 'Expand Options' : 'Collapse Options'}
//                 </button>
//               </div>
//               {!collapsed.includes(column) &&
//                 isolatePotentialBadValues(getUniqueValuesForColumn(column)).map((group, i) => (
//                   <React.Fragment>
//                     {group.map(val => (
//                       <div className="field">
//                         <div className="control">
//                           <label className={`checkbox ${i === 0 ? 'has-text-warning' : ''}`}>
//                             <input
//                               type="checkbox"
//                               className="margin-right"
//                               checked={filters
//                                 .find(filter => filter.columnTitle === column)
//                                 .validValues.includes(val)}
//                               onChange={() => onToggleValue(column, val)}
//                             />
//                             {val || '(blank)'}
//                           </label>
//                         </div>
//                       </div>
//                     ))}
//                   </React.Fragment>
//                 ))}
//               {!collapsed.includes(column) && (
//                 <button className="button is-small" onClick={() => toggleCollapsed(column)}>
//                   {collapsed.includes(column) ? 'Expand Options' : 'Collapse Options'}
//                 </button>
//               )}
//             </div>
//           )}
//         </React.Fragment>
//       ))}
