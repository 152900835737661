import request from './request'
import prefix from './prefix'
import Promise from 'bluebird'
import { formatFilters } from './filters'

//TODO(Zap): Advise with Tim, but I think we should have all schedule job taskshere. So scheduleEmails
// should probably be here?

export const cancelJobs = ({ campaignName = null, surveyId = null }) => {
  return new Promise(async (resolve, reject) => {
    let res = await request.post(prefix + '/api/panel/cancelScheduledJobs')

    if (res && res.body && res.body.error) return reject(res.body.error)
    return resolve(res.body)
  })
}

export const getAll = data => {
  // const query = {
  //   mode,
  //   typesToGet,
  // }
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + '/api/scheduledjobs').query(data)

      if (res && res.body && res.body.error) return reject(res.body.error)
      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}

// /api/scheduledjobs/cancel
export const cancel = jobId => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = {
        jobId,
      }

      let res = await request.post(prefix + '/api/scheduledjobs/cancel').send(data)
      if (res?.body?.error) return reject(res.body.error)
      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const scheduleSms = ({
  schedules,
  campaignName,
  templateId,
  messageLimit,
  selectedSchoolId,
  message,
  surveyId,
  maxSms,
  maxSmsPerSchool,
  reTargetPeriod,
  globalVariables,
  onlyUsers,
  onlyEdVisors,
  onlyExactData,
  allowAlumni,
  filterSets,
  deduplicateAgainst,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Format data
      const formattedQuery = formatFilters(filterSets)

      if (selectedSchoolId) {
        formattedQuery.push([{ variable: 'school', value: selectedSchoolId }])
      }

      let data = {
        campaignName,
        templateId,
        messageLimit,
        message,
        surveyId,
        maxSms,
        maxSmsPerSchool,
        reTargetPeriod,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        onlyExactData,
        allowAlumni,
        filters: formattedQuery,
        deduplicateAgainst,
      }

      await Promise.mapSeries(schedules, async schedule => {
        let date = schedule.utc().toDate()

        await request.post(prefix + '/api/scheduledjobs/create').send({
          date,
          data,
          type: 'sms-job',
        })

        console.log('Created a job')

        return
      })

      resolve()
    } catch (err) {
      //Error
      console.log(err)
      reject()
    }
  })
}
