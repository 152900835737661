import React from 'react'
import './SnackBar.scss'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

const SnackBar = ({ snackbarFunctions }) => {
  return (
    <div className="snackbar-container">
      <AnimatePresence>
        {snackbarFunctions.snackbars.map((snack, i) => (
          <motion.div
            key={snack.random}
            initial={{ y: 100, scale: 0.5 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ x: 400, opacity: 0 }}
            className={`box has-background-${snack.type} has-text-white`}
          >
            <h4>{snack.message}</h4>
            <button
              className="delete is-large"
              onClick={() => snackbarFunctions.removeSnackbar(i)}
              aria-label="close"
            ></button>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}

SnackBar.propTypes = {
  snackbarFunctions: PropTypes.object,
}

export default SnackBar
