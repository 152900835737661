import React, { useState } from 'react'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import PropTypes from 'prop-types'

const SaveSample = ({ handleModal, saveSample, snackbarFunctions, setLoadedSample }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [notes, setNotes] = useState('')

  const handleSave = async () => {
    // Set loading
    setIsLoading(true)

    // Save the sample
    try {
      await saveSample(title, notes)
      setLoadedSample(title)
      snackbarFunctions.newSnackbar({ message: 'Saved Sample Set', type: 'success' })
    } catch (err) {
      snackbarFunctions.newSnackbar({ message: 'Error saving sample', type: 'danger' })
      console.log(err)
    }

    // Close the modal
    handleModal(null)
  }

  return (
    <div className="box">
      <div className="field">
        <label className="label">Title</label>
        <div className="control">
          <input
            onChange={e => setTitle(e.target.value)}
            className="input"
            type="text"
            placeholder="Title of Dataset"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Notes</label>
        <div className="control">
          <textarea
            onChange={e => setNotes(e.target.value)}
            className="textarea"
            placeholder="This dataset is used for the FIRE project..."
          />
        </div>
      </div>
      <AnimatedButton
        disabled={isLoading || !title}
        onClick={() => handleSave()}
        className={`button is-success is-fullwidth ${isLoading && 'is-loading'}`}
      >
        Save Sample
      </AnimatedButton>
    </div>
  )
}

SaveSample.propTypes = {
  handleModal: PropTypes.func.isRequired,
  saveSample: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.func.isRequired,
  setLoadedSample: PropTypes.func,
}

export default SaveSample
