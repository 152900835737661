import React, { useState, useCallback, useEffect } from 'react'
import Modal from './Modal/Modal'
import FeasibilityTable from './FeasibilityTable/FeasibilityTable'
import FeasibilityLayout from '../layout/FeasibilityLayout'
import SnackBar from './SnackBar/SnackBar'
import api from '../api'
import useFilter from '../context/FilterContext'
import useMessage from '../context/MessageContext'
import DownloadCsv from './DownloadCsv/DownloadCsv'

const Feasibility = () => {
  const { setLoadingMessage } = useMessage()
  const [loadedSample, setLoadedSample] = useState()
  const [stateData, setStateData] = useState()

  const dataArrayProperFormat = createDataArray(stateData)

  /*
  Filter Logic
  */
  const { filterSets } = useFilter()

  /*
  Modal Logic
  Other components in the app use handleModal and pass it a string to enable specific modals.
  IE: onClick={() => handleModal('save-sample')}
  The string passed determines which modal child content is loaded into the Modal.
  */
  const [modalState, setModalState] = useState({ open: false, modal: null })
  const handleModal = useCallback((modal, passedData) => {
    setModalState(modalState =>
      (modalState.modal && modal === modalState.modal.title) || !modal
        ? { open: false, modal: null }
        : { open: true, modal: { title: modal, data: passedData } }
    )
  }, [])

  /*
    Snackbars

    Everything is passed in one variable called snackbarFunctions
    To create a new snackbar, pass snackbarFunctions to the component and run
    snackbarFunctions.newSnackbar({message: "My Message", type: "danger"})

    Type uses bulma color variables to change the background of the snackbar.

    Functions are wrapped inside of useCallback so they are memoized and not re-created
    each re-render.
  */

  const [snackbars, setSnackbars] = useState([])

  const snackbarFunctions = {
    newSnackbar: useCallback(snack => {
      let index = Math.random()
      setSnackbars(prevState =>
        prevState.concat({
          message: snack.message,
          type: snack.type,
          random: index,
        })
      )
      setTimeout(() => {
        setSnackbars(prevState => prevState.filter(e => e.random !== index))
      }, 4000)
    }, []),
    removeSnackbar: useCallback(index => {
      setSnackbars(prevState => {
        let newState = [...prevState]
        newState.splice(index, 1)
        return newState
      })
    }, []),
    snackbars: snackbars,
  }

  /*
  Data Logic

  We set stateData and then push it into useMemo as required by react-table.
  overviewData controls the overview field.

  We assume that the first row of data from API is going to be the overview data.
  */

  // Function used by the search button to get new data
  const fetchNewData = useCallback(async () => {
    setLoadingMessage('Fetching Data')

    let result
    try {
      result = await api.filters.getFeasibilityDataFromFilters(filterSets)
      snackbarFunctions.newSnackbar({ message: 'Fetched new data', type: 'success' })
      setLoadingMessage()
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Error catching data',
        type: 'danger',
      })
      setLoadingMessage()
      console.log(err)
    }

    setStateData(result) // If there is a result, remove the Overview
  }, [filterSets, snackbarFunctions])

  // Dinamically loads the page if there are filters selected.
  // Disable temporariliy as leading messages are not working well for this specific case,
  // and the button is not getting disabled.
  // useEffect(() => {
  //   if (filterSets[0]?.filter?.[0] && Object.keys(filterSets[0].filter[0]).length !== 0) {
  //     fetchNewData()
  //   }
  // }, [])

  return (
    <React.Fragment>
      <SnackBar snackbarFunctions={snackbarFunctions} />
      {modalState.open && (
        <Modal handleModal={handleModal}>
          {modalState.modal.title === 'download-csv' && (
            <DownloadCsv
              handleModal={handleModal}
              snackbarFunctions={snackbarFunctions}
              jsonData={dataArrayProperFormat}
            />
          )}
        </Modal>
      )}
      <FeasibilityLayout
        fetchNewData={fetchNewData}
        snackbarFunctions={snackbarFunctions}
        handleModal={handleModal}
      >
        <FeasibilityTable
          columnsArray={headerArray}
          dataArray={dataArrayProperFormat}
          fetchNewData={fetchNewData}
          snackbarFunctions={snackbarFunctions}
        />
      </FeasibilityLayout>
    </React.Fragment>
  )
}

const headerArray = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'Demographics',
        accessor: 'demographics',
        disableSortBy: true,
      },
    ],
  },
  {
    Header: 'Total',
    columns: [
      {
        Header: 'Estimated Responses',
        accessor: 'feasibility',
        disableSortBy: true,
      },
    ],
  },
  {
    Header: ' ',
    columns: [
      {
        Header: 'User Count',
        accessor: 'userCount',
        disableSortBy: true,
      },
    ],
  },
  {
    Header: 'Email',
    columns: [
      {
        Header: 'Email Count',
        accessor: 'emailCount',
        disableSortBy: true,
      },
      {
        Header: 'Open Rate',
        accessor: 'openRate',
        disableSortBy: true,
      },
      {
        Header: 'Click Rate',
        accessor: 'clickRate',
        disableSortBy: true,
      },
      {
        Header: 'Conversion Rate',
        accessor: 'conversionRate',
        disableSortBy: true,
      },
      {
        Header: 'Estimated Responses',
        accessor: 'emailFeasibility',
        disableSortBy: true,
      },
    ],
  },
  {
    Header: 'SMS',
    columns: [
      {
        Header: 'Phone Count',
        accessor: 'smsCount',
      },
      {
        Header: 'Conversion Rate',
        accessor: 'smsConversionRate',
      },
      {
        Header: 'Estimated Responses',
        accessor: 'smsFeasibility',
      },
    ],
  },
]

// Converts the proper floats to percent
function valueToPercent(key, value) {
  if (
    (key === 'openRate' ||
      key === 'clickRate' ||
      key === 'conversionRate' ||
      key === 'smsConversionRate') &&
    typeof value === 'number'
  ) {
    if (Number.isInteger(value)) {
      return (value * 100).toString() + '%'
    } else {
      return (value * 100).toFixed(2).toString() + '%'
    }
  }
  return value
}

const createDataArray = feasibilityData => {
  const dataArrayProperFormat = []

  if (feasibilityData && Object.keys(feasibilityData).length > 0) {
    //Need to push totals always first, so it appears on top of the table
    const totalsData = feasibilityData['totals']
    totalsData['demographics'] = 'Totals'
    Object.keys(totalsData).forEach(key => (totalsData[key] = valueToPercent(key, totalsData[key])))
    dataArrayProperFormat.push(totalsData)

    // Get the topic ( a.k.a. gender, race, etc ...)
    // And the appropriate sub-topic data ( a.k.a female, male etc ...)
    Object.keys(feasibilityData).forEach(topic => {
      let topicData = feasibilityData[topic]
      // Totals is a special case! Totals does not have sub-topics, it has its values directly.
      if (topic !== 'totals') {
        // Push the header as demographics and keep the rest of the object empty.
        // This way we can then select those data objects as topic headers.
        // This will be race, gender, school, etc...
        const header = { demographics: topic }
        dataArrayProperFormat.push(header)

        Object.keys(topicData).forEach(subTopic => {
          let subTopicData = topicData[subTopic]
          subTopicData['demographics'] = subTopic
          subTopicData['demographicsTopic'] = topic ? topic : ''
          // ROUND the data ( consider +/- of doing it on back end)
          Object.keys(subTopicData).forEach(
            key => (subTopicData[key] = valueToPercent(key, subTopicData[key]))
          )
          dataArrayProperFormat.push(subTopicData)
        })
      }
    })
  }
  return dataArrayProperFormat
}

export default Feasibility
