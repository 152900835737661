import React, { useState, useEffect, useCallback } from 'react'
import { smsCampaignNames } from '../../api/sms'
import './SendSms.scss'
import DataCard from '../DataCard/DataCard'
import moment from 'moment'
import SendSmsForm from './SendSmsForm'
import { SchedulingCalendarsListBox } from '../ReusableComponents/SchedulingCalendar'

// This array holds all the Sms Template values.
const SMS_TEMPLATES = [
  { name: 'Direct Incentive', value: 2134795 },
  { name: 'Direct Incentive Type 2', value: 2894573 },
  { name: '[Alumni] Direct Incentive', value: 2564572 },
]

const SendSms = ({ visible, data, sendSms, snackBarFunctions, sendSelf, scheduleSms }) => {
  const [sendLoading, setSendLoading] = useState(false)
  const [onlyUsers, setOnlyUsers] = useState(false)
  const [onlyEdVisors, setOnlyEdVisors] = useState(false)
  const [onlyExactData, setOnlyExactData] = useState(false)
  const [allowAlumni, setAllowAlumni] = useState(false)
  const [startScheduling, setStartScheduling] = useState(false)
  const [schedules, setSchedules] = useState([moment(), moment()])
  const [noOverlapCampaigns, setNoOverlapCampaigns] = useState([])
  const [prevCampaigns, setPrevCampaigns] = useState([])

  const [inputs, setInputs] = useState({
    templateId: 2134795,
    campaignName: '',
    surveyId: '',
    maxSms: null,
    maxSmsPerSchool: null,
    reTargetPeriod: null,
    message: null,
    surveyTopic: '',
    studentsLeft: 2000,
    list: '',
    growthChannelCustom: null,
  })

  // This is an array that hoplds the variables needed for each checkboxObject.
  // checkBoxRows is used inside of SendSmsForm to render the checkbox list
  const checkboxRows = [
    {
      label: 'Only Send to Users',
      handleOnChange: () => setOnlyUsers(e => !e),
      value: onlyUsers,
    },
    {
      label: 'Only Send to EdVisor Emails',
      handleOnChange: () => setOnlyEdVisors(e => !e),
      value: onlyEdVisors,
    },
    {
      label: 'Only Send to Exact Data Numbers',
      handleOnChange: () => setOnlyExactData(e => !e),
      value: onlyEdVisors,
    },
    {
      label: 'Allow send to alumni',
      handleOnChange: () => setAllowAlumni(e => !e),
      value: allowAlumni,
    },
  ]

  // This callback handles the input change
  const handleInput = useCallback(
    (input, value) => {
      setInputs({ ...inputs, [input]: value })
    },
    [inputs]
  )

  // This callback handles the tamplateChange
  const handleTemplateChange = useCallback(e => {
    handleInput('templateId', parseInt(e.target.value))
  }, [])

  //This function handles sms campaign sends
  const handleSubmit = async () => {
    sendSms({
      campaignName: inputs?.campaignName,
      templateId: inputs?.templateId,
      selectedSchoolId: data?.data?.institution,
      surveyId: inputs?.surveyId,
      maxSms: inputs?.maxSms,
      maxSmsPerSchool: inputs?.maxSmsPerSchool,
      reTargetPeriod: inputs?.reTargetPeriod,
      onlyUsers,
      onlyEdVisors,
      onlyExactData,
      allowAlumni,
      list: inputs?.list,
      globalVariables: {
        studentsLeft: inputs?.studentsLeft,
        surveyTopic: inputs?.surveyTopic,
      },
      deduplicateAgainst: noOverlapCampaigns.map(campaign => campaign?.value),
      growthChannelCustom: inputs?.growthChannelCustom,
    })

    setSendLoading(true)
  }

  //This function handles scheduled sms campaign sends
  const handleSubmitSchedules = async () => {
    scheduleSms({
      ...{ schedules },
      ...{
        campaignName: inputs?.campaignName,
        templateId: inputs?.templateId,
        selectedSchoolId: data?.data?.institution,
        surveyId: inputs?.surveyId,
        maxSms: inputs?.maxSms,
        maxSmsPerSchool: inputs?.maxSmsPerSchool,
        reTargetPeriod: inputs?.reTargetPeriod,
        onlyUsers,
        onlyEdVisors,
        onlyExactData,
        allowAlumni,
        list: inputs?.list,
        globalVariables: {
          studentsLeft: inputs?.studentsLeft,
          surveyTopic: inputs?.surveyTopic,
        },
        deduplicateAgainst: noOverlapCampaigns.map(campaign => campaign?.value),
        growthChannelCustom: inputs?.growthChannelCustom,
      },
    })

    setSendLoading(true)
  }

  //This function handles sms send to self
  const handleSubmitSelf = async () => {
    try {
      await sendSelf({
        campaignName: inputs.campaignName,
        templateId: inputs.templateId,
        surveyId: inputs.surveyId,
        selfNumber: inputs.selfNumber,

        globalVariables: {
          studentsLeft: inputs.studentsLeft,
          surveyTopic: inputs.surveyTopic,
        },
        deduplicateAgainst: noOverlapCampaigns.map(campaign => campaign?.value),
        growthChannelCustom: inputs?.growthChannelCustom,
      })
    } catch (e) {
      console.log('CAUGHT ERROR: ', e.message)
    }
  }

  //This function does the initial data load for all the smsCampaignNames.
  useEffect(() => {
    setSendLoading(false)

    const setCampaigns = async () => {
      try {
        const campaigns = await smsCampaignNames()
        setPrevCampaigns(campaigns.map(c => ({ label: c, value: c })))
      } catch (e) {
        setPrevCampaigns([])
      }
    }
    setCampaigns()
  }, [visible])

  return (
    <React.Fragment>
      {data && <DataCard preview={data.preview} title={data.title} data={data.data} />}
      <div
        className="box has-background-white send-sms-container"
        style={visible ? {} : { display: 'none' }}
      >
        <SendSmsForm
          handleTemplateChange={handleTemplateChange}
          SMS_TEMPLATES={SMS_TEMPLATES}
          handleInput={handleInput}
          noOverlapCampaigns={noOverlapCampaigns}
          setNoOverlapCampaigns={setNoOverlapCampaigns}
          prevCampaigns={prevCampaigns}
          checkboxRows={checkboxRows}
          handleSubmit={handleSubmit}
          sendLoading={sendLoading}
          handleSubmitSelf={handleSubmitSelf}
          setStartScheduling={setStartScheduling}
          startScheduling={startScheduling}
          inputs={inputs}
        />

        <SchedulingCalendarsListBox
          startScheduling={startScheduling}
          schedules={schedules}
          setSchedules={setSchedules}
          handleSubmitSchedules={handleSubmitSchedules}
          sendLoading={sendLoading}
          inputs={inputs}
        />
      </div>
    </React.Fragment>
  )
}

export default SendSms
