import React from 'react'
import './DataCard.scss'
import PropTypes from 'prop-types'

const DataCard = ({ handleModal, title, preview, data, download }) => {
  return (
    <article
      className={`data-card card has-border-radius ${
        !preview ? 'has-background-grey-lighter' : 'has-background-white box'
      }`}
    >
      <header className="level">
        <div className="level-left" style={{ height: '100%' }}>
          <div className={`title-container level-item has-background-success ${preview && 'box'}`}>
            <h2 className="title has-text-white is-6">
              <strong>{title || data.name}</strong>
            </h2>
          </div>
        </div>
        {!preview && (
          <div className="level-right">
            <button
              className="level-item button is-success is-marginless is-radiusless"
              onClick={download}
            >
              Download
            </button>
            <button
              onClick={() =>
                handleModal('send-email', { title: 'Overview', preview: true, data: data })
              }
              className="level-item button is-primary is-marginless is-radiusless"
              style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
            >
              Send Email
            </button>
            <button
              onClick={() =>
                handleModal('send-sms', { title: 'Overview', preview: true, data: data })
              }
              className="level-item button is-primary is-marginless is-radiusless"
              style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
            >
              Send SMS
            </button>
          </div>
        )}
      </header>
      <div className="data-card-columns columns has-borders">
        <div className="content column has-text-centered">
          <h3 className="title is-6 is-centered">Emails</h3>
          <p>
            {data.emails ? data.emails.toLocaleString() : '0'}
            {data.emails && data.emails === 100000 && '+'}
          </p>
        </div>
        <div className="content column has-text-centered">
          <h3 className="title is-6 is-centered">Numbers</h3>
          <p>
            {data.phones ? data.phones.toLocaleString() : '0'}
            {data.phones && data.phones === 100000 && '+'}
          </p>
        </div>
        <div className="content column has-text-centered">
          <h3 className="title is-6 is-centered">Users</h3>
          <p>
            {data.users ? data.users.toLocaleString() : '0'}
            {data.users && data.users === 5000 && '+'}
          </p>
        </div>
        <div className="content column has-text-centered">
          <h3 className="title is-6 is-centered">30 day: Completes</h3>
          <p>{data.uniqueRecentSubmits ? data.uniqueRecentSubmits.toLocaleString() : 'NA'}</p>
        </div>
        <div className="content column has-text-centered">
          <h3 className="title is-6 is-centered">90 day: Email Opens</h3>
          <p>{data.openRate ? (data.openRate * 100).toFixed(2) + '%' : 'NA'}</p>
        </div>
        <div className="content column has-text-centered">
          <h3 className="title is-6 is-centered">90 day: Email Clicks</h3>
          <p>{data.clickRate ? (data.clickRate * 100).toFixed(2) + '%' : 'NA'}</p>
        </div>
      </div>
    </article>
  )
}

DataCard.propTypes = {
  handleModal: PropTypes.func,
  // (ZAP) Rmoved isRequired because it kept giving us warnings
  download: PropTypes.func,
  title: PropTypes.string,
  preview: PropTypes.bool,
  data: PropTypes.object.isRequired,
}

export default DataCard
