import React, { useEffect, useState, useCallback } from 'react'
import api from '../../api'
import './ViewEmails.scss'
import PropTypes from 'prop-types'
import {
  StatusCardHeader,
  StatusCardBody,
  StatusCardFooter,
  StatusCardError,
} from '../ReusableComponents/Card/StatusCard'
import AnimatedButton from '../AnimatedButton/AnimatedButton'

const ViewEmails = ({ applyFilter, snackbarFunctions }) => {
  const [emails, setEmails] = useState([])

  useEffect(() => {
    const getEmails = async () => {
      try {
        let resp = await api.email.getEmails()

        setEmails(resp.body.sort((a, b) => b.timestamp - a.timestamp))
      } catch (err) {
        console.log(err)
        snackbarFunctions.newSnackbar({ message: err || 'Error getting emails', type: 'danger' })
      }
    }
    getEmails()
  }, [snackbarFunctions])

  const getCampaignInfo = useCallback(
    async ({ campaignName, surveyId, emails, timestamp }) => {
      const campaignInfo = await api.email.getCampaignInfo({ campaignName, surveyId })
      let updatedEmails = emails

      updatedEmails = updatedEmails.map(email => {
        if (
          email.campaignName === campaignName &&
          email.surveyId === surveyId &&
          email.timestamp === timestamp
        ) {
          Object.keys(campaignInfo).forEach(key => {
            email[key] = campaignInfo[key]
          })
        }
        return email
      })

      setEmails(updatedEmails)
    },
    [setEmails]
  )

  const renderCard = email => {
    console.log('emaul: ', email)
    const cardData = [
      [
        { title: 'Subject:', value: `${email?.subject || 'N/A'}` },
        {
          title: 'Survey topic:',
          value: `${email?.globalVariables?.surveyTopic || 'N/A'}`,
        },
        {
          title: 'Rejected Responses:',
          value: `${email?.rejectCount || email?.rejectCount === 0 ? email.rejectCount : 'N/A'}`,
        },
        {
          title: 'Accepted Responses:',
          value: `${
            email?.conversionCount || email?.conversionCount === 0 ? email.conversionCount : 'N/A'
          }`,
        },
      ],

      [
        { title: 'Survey Id:', value: `${email?.surveyId || 'N/A'}` },

        {
          title: 'Complete Rate:',
          value: `${email?.completeRate || 'N/A'} %`,
        },
        {
          title: 'Rejected Rate:',
          value: `${email?.rejectRate || 'N/A'} %`,
        },
        {
          title: 'Accept Rate:',
          value: `${email?.conversionRate || 'N/A'} %`,
        },
      ],
    ]
    return (
      <div className="box" key={email.timestamp}>
        <StatusCardHeader
          title={email.campaignName}
          status={
            email.failedReason
              ? 'Email Failed'
              : `${email?.sentCount ? email.sentCount : email.successCount} emails sent`
          }
          statusColor={`${!email.failedReason ? 'success' : 'danger'}`}
          email={email}
          applyFilter={applyFilter}
        />

        <StatusCardBody cardData={cardData} />

        <div className="columns status-card-error-box">
          <StatusCardError email={email} />
        </div>

        <StatusCardFooter
          leftLabel={'Processed on:'}
          leftValue={email.processedOn}
          rightValue={email.finishedOn}
          rightLabel={'Finished on:'}
        >
          <AnimatedButton
            className="is-primary"
            style={{ width: '100%', height: '46px' }}
            onClick={() =>
              getCampaignInfo({
                campaignName: email.campaignName,
                surveyId: email.surveyId,
                emails,
                timestamp: email.timestamp,
              })
            }
          >
            <div className="button-get-info-text">
              <p className="button-main-text">Calculate Statistics</p>
              <p className="button-secondary-text">For all campaigns with this name!</p>
            </div>
          </AnimatedButton>
        </StatusCardFooter>
      </div>
    )
  }

  console.log('EMAILS:', emails)
  return (
    <div className="box view-email-container has-backround-lighter-gray">
      {emails && emails.map(email => renderCard(email))}
    </div>
  )
}

ViewEmails.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
}

export default ViewEmails
