import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

export const AnimatedButton = ({ children, onClick, disabled, className, style }) => {
  return (
    <motion.button
      whileHover={{
        scale: 1.05,
      }}
      whileTap={{
        scale: 0.95,
      }}
      onClick={onClick}
      disabled={disabled}
      className={`${className ? className : undefined} button`}
      style={style ? style : undefined}
    >
      {children}
    </motion.button>
  )
}

AnimatedButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default AnimatedButton
