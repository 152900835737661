import React, { createContext, useState, useContext, useMemo } from 'react'

const MessageContext = createContext()

export function MessageProvider({ children }) {
  const [loadingMessage, setLoadingMessage] = useState()

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      loadingMessage,
      setLoadingMessage,
    }),
    [loadingMessage]
  )

  return <MessageContext.Provider value={memoedValue}>{children}</MessageContext.Provider>
}

// Let's only export the `useLocation` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useMessage() {
  return useContext(MessageContext)
}
