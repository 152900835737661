import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import './UploadFile.scss'
import api from '../../api'
import PropTypes from 'prop-types'
import Select from 'react-select'
import UploadFilterConfig from './UploadFilterConfig'
import DemographicsLinkingSection from './DemographicsLinkingSection'
import demographicColumnsConfig from './demographicsLinkingHelpers/demographicColumnsConfig'
import valueTransformerForDem from './demographicsLinkingHelpers/valueTransformerForDem'

const UploadFileOptions = ({ handleModal, data, snackbarFunctions }) => {
  const [options, setOptions] = useState({ clean: false, autoVerifyNonEdu: false, title: '' })
  const [isUploading, setIsUploading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const [emailColumn, setEmailColumn] = useState(null)
  const [ipedsColumn, setIpedsColumn] = useState(null)
  const [progress, setProgress] = useState(null)

  const [uploadResult, setUploadResult] = useState(null)

  const [filters, setFilters] = useState([
    {
      exact: false,
      empty: false,
      method: { value: 'only', label: 'Only' },
      column: null,
      value: null,
      id: 1,
    },
  ])

  /*********** Start State Variables Used For Demographics Linking ***********/
  /* See DemographicsLinkingSection component for thorough docs on these state variables */

  const [demographicColumns, setDemographicColumns] = useState({
    ...demographicColumnsConfig.reduce(
      (acc, column) => ({
        ...acc,
        [column.name]: null,
      }),
      {}
    ),
  })

  /*
    Handle valid and invalid rows on dependency updates.
    Refactored from direct placement in return statement on 02/11/21
    to prevent unnesesary re-renders on state change.
  */
  useEffect(() => {
    if (data) {
      determineValidAndInvalidEmails(data.slice(1))
    }
  }, [data, filters, demographicColumns])
  const [validRows, setValidRows] = useState(null)
  const [invalidRows, setInvalidRows] = useState(null)
  const [sampleValid, setSampleValid] = useState(null)
  const [sampleInvalid, setSampleInvalid] = useState(null)

  const determineValidAndInvalidEmails = rows => {
    const { validRows, invalidRows } = rows.reduce(
      (acc, row) => {
        if (!emailColumn) {
          return acc
        }

        const email = row[emailColumn.value]
        if (!email || !email.includes('@')) {
          acc.invalidRows.push(row)
          return acc
        }

        // const passesFilters = filters.every(filter => {
        //   const columnIndex = data[0].findIndex(col => col === filter.columnTitle)
        //   return filter.validValues.includes(row[columnIndex])
        // })

        const passesFilters = filters.every(filter => {
          // Check if there is a method and column, if not, pass it anyways!
          if (filter.method && filter.column) {
            // Get column index from data
            let columnIndex = data[0].findIndex(
              col => col.toLowerCase() === filter.column.value.toLowerCase()
            )

            // Store a pass or fail
            let match
            if (filter.empty) {
              // Check if the value is empty or not
              match = row[columnIndex] ? false : true
            } else if (filter.exact) {
              // Check if the value is equal to the columns data
              match = row[columnIndex] === filter.value ? true : false
            } else {
              if (!filter.value) {
                // Check if there is a value, if not pass it
                match = true
              } else {
                // Check if there is a value, if so, check to see if the column includes it
                match = row[columnIndex].toLowerCase().includes(filter.value.toLowerCase())
                  ? true
                  : false
              }
            }

            // Return the match, or reverse it depending on the method
            return filter.method.value === 'only' ? match : !match
          } else {
            return true
          }
        })

        if (passesFilters) {
          acc.validRows.push(row)
          return acc
        }
        acc.invalidRows.push(row)
        return acc
      },
      {
        validRows: [],
        invalidRows: [],
      }
    )

    setValidRows(validRows)
    setInvalidRows(invalidRows)
    setSampleValid(_.sampleSize(validRows || [], 3))
    setSampleInvalid(_.sampleSize(invalidRows || [], 3))

    return { validRows, invalidRows }
  }

  const [demographicColumnValueMaps, setDemographicColumnValueMaps] = useState({})

  /*
   * demographicsConfig
   * In order to let the user link columns and cell values to our internal demographics,
   * we need the backend to send us the demographicsConfig. Fetch it on mount and save
   * to demographicsConfig state variable
   */
  const [demographicsConfig, setDemographicsConfig] = useState(null)
  useEffect(() => {
    const fetchAndStoreDemConfig = async () => {
      const config = await api.demographics.getConfig()
      setDemographicsConfig(config)
    }
    fetchAndStoreDemConfig()
  }, [])

  /*********** End State Variables Used For Demographics Linking ***********/

  useEffect(() => {
    // set emailColumn and ipedsColumn to defaults if we think we can guess them
    const emailIndex = data?.[0]?.findIndex(header => header?.toLowerCase() === 'email')
    if (emailIndex > -1) {
      setEmailColumn({ value: emailIndex, label: data[0][emailIndex] })
    }

    const ipedsIndex = data?.[0]?.findIndex(
      header => header?.toLowerCase() === 'ipeds' || header?.toLowerCase() === 'ipedsid'
    )

    if (ipedsIndex > -1) {
      setIpedsColumn({ value: ipedsIndex, label: data[0][ipedsIndex] })
    }

    const defaultColumns = demographicColumnsConfig.reduce(
      (acc, { name, autoMatchingColumnTitles }) => {
        const lowercaseTitles = autoMatchingColumnTitles.map(_.toLower)
        const columnMatch = data?.[0]?.findIndex(header =>
          lowercaseTitles.includes(header?.toLowerCase())
        )

        if (columnMatch > -1) {
          return { ...acc, [name]: { value: columnMatch, label: data[0][columnMatch] } }
        }

        return acc
      },
      {}
    )
    setDemographicColumns(columns => ({
      ...columns,
      ...defaultColumns,
    }))
  }, [data])

  const sendData = async () => {
    if (!emailColumn) {
      snackbarFunctions.newSnackbar({
        message: 'Must specify Email Column',
        type: 'danger',
      })
      return
    }

    // Change button to loading
    setIsUploading(true)

    const activeDemographicColumns = demographicColumnsConfig
      .filter(
        ({ name }) => demographicColumns[name] && Number.isInteger(demographicColumns[name].value)
      )
      .filter(
        ({ name, sendToAPIAs }) =>
          demographicsConfig && (demographicsConfig[name] || demographicsConfig[sendToAPIAs])
      )
      .map(({ name }) => ({ name, columnIndex: demographicColumns[name].value }))

    const [header, ...rows] = data
    const { validRows: emails } = determineValidAndInvalidEmails(rows)
    const emailMapping = emails.map(emailRow => ({
      ...header.reduce(
        (acc, item, i) => ({
          ...acc,
          [item]: emailRow[i],
        }),
        {}
      ),
      email: emailRow[emailColumn.value],
      ipedsId: ipedsColumn?.value > -1 ? emailRow[ipedsColumn.value] : null,
      demographics: activeDemographicColumns.reduce(
        (acc, { name, columnIndex }) => ({
          ...acc,
          [name]: valueTransformerForDem(demographicColumnValueMaps, name)(emailRow[columnIndex]),
        }),
        {}
      ),
    }))

    // Format data for api
    const formattedData = {
      options: options,
      emails: emailMapping,
    }

    // Send data to API
    try {
      let uploadData = await api.upload.uploadEmails(formattedData, setProgress)
      snackbarFunctions.newSnackbar({
        message: 'Emails have been uploaded!',
        type: 'success',
      })

      //Process the return array
      let upsertedCount = 0
      let duplicateCount = 0
      uploadData.forEach(entry => {
        // setTotalUpsetedCount(totalUpsertedCount + entry.body['upsertedCount'])
        // setTotalDuplicateCount(totalDuplicateCount + entry.body['duplicateCount'])
        upsertedCount += entry.body['upsertedCount']
        duplicateCount += entry.body['duplicateCount']
      })
      setUploadResult({ upsertedCount, duplicateCount })
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Error Uploading Emails',
        type: 'danger',
      })
      return
    }
    setIsFinished(true)
  }

  // √ dropdown for each demographic.

  // √ on upload, need to do the mapping of values of emails based on the demographic columns

  // √ need to initialize the values for dem columns based on defaults

  // √ also need to process the values and compute valid vs invalid, pulling the demographics
  // config from API to do this checking...
  //
  // √ Should there be a way to remap the values? Grade handling should probably be to
  // Map Year column to Grade, and then map the grades to specific years...

  return (
    <div className="box upload-file-options-box">
      <React.Fragment>
        <h3 className="subtitle">
          Found <span className="has-text-success">{data.length - 1}</span> rows of data!
        </h3>
        <hr />
        <div className="field">
          <label className="label">Email Column</label>
          <div className="control">
            <Select
              options={data[0].map((header, i) => ({ value: i, label: header }))}
              onChange={val => setEmailColumn(val)}
              value={emailColumn}
            />
          </div>
          <p className="help">Which column contains the emails</p>
        </div>
        <div className="field">
          <label className="label">IPEDS ID Column (Optional)</label>
          <div className="control">
            <Select
              options={data[0].map((header, i) => ({ value: i, label: header }))}
              onChange={val => setIpedsColumn(val)}
              value={ipedsColumn}
            />
          </div>
          <p className="help">
            Tie emails to a school manually with IPEDS Unit Ids. For .edu emails, leave blank to
            auto-connect
          </p>
        </div>
        <hr />
        <DemographicsLinkingSection
          data={data}
          demographicsConfig={demographicsConfig}
          demographicColumns={demographicColumns}
          setDemographicColumns={setDemographicColumns}
          demographicColumnValueMaps={demographicColumnValueMaps}
          setDemographicColumnValueMaps={setDemographicColumnValueMaps}
        />
        <hr />
        <label className="label ">Options</label>
        <div className="field">
          <div className="control">
            <input
              disabled
              value={options.clean}
              onChange={e => setOptions({ ...options, clean: !options.clean })}
              className="margin-right"
              type="checkbox"
            />
            Clean Emails?
          </div>
          <div className="control">
            <input
              value={options.autoVerifyNonEdu}
              onChange={e =>
                setOptions({ ...options, autoVerifyNonEdu: !options.autoVerifyNonEdu })
              }
              className="margin-right"
              type="checkbox"
            />
            Auto-Verify Non Edu Emails
          </div>
        </div>
        <div className="field">
          <label className="label">
            Would you like to name this list so you can send directly to it?
          </label>
          <div className="control">
            <input
              value={options.title}
              onChange={e => setOptions({ ...options, title: e.target.value })}
              type="text"
              className="input"
              placeholder="list_of_dartmouth_alum"
            />
          </div>
        </div>
        <hr />
        <UploadFilterConfig
          columnNames={data[0]}
          rows={data.slice(1)}
          filters={filters}
          setFilters={setFilters}
        />
        <hr />
        <label className="label has-text-success">
          Uploading {validRows?.length || '0'} Valid Emails. Sample:
        </label>
        <div className="table-container">
          <table className="table">
            <tbody>
              <tr>
                {data[0].map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
              {sampleValid?.map((row, i) => (
                <tr key={i}>
                  {row.map((el, i) => (
                    <td key={i}>{el}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <label className="label has-text-danger">
          Discarding {invalidRows?.length || '0'} Invalid Emails. Sample:
        </label>
        <div className="table-container">
          <table className="table">
            <tbody>
              <tr>
                {data[0].map((header, i) => (
                  <th key={i}>{header}</th>
                ))}
              </tr>
              {sampleInvalid?.map((row, i) => (
                <tr key={i}>
                  {row.map((el, i) => (
                    <td key={i}>{el}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isFinished ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                className="table-container has-background-primary"
                style={{
                  padding: '1rem',
                  color: 'white',
                  borderRadius: '5px',
                }}
              >
                <p>Duplicate data: {uploadResult?.duplicateCount || 0}</p>
                <p>New data: {uploadResult?.upsertedCount || 0}</p>
                <p>
                  Faulty data:{' '}
                  {data.length -
                    1 -
                    ((uploadResult?.duplicateCount || 0) + (uploadResult?.upsertedCount || 0))}
                </p>
              </div>
              <AnimatedButton
                disabled={false}
                className={`button is-success ${isFinished && 'is-finished'}`}
                onClick={() => handleModal(null)}
              >
                {'Finish'}
              </AnimatedButton>
            </div>
          ) : (
            <React.Fragment>
              <AnimatedButton
                disabled={isUploading || !emailColumn}
                className={`button is-success ${isUploading && 'is-loading'}`}
                onClick={() => sendData()}
              >
                {emailColumn ? 'Finalize Upload!' : 'Must Select Email Column'}
              </AnimatedButton>
              {!!progress && (
                <progress
                  className="progress is-primary"
                  value={Math.round(progress * 100)}
                  max="100"
                />
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    </div>
  )
}

UploadFileOptions.propTypes = {
  handleModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
}

export default UploadFileOptions
/*

        <label className="label">Demographics</label>
        {Object.keys(demographicColumns).map(demographic => (
          <div className="field">
            <div className="field">
              <label className="label">{demographic} (Optional)</label>
              <div className="control">
                <Select
                  options={data[0].map((header, i) => ({ value: i, label: header }))}
                  onChange={val => updateDemographicValue(demographic, val)}
                  value={demographicColumns[demographic]}
                />
              </div>
              {!!demographicColumns[demographic] && (
                <p className="help">
                  <strong className="has-text-success">
                    {demographicValidCounts[demographic] || 0}
                  </strong>{' '}
                  Rows have valid values.{' '}
                  <strong className="has-text-danger">
                    {data.length - 1 - (demographicValidCounts[demographic] || 0)}
                  </strong>{' '}
                  don't match our demographic options. Please map those manually below.
                </p>
              )}
            </div>
            {!!demographicColumns[demographic] && (
              <React.Fragment>
                <p>Custom Mappings For {demographic}</p>
                {Object.entries(demographicColumnValueMaps[demographic] || {}).map(
                  ([csvValue, mapToValue]) => (
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <Select
                            options={uniqueColumnValues[demographicColumns[demographic]?.value]
                              ?.filter(value => {
                                if (!demographicsConfig || !demographicsConfig[demographic]) {
                                  return false
                                }
                                // ensure that this value does not match a demographic exactly
                                const validDemOptions = demographicsConfig[demographic].options
                                return !validDemOptions.includes(value)
                              })
                              .map(value => ({
                                value,
                                label: value,
                              }))}
                            onChange={val =>
                              updateDemographicMapValue(demographic, val.value, null)
                            }
                            value={{ value: csvValue, label: csvValue }}
                          />
                        </div>
                        <div className="field">
                          <Select
                            options={demographicsConfig[demographic]?.options?.map(value => ({
                              value,
                              label: value,
                            }))}
                            onChange={val =>
                              updateDemographicMapValue(demographic, csvValue, val.value)
                            }
                            value={{ value: mapToValue, label: mapToValue }}
                          />
                        </div>
                      </div>
                    </div>
                  ),
                )}
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <Select
                        options={uniqueColumnValues[demographicColumns[demographic]?.value]
                          ?.filter(value => {
                            if (!demographicsConfig || !demographicsConfig[demographic]) {
                              return false
                            }
                            // ensure that this value does not match a demographic exactly
                            const validDemOptions = demographicsConfig[demographic].options
                            return !validDemOptions.includes(value)
                          })
                          .map(value => ({
                            value,
                            label: value,
                          }))}
                        onChange={val => updateDemographicMapValue(demographic, val.value, null)}
                        value={null}
                      />
                    </div>
                    <div className="field">
                      <Select isDisabled />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        ))}
 */
