import React from 'react'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import {
  ReusableTextInput,
  ReusableCheckboxColumn,
  ReusableDropDownInput,
  CommonFields,
} from '../ReusableComponents/ReusableInputComponents'

const SendSmsForm = ({
  handleTemplateChange,
  SMS_TEMPLATES,
  handleInput,
  noOverlapCampaigns,
  setNoOverlapCampaigns,
  prevCampaigns,
  checkboxRows,
  handleSubmit,
  sendLoading,
  handleSubmitSelf,
  setStartScheduling,
  startScheduling,
  inputs,
}) => {
  return (
    <div style={{}}>
      <ReusableDropDownInput
        label={'Template Selection'}
        handleChange={handleTemplateChange}
        selectionList={SMS_TEMPLATES}
      />

      <ReusableTextInput
        label={'Growth Channel'}
        placeholder={'sms-tw-<your input>'}
        inputKey={'growthChannelCustom'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />

      {CommonFields({ handleInput, noOverlapCampaigns, prevCampaigns, setNoOverlapCampaigns })}

      <ReusableTextInput
        label={'Send To Self Number:'}
        placeholder={'Enter a 10 digit number you would like to send to'}
        inputKey={'selfNumber'}
        inputType={'number'}
        className={'field'}
        handleChange={handleInput}
      />

      <div className="field">
        <label className="label">Select From the Following Options</label>
      </div>

      <div className="columns">
        <ReusableCheckboxColumn checkboxRows={checkboxRows} />
        <div className="field is-marginless column">
          <ReusableTextInput
            label={'Re-target students after X days:'}
            placeholder={'7'}
            inputKey={'reTargetPeriod'}
            inputType={'number'}
            className={'field'}
            handleChange={handleInput}
          />
        </div>
      </div>

      <div className="columns">
        <div className="field is-marginless column">
          <ReusableTextInput
            label={'Max SMS Per School (optional)'}
            placeholder={'100'}
            inputKey={'maxSmsPerSchool'}
            inputType={'number'}
            className={'field'}
            handleChange={handleInput}
          />
        </div>
        <div className="field is-marginless column">
          <ReusableTextInput
            label={'Max SMS'}
            placeholder={'100'}
            inputKey={'maxSms'}
            inputType={'number'}
            className={'field'}
            handleChange={handleInput}
          />
        </div>
      </div>

      <div className="level send-buttons-box">
        <AnimatedButton
          onClick={handleSubmit}
          disabled={
            sendLoading ||
            !inputs.maxSms ||
            !inputs.campaignName ||
            !inputs.surveyId ||
            !inputs.surveyTopic
          }
          className={`level-item button is-success is-set-height ${sendLoading && 'is-loading'}`}
        >
          Send SMS
        </AnimatedButton>

        <AnimatedButton
          onClick={handleSubmitSelf}
          disabled={
            sendLoading ||
            !inputs.selfNumber ||
            !inputs.campaignName ||
            !inputs.surveyId ||
            !inputs.surveyTopic
          }
          className={`level-item button is-primary is-set-height ${sendLoading && 'is-loading'}`}
        >
          Send to self
        </AnimatedButton>

        <AnimatedButton
          onClick={() => setStartScheduling(true)}
          disabled={startScheduling}
          className={`level-item button is-primary is-set-height ${sendLoading && 'is-loading'}`}
        >
          {startScheduling ? 'Scroll Down' : 'Start Scheduling'}
        </AnimatedButton>
      </div>
    </div>
  )
}

export default SendSmsForm
