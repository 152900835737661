import React from 'react'
import Select from 'react-select'

/**
 * When you use this components in your code, you will need to handle the state of the inputs
 * by using a state like this in the parent component. Where
 * inputKey is the key in the state.
 *   const [inputs, setInputs] = useState({
    templateId: '27488124', // Postmark default
    provider: 'postmark',
    subject: '',
    campaignName: '',
    surveyId: '',
    maxEmails: undefined,
    maxEmailsPerSchool: undefined,
    message: undefined,
    surveyTopic: '',
    studentsLeft: 2000,
    list: '',
  })
 */
// This is a simple string inpout component
export const ReusableTextInput = ({
  label,
  placeholder,
  inputKey,
  inputType,
  className,
  handleChange,
}) => {
  return (
    <div className={className}>
      <label className="label">{label}</label>
      <div className="control">
        <input
          onChange={e => handleChange(inputKey, e.target.value)}
          className="input"
          type={inputType}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

/**
 * This object is needed to use the component properly
 *  const checkboxRow = {
      label: 'Only Send to Users',
      handleOnChange: () => setOnlyUsers(e => !e),
      value: onlyUsers,
    }
 */
// This is a simple checkbox component
export const ReusableCheckboxInput = ({ label, handleOnChange, value }) => {
  return (
    <div className={'field'}>
      <label className="checkbox">
        <input onChange={() => handleOnChange()} value={value} type="checkbox" />
      </label>
      <small className="has-padding-left">{label}</small>
    </div>
  )
}

/**
 This is an array that hoplds the variables needed for each checkboxObject
  to render the checkbox list.It is needed in order to use the ReusableCheckboxColumn
  component. The array should look like this, where setOnlyUsers, etc. are state variables
  const checkboxRows = [
    {
      label: 'Only Send to Users',
      handleOnChange: () => setOnlyUsers(e => !e),
      value: onlyUsers,
    },
    {
      label: 'Only Send to EdVisor Emails',
      handleOnChange: () => setOnlyEdVisors(e => !e),
      value: onlyEdVisors,
    },
    {
      label: 'Allow send to alumni',
      handleOnChange: () => setAllowAlumni(e => !e),
      value: allowAlumni,
    },
    {
      label: 'Save Sample?',
      handleOnChange: () => setSaveSampleBox(e => !e),
      value: saveSampleBox,
    },
  ]
 */
// This is a list of simple checkbox component
export const ReusableCheckboxColumn = ({ checkboxRows }) => {
  return (
    <div className="field is-marginless column">
      {checkboxRows.map(({ label, handleOnChange, value }) =>
        ReusableCheckboxInput({ label, handleOnChange, value })
      )}
    </div>
  )
}

// This is a simple Drop Down Menu with selections from selectionList
export const ReusableDropDownInput = ({ label, handleChange, selectionList }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <div className="select is-fullwidth">
          <select onChange={e => handleChange(e)}>
            {selectionList.map(({ value, name }) => (
              <option value={value}>{name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

/**
 * This is a select drop down menu that slects from [options], where an option is an object
 * like this one {value: 'value', label: 'label'}.
 * handleChange() receives the option object as a parameter.
 * @param {Object} props
 * @param {String} props.label
 * @param {String} props.options
 * @param {Function} props.handleChange
 * @param {String} props.help
 * @param {String} props.noOptionsMessage
 * @returns {JSX} Returns a JSX Select Drop Down Menu
 */
export const ReusableSelectDropDownInput = ({
  label,
  options,
  handleChange,
  help,
  noOptionsMessage,
}) => {
  const customStyles = {
    option: provided => ({
      ...provided,
    }),
    control: provided => ({
      ...provided,
      borderRadius: 0,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 5,
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: '#485fc7',
      paddingRight: '0.75em',
    }),
  }

  return (
    <div className="field">
      <label className="label">{label}</label>
      {help ? <p className="help">{help}</p> : ''}
      <div className="control">
        <Select
          isSearchable={true}
          styles={customStyles}
          options={options}
          onChange={e => handleChange(e)}
          noOptionsMessage={() => noOptionsMessage}
        />
      </div>
    </div>
  )
}

export const CommonFields = ({
  handleInput,
  noOverlapCampaigns,
  prevCampaigns,
  setNoOverlapCampaigns,
}) => {
  return (
    <div>
      <ReusableTextInput
        label={'List'}
        placeholder={'Send to specific list'}
        inputKey={'list'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <ReusableTextInput
        label={'Campaign Name'}
        placeholder={'Campaign Name'}
        inputKey={'campaignName'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <ReusableSelectDropDownInput
        label={'Avoid Overlap With Campaigns'}
        help={`Use this for AB Testing. Send to a new campaign name, but with no overlap between other
              campaigns. Then compare results between campaigns using Mailjet's Compare View.`}
        value={noOverlapCampaigns}
        options={prevCampaigns}
        handleOnChange={setNoOverlapCampaigns}
      />
      <ReusableTextInput
        label={'Survey ID'}
        placeholder={'Survey ID'}
        inputKey={'surveyId'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <ReusableTextInput
        label={'Survey Topic'}
        placeholder={'We\'re tracking the opinion of students on ___'}
        inputKey={'surveyTopic'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
    </div>
  )
}
