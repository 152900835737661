import React, { useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#18af90' : 'white',
  }),
  control: provided => ({
    ...provided,
    borderRadius: 0,
  }),
}

const FilterRow = ({
  filter,
  index,
  filterCategories,
  filterOptions,
  filterGetOptions,
  adjustFilter,
  removeFilter,
}) => {
  const [optionSearch, setOptionSearch] = useState('')
  let filteredFilterOptions =
    filter.category && filterOptions && filter.category.label === 'school'
      ? optionSearch && optionSearch.length > 2
        ? filterOptions.filter(option =>
          option.label.toLowerCase().includes(optionSearch.toLowerCase())
        )
        : []
      : filterOptions

  return (
    <div className="columns is-multiline filter-row">
      <div className="column is-full is-tooltip has-text-weight-bold">
        {filter && filter.option && filter.option.label}
      </div>
      <Select
        type="search"
        styles={customStyles}
        value={filter.category}
        options={filterCategories}
        onChange={e => {
          filterGetOptions(e.value, index)
          adjustFilter(index, 'category', e)
        }}
        className="column has-text-black"
      />
      <p className="column is-1">is</p>
      <Select
        type="search"
        styles={customStyles}
        value={filter.option}
        isDisabled={!filter.category}
        options={filteredFilterOptions}
        noOptionsMessage={() => (filter.category.label === 'school' ? 'Search!' : 'No options')}
        className="column has-text-black"
        onInputChange={e => setOptionSearch(e)}
        onChange={e => {
          adjustFilter(index, 'option', e)
        }}
      />
      <div style={{ width: 38 }} className="close-button" onClick={() => removeFilter(index)}>
        <img alt="remove" width="38" src="/blueCircleMinus.svg" />
      </div>
    </div>
  )
}

FilterRow.propTypes = {
  filter: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  filterCategories: PropTypes.array,
  filterOptions: PropTypes.array,
  filterGetOptions: PropTypes.func.isRequired,
  adjustFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
}

export default FilterRow
