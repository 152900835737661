import prefix from './prefix'
import request from './request'
import storage from '../lib/storage'

export const me = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/user/me').end((err, res) => {
      if (err) return reject(err)

      storage.set('user', res.body.user)
      return resolve(res.body.user)
    })
  })
}

export const setDemographics = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/user/demographics')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

export const updateDemographicCategories = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/demographics/update')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body.user)
      })
  })
}

export const markTutorialDone = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/user/tutorial-done')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

export const markSwipeLessonSeen = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/user/swipe-seen')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

export const fetchNotifications = function (page) {
  page = page || 1
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/user/notifications?page=' + page).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

export const readAllNotifications = function () {
  return new Promise((resolve, reject) => {
    request.post(prefix + '/api/user/notifications/readAll').end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

export const markNotificationClicked = function (notification) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/user/notifications/${notification}`).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

export const updateUsername = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/username')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

export const updatePassword = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/password')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

export const updateSecurityQuestion = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/securityQuestion')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

export const updateRecoveryEmail = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/recoveryEmail')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

export const securityQuestionOptions = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/user/securityOpts').end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}
