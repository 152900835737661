import React, { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import PropTypes from 'prop-types'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import useFilter from '../../context/FilterContext'

import './FeasibilityTable.scss'

const FeasibilityTable = ({ dataArray, columnsArray, fetchNewData, snackbarFunctions }) => {
  const columns = useMemo(() => columnsArray, [columnsArray])
  const data = useMemo(() => dataArray, [dataArray])
  let demographicTitle
  const borderStyle = '1px solid white'
  const { addFilter } = useFilter()

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  return (
    <div>
      <div
        style={{
          top: '0',
          position: 'sticky',
          zIndex: '2',
          height: '1rem',
          marginBottom: '1px',
          background: 'white',
        }}
      />
      <table className="table is-fullwidth has-background-grey-lighter" {...getTableProps()}>
        <thead style={{ position: 'sticky', zIndex: '2', top: '1rem', background: '#cccccc' }}>
          {headerGroups.map(headerGroup => {
            let mainHeaders = headerGroup.headers.length < 6
            return (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ top: `${mainHeaders ? '' : '-2px'}`, position: 'relative' }}
              >
                {headerGroup.headers.map((column, index) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className={column.isSorted ? 'has-text-info' : undefined}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      textAlign: 'center',
                      borderLeft: `${index === 0 ? '' : borderStyle}`,
                      borderBottom: borderStyle,
                    }}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            )
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            // Gets the number of cells with values in them
            let isDemographicTitle = row.cells.filter(elem => elem.value).length === 1
            let demographicValue = row?.cells?.[0]?.value
            let isTotals = row.id === '0'
            let specialClassName

            if (isTotals) {
              specialClassName = 'has-background-primary has-text-white has-text-weight-bold'
            } else if (isDemographicTitle) {
              specialClassName = isDemographicTitle
                ? 'has-background-success has-text-white has-text-weight-bold'
                : undefined
              demographicTitle = row?.cells?.[0]?.value ? row?.cells?.[0]?.value : undefined
            }
            //TODO: Edge case when the demographic value is unkonwn don't query
            // In the future get a cross list between the availabe filters and the from the togal menu
            // and their respective demographics in fesibility
            // Do this for both demograpphics title as well as possible values

            return (
              <tr
                {...row.getRowProps()}
                style={{
                  position: `${isTotals ? 'sticky' : ''}`,
                  zIndex: `${isTotals ? '2' : ''}`,
                  top: `${isTotals ? '7.5rem' : ''}`,
                }}
              >
                {row.cells.map((cell, index) => (
                  <td
                    className={specialClassName}
                    style={{
                      textAlign: `${row.cells.indexOf(cell) !== 0 ? 'center' : ''}`,
                      // borderLeft: `${index !== 0 && !specialClassName ? borderStyle : ''}`,
                      borderLeft: borderStyle,
                      borderBottom: `${!specialClassName ? borderStyle : ''}`,
                    }}
                    {...cell.getCellProps()}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: `${
                          row.cells.indexOf(cell) !== 0 ? 'center' : 'space-between'
                        }`,
                      }}
                    >
                      {/* TODO(Zap): Don't show the add button if the filter is already added.
                          Don't show add button if there are no demographics from that group to it, or there 
                          is not demographic selection in the menu like twoYearOrFourYear...
                          Also, add the ability to add to filter as or not just and????
                      */}
                      {cell.render('Cell')}
                      {demographicTitle !== demographicValue &&
                      demographicValue !== 'Totals' &&
                      demographicValue !== 'unknown' &&
                      demographicTitle !== 'twoYearFourYear' &&
                      cell.column.Header === 'Demographics' ? (
                          <AnimatedButton
                            className="button is-primary"
                            style={{
                              maxWidth: '30px',
                              maxHeight: '30px',
                              padding: '0',
                              background: 'none',
                            }}
                            onClick={async () => {
                              await addFilter({
                                key: cell.row.original.demographicsTopic,
                                value: demographicValue,
                                snackbarFunctions,
                              })
                              await fetchNewData()
                            }}
                          >
                            <img className="button-icon-feasibility" alt="ADD" src="/plus.png" />
                          </AnimatedButton>
                        ) : (
                          ''
                        )}
                    </div>
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

FeasibilityTable.propTypes = {
  dataArray: PropTypes.array,
  columnsArray: PropTypes.array.isRequired,
  fetchNewData: PropTypes.func,
  snackbarFunctions: PropTypes.object,
}

export default FeasibilityTable

/******************************************************************************************
 ******************************** REACT TABLE CUSTOMIZATION *******************************
 ******************************************************************************************
 *
 * React-rable can be very customizable!
 * We can customize different rows, td,th etc. based on the data we get.
 *
 * - To customize specific headers we can do the following:
 * ->
 *  In table > thead:  we can get the main header or subheaders by tapping
 *  in headerGroup.headers.length. We have 5 main headers so to get the top
 *  layer of main headers we can do the following let mainHeaders = headerGroup.headers.length < 6.
 * ->
 *  In table > thead > tr: we can get the specific headers by tapping into the follwoing properties
 *  const currHeader = column.Header. This will allow us to change the headers based on their value.
 *  We can customize the colors of specific headers and more.
 * ->
 *  In general if you are trying to achive some level of cusomization, look into the data that
 *  react-table provides at different levels! Then use inline style to change the style you want.
 *
 * - To customize specific data rows and fields do the following:
 * ->
 *  Similarly, to what we can do for the table headers we can also customize the table data fields.
 *  In table > tbody: we can get how many values a data row has by tapping into row.cells the folowing way
 *  let cellsValueLength = row.cells.filter(elem => elem.value).length. Based on this we can have subheaders
 *  and split the table data based on that.
 * ->
 *  In table > tbody > tr: we can change the style of different rows and row data elements by tapping into
 *  cell.column. One example is to change the style based of their parent. We can do it by setting the following
 *  varriable const cellHeader = cell.column.parent.Header
 * ->
 *  Like with headers in genersl if you are trying to achive som level of style customization, look into the data
 *  provided by react table at dirrfernt levels. Then use inline style to hcant the style you want.
 *
 *
 *
 * When sorting is enabled add the span below to thead > tr > th, right under {column.render('Header')}
 *
 * <span>
 * {column.isSorted ? (
 *   column.isSortedDesc ? (
 *     <img
 *       alt="down"
 *       width="15"
 *       className="is-arrow is-inline"
 *       src="/down.svg"
 *     />
 *   ) : (
 *     <img alt="up" width="15" className="is-arrow is-inline" src="/up.svg" />
 *   )
 * ) : (
 *   ''
 * )}
 * </span>
 ********************************************************************************************
 */
