import React, { useState } from 'react'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'

import useAuth from '../../context/AuthContext'

const SignIn = () => {
  const [email, handleEmail] = useState('')
  const [password, handlePassword] = useState('')

  const { login, loading, error } = useAuth()

  const handleSignIn = async () => {
    await login({ username: email, password })
  }

  return (
    <div style={{ height: '100vh', display: 'flex', position: 'relative' }}>
      <div className="box" style={{ width: '90%', margin: 'auto' }}>
        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              onChange={e => handleEmail(e.target.value)}
              className="input"
              type="text"
              placeholder="Email"
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Password</label>
          <div className="control">
            <input
              onChange={e => handlePassword(e.target.value)}
              className="input"
              type="password"
              placeholder="Password"
            />
          </div>
        </div>

        <AnimatedButton
          disabled={loading || !email || !password}
          onClick={() => handleSignIn()}
          className={`button is-success is-fullwidth ${loading && 'is-loading'}`}
        >
          Sign In
        </AnimatedButton>
        {error && <p style={{ marginTop: '1rem', textAlign: 'center', color: 'red' }}>{error}</p>}
      </div>
    </div>
  )
}

export default SignIn
