import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'

const OutreachLayout = ({ children, snackbarFunctions, handleModal }) => {
  return (
    <React.Fragment>
      <aside className="has-background-primary">
        <Sidebar snackbarFunctions={snackbarFunctions} handleModal={handleModal} />
      </aside>
      <main className="box">{children}</main>
    </React.Fragment>
  )
}

export default OutreachLayout
