import React, { useState } from 'react'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import { Parser } from 'json2csv'
import useFilter from '../../context/FilterContext'
import api from '../../api'

const DownloadCsv = ({ handleModal, saveSample, snackbarFunctions, setLoadedSample, jsonData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [filename, setFilename] = useState('')
  const [notes, setNotes] = useState('')
  const { filterSets } = useFilter()

  const downloadToCsv = () => {
    // Set loading
    setIsLoading(true)

    if (!jsonData || jsonData === undefined || Object.keys(jsonData).length === 0) {
      // Before closing give a message or disable button if no data
      snackbarFunctions.newSnackbar({
        message: 'No Data selected! Please select data before downloading it.',
        type: 'danger',
      })
      // Close the modal
      handleModal(null)
      return 0
    }

    // Convert JSON array to CSV
    const json2csvParser = new Parser({ fields })
    let csvFile = ''
    //Add Notest if any
    if (notes) {
      csvFile = '"Notes","' + notes + '"\n'
    }
    //Add filters if any
    if (filterSets) {
      const formatedQuery = api.filters.formatFilters(filterSets)
      formatedQuery.forEach(filterArray => {
        let newFilterString = ''
        filterArray.forEach((filter, index) => {
          if (index === 0) {
            newFilterString = 'Selected ' + filter.variable.toString() + ','
          }
          // TODO: if demographics is school, convert schoolId to schoolName
          newFilterString = newFilterString + filter.value.toString() + ' '
        })
        csvFile = csvFile + newFilterString + '\n'
      })
    }

    //For each filter, add filter and value
    csvFile = csvFile + json2csvParser.parse(jsonData)

    // Create Blob and add blob to link
    // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
    //Download successfull message
    snackbarFunctions.newSnackbar({
      message: 'Downloaded Feasibility CSV Successfully!',
      type: 'success',
    })

    // Close the modal
    handleModal(null)
    return 1
  }

  return (
    <div className="box">
      <div className="field">
        <label className="label">File Name</label>
        <div className="control">
          <input
            onChange={e => setFilename(e.target.value)}
            className="input"
            type="text"
            placeholder="Title of Csv File"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Notes</label>
        <div className="control">
          <textarea
            onChange={e => setNotes(e.target.value)}
            className="textarea"
            placeholder="This Feasibility file is used for..."
          />
        </div>
      </div>
      <AnimatedButton
        disabled={isLoading || !filename}
        onClick={() => downloadToCsv()}
        className={`button is-success is-fullwidth ${isLoading && 'is-loading'}`}
      >
        Download CSV
      </AnimatedButton>
    </div>
  )
}

const fields = [
  {
    label: 'Demographics',
    value: 'demographics',
  },
  {
    label: 'Total Estimated Responses',
    value: 'feasibility',
  },
  {
    label: 'User Count',
    value: 'userCount',
  },
  {
    label: 'Email Count',
    value: 'emailCount',
  },
  {
    label: 'Open Rate',
    value: 'openRate',
  },
  {
    label: 'Click Rate',
    value: 'clickRate',
  },
  {
    label: 'Email Convertion Rate',
    value: 'conversionRate',
  },
  {
    label: 'Email Estimated Responses',
    value: 'emailFeasibility',
  },
  {
    label: 'Phone Count',
    value: 'smsCount',
  },
  {
    label: 'SMS Conversion Rate',
    value: 'smsConversionRate',
  },
  {
    label: 'SMS Estimated Responses',
    value: 'smsFeasibility',
  },
]

export default DownloadCsv
