import prefix from './prefix'
import request from './request'

/**
 * Get Active Big Boxes
 * Get a list of all active bigboxes
 * @returns {Array} Bigbox Objects
 */
export const getActiveBigBoxes = () => {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/panel/bigbox/active').end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

/**
 * Create a new Big Box
 * @param {Object} Bigbox : An object containing the bigbox information
 * @returns {Object} Bigbox Object || Error Message
 */
export const createBigBox = bigbox => {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/panel/bigbox/create')
      .send(bigbox)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

/**
 * Close a Big Box
 * This will mark a bigbox as "completed"
 * @param {String} _id
 * @returns {Object} Updated Bigbox Object || Error Message
 */
export const closeBigBox = ({ _id }) => {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/panel/bigbox/close')
      .send({ bigBoxId: _id })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

/**
 * Update a Big Box
 * @param {String} _id Bigbox string to update
 * @param {Object} propertiesToUpdate Object containing the properties to update
 * @returns {Object} Big Box Object || Error Message
 */
export const updateBigBox = ({ _id, propertiesToUpdate }) => {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/panel/bigbox/update')
      .send({ bigBoxId: _id, properties: propertiesToUpdate })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}
