import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Modal from './Modal/Modal'
import DataCard from './DataCard/DataCard'
import SendEmail from './SendEmail/SendEmail'
import SendSms from './SendSms/SendSms'
import SaveSample from './SaveSample/SaveSample'
import SetSample from './SetSample/SetSample'
import ViewSamples from './ViewSamples/ViewSamples'
import UploadFile from './UploadFile/UploadFile'
import Table from './Table/Table'
import UploadFileOptions from './UploadFile/UploadFileOptions'
import ScheduledJobs from './ScheduledJobs/ScheduledJobs'
import SnackBar from './SnackBar/SnackBar'
import ViewEmails from './ViewEmails/ViewEmails'
import ViewSms from './ViewSms/ViewSms'
import api from '../api'
import FieldingLayout from '../layout/FieldingLayout'
import SurveySync from './SurveySync/SurveySync'
import useFilter from '../context/FilterContext'
import useMessage from '../context/MessageContext'

const Fielding = () => {
  /*
  Filter Logic
  */
  const { filterSets, setFilterSets } = useFilter()

  // Filter headers
  const [filterHeaders, setFilterHeaders] = useState({
    schoolBreakdown: false,
    loadStatistics: false,
  })

  /*
    Loading Message
  */
  const { setLoadingMessage } = useMessage()
  const [loadedSample, setLoadedSample] = useState()
  /*
  Modal Logic
  Other components in the app use handleModal and pass it a string to enable specific modals.
  IE: onClick={() => handleModal('save-sample')}
  The string passed determines which modal child content is loaded into the Modal.
  */
  const [modalState, setModalState] = useState({ open: false, modal: null })
  const handleModal = useCallback((modal, passedData) => {
    setModalState(modalState =>
      (modalState.modal && modal === modalState.modal.title) || !modal
        ? { open: false, modal: null }
        : { open: true, modal: { title: modal, data: passedData } }
    )
  }, [])

  /*
    Snackbars

    Everything is passed in one variable called snackbarFunctions
    To create a new snackbar, pass snackbarFunctions to the component and run
    snackbarFunctions.newSnackbar({message: "My Message", type: "danger"})

    Type uses bulma color variables to change the background of the snackbar.

    Functions are wrapped inside of useCallback so they are memoized and not re-created
    each re-render.
  */

  const [snackbars, setSnackbars] = useState([])

  const snackbarFunctions = {
    newSnackbar: useCallback(snack => {
      let index = Math.random()
      setSnackbars(prevState =>
        prevState.concat({
          message: snack.message,
          type: snack.type,
          random: index,
        })
      )
      setTimeout(() => {
        setSnackbars(prevState => prevState.filter(e => e.random !== index))
      }, 4000)
    }, []),
    removeSnackbar: useCallback(index => {
      setSnackbars(prevState => {
        let newState = [...prevState]
        newState.splice(index, 1)
        return newState
      })
    }, []),
    snackbars: snackbars,
  }

  /*
  Data Logic

  We set stateData and then push it into useMemo as required by react-table.
  overviewData controls the overview field.

  We assume that the first row of data from API is going to be the overview data.
  */
  const [stateData, setStateData] = useState()
  const [overviewData, setOverviewData] = useState()

  // Function used by the search button to get new data
  const fetchNewData = useCallback(async () => {
    if (!filterHeaders.loadStatistics) {
      setStateData([])
      setOverviewData([
        {
          institution: 'Overall',
          name: 'Overall',
          emails: 'N/A',
          users: 'N/A',
          uniqueRecentSubmits: 'N/A',
          openRate: 'N/A',
          clickRate: 'N/A',
          phones: 'N/A',
        },
      ])
      return
    }

    setLoadingMessage('Fetching Data')

    let result
    try {
      result = await api.filters.getDataFromFilters(filterSets, filterHeaders)
      snackbarFunctions.newSnackbar({ message: 'Fetched new data', type: 'success' })
      setLoadingMessage()
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Error catching data',
        type: 'danger',
      })
      setLoadingMessage()
      console.log(err)
    }

    setStateData(result ? result.slice(1) : null) // If there is a result, remove the Overview
    setOverviewData(result ? result.slice(0, 1) : null) // If there is a result, take the Overview
  }, [filterSets, snackbarFunctions])

  const [fetchingData, setFetchingData] = useState(false)
  useEffect(() => {
    if (fetchingData) {
      fetchNewData()

      setFetchingData(false)
    }
  }, [fetchingData, fetchNewData])

  // Dinamically loads the page if there are filters selected.
  // Disable temporariliy as leading messages are not working well for this specific case,
  // and the button is not getting disabled.
  // useEffect(() => {
  //   if (filterSets[0]?.filter?.[0] && Object.keys(filterSets[0].filter[0]).length !== 0) {
  //     fetchNewData()
  //   }
  // }, [])

  // Turn the stateData into useMemo data for react-table
  const data = useMemo(() => {
    return stateData
  }, [stateData])

  /*
    Save sample
  */
  // Function to save a new sample
  const saveSample = async (title, notes) => {
    try {
      await api.samples.newSample(title, filterSets, notes)
    } catch (err) {
      console.log(err)
    }
  }

  const applyFilter = async newFilters => {
    let newFilterSets = [...newFilters]
    setFilterSets(newFilters)

    // Fetch Data
    setFetchingData(true)

    setLoadingMessage('Fetching Data')
    newFilterSets.forEach(async (fset, findex) => {
      fset.filter.forEach(async (filter, i) => {
        let newOptions
        try {
          newOptions = await api.filters.getFilterOptions(filter.category.value)
        } catch (err) {
          snackbarFunctions.newSnackbar({
            message: 'Error getting initial options',
            type: 'danger',
          })
          console.log(err)
          return
        }

        newFilterSets[findex].options[i] = newOptions
        newFilterSets[findex].filter[i].option.label = newOptions.find(
          no => no.value === newFilterSets[findex].filter[i].option.value
        ).label
      })
    })
    setFilterSets(newFilterSets)

    // Remove modal
    handleModal(null)
    setLoadingMessage(null)
  }

  /*
    Schedule Emails
  */
  const scheduleEmails = ({
    schedules,
    campaignName,
    templateId,
    messageLimit,
    subject,
    selectedSchoolId,
    message,
    surveyId,
    maxEmails,
    maxEmailsPerSchool,
    globalVariables,
    onlyUsers,
    onlyEdVisors,
    allowAlumni,
    provider,
    ...rest
  }) => {
    try {
      api.email.scheduleEmails({
        schedules,
        filterSets,
        campaignName: campaignName,
        templateId: templateId,
        messageLimit: messageLimit || undefined,
        subject: subject,
        selectedSchoolId: selectedSchoolId,
        surveyId: surveyId || undefined,
        message: message || undefined,
        maxEmails,
        maxEmailsPerSchool,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        allowAlumni,
        provider,
        ...rest,
      })

      snackbarFunctions.newSnackbar({
        message: `Emails are scheduled with ${provider}!`,
        type: 'success',
      })
      handleModal(null)
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Could not schedule email',
        type: 'danger',
      })
    }
  }

  /*
    Generate Estimate
  */
  const generateEstimate = async ({
    campaignName,
    templateId,
    messageLimit,
    subject,
    selectedSchoolId,
    message,
    surveyId,
    maxEmails,
    maxEmailsPerSchool,
    globalVariables,
    onlyUsers,
    onlyEdVisors,
    allowAlumni,
    provider,
    ...rest
  }) => {
    let counts = await api.email.generateEstimate({
      filterSets,
      campaignName: campaignName,
      templateId: templateId,
      messageLimit: messageLimit || undefined,
      subject: subject,
      selectedSchoolId: selectedSchoolId,
      surveyId: surveyId || undefined,
      message: message || undefined,
      maxEmails,
      maxEmailsPerSchool,
      globalVariables,
      onlyUsers,
      onlyEdVisors,
      allowAlumni,
      provider,
      ...rest,
    })

    return counts
  }

  /*
    Send Email
  */

  const sendEmail = ({ messageLimit, message, surveyId, ...rest }) => {
    try {
      api.email.sendEmail({
        filterSets,
        messageLimit: messageLimit || undefined,
        surveyId: surveyId || undefined,
        message: message || undefined,
        ...rest,
      })

      snackbarFunctions.newSnackbar({ message: 'Emails are being sent!', type: 'success' })
      handleModal(null)
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Could not send email',
        type: 'danger',
      })
    }
  }

  const sendSelf = ({ provider, ...rest }) => {
    try {
      api.email.sendSelf({ provider, ...rest })

      snackbarFunctions.newSnackbar({
        message: `Email is being sent to yourself with ${provider}!`,
        type: 'success',
      })
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Could not send email to self',
        type: 'danger',
      })
    }
  }

  /*
    SEND SMS
  */

  const sendSms = ({
    campaignName,
    templateId,
    messageLimit,
    selectedSchoolId,
    message,
    surveyId,
    maxSms,
    maxSmsPerSchool,
    reTargetPeriod,
    globalVariables,
    onlyUsers,
    onlyEdVisors,
    onlyExactData,
    allowAlumni,
    ...rest
  }) => {
    try {
      api.sms.sendSms({
        filterSets,
        campaignName: campaignName,
        templateId: templateId,
        messageLimit: messageLimit || undefined,
        selectedSchoolId: selectedSchoolId,
        surveyId: surveyId || undefined,
        message: message || undefined,
        maxSms,
        maxSmsPerSchool,
        reTargetPeriod,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        onlyExactData,
        allowAlumni,
        ...rest,
      })

      snackbarFunctions.newSnackbar({ message: 'SMS are being sent!', type: 'success' })
      handleModal(null)
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Could not send SMS',
        type: 'danger',
      })
    }
  }

  const scheduleSms = ({
    schedules,
    campaignName,
    templateId,
    messageLimit,
    selectedSchoolId,
    message,
    surveyId,
    maxSms,
    maxSmsPerSchool,
    reTargetPeriod,
    onlyUsers,
    onlyEdVisors,
    onlyExactData,
    allowAlumni,
    globalVariables,
    deduplicateAgainst,
  }) => {
    try {
      api.scheduledJobs.scheduleSms({
        schedules,
        campaignName,
        templateId,
        messageLimit,
        selectedSchoolId,
        message,
        surveyId,
        maxSms,
        maxSmsPerSchool,
        reTargetPeriod,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        onlyExactData,
        allowAlumni,
        filterSets,
        deduplicateAgainst,
      })

      snackbarFunctions.newSnackbar({
        message: 'Sms are scheduled with Twilio!',
        type: 'success',
      })
      handleModal(null)
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Could not schedule sms',
        type: 'danger',
      })
    }
  }

  const sendSmsToSelf = async ({
    campaignName,
    templateId,
    surveyId,
    selfNumber,
    globalVariables,
  }) => {
    try {
      await api.sms.sendSmsToSelf({
        campaignName: campaignName,
        templateId: templateId,
        surveyId: surveyId,
        selfNumber: selfNumber,
        globalVariables,
      })
      snackbarFunctions.newSnackbar({
        message: 'Sms is being sent to yourself!',
        type: 'success',
      })
    } catch (err) {
      snackbarFunctions.newSnackbar({
        message: err.message || 'Could not send sms to self.',
        type: 'danger',
      })
    }
  }

  const downloadSample = () => window.open(api.filters.downloadSampleLink(filterSets), '_blank')

  const emailModalVisible =
    modalState && modalState.modal && modalState.modal.title === 'send-email' && modalState.open

  const smsModalVisible = modalState?.modal?.title === 'send-sms' && modalState.open

  return (
    <React.Fragment>
      <SnackBar snackbarFunctions={snackbarFunctions} />
      <Modal handleModal={handleModal} hidden={!emailModalVisible}>
        <SendEmail
          sendEmail={sendEmail}
          generateEstimate={generateEstimate}
          data={
            modalState &&
            modalState.modal &&
            modalState.modal.title === 'send-email' &&
            modalState.modal.data
          }
          saveSample={saveSample}
          snackbarFunctions={snackbarFunctions}
          sendSelf={sendSelf}
          visible={emailModalVisible}
          scheduleEmails={scheduleEmails}
        />
      </Modal>
      <Modal handleModal={handleModal} hidden={!smsModalVisible}>
        <SendSms
          sendSms={sendSms}
          data={
            modalState &&
            modalState.modal &&
            modalState.modal.title === 'send-sms' &&
            modalState.modal.data
          }
          snackbarFunctions={snackbarFunctions}
          sendSelf={sendSmsToSelf}
          visible={smsModalVisible}
          scheduleSms={scheduleSms}
        />
      </Modal>
      {modalState.open && !emailModalVisible && !smsModalVisible && (
        <Modal handleModal={handleModal}>
          <React.Fragment>
            {modalState.modal.title === 'save-sample' && (
              <SaveSample
                snackbarFunctions={snackbarFunctions}
                saveSample={saveSample}
                handleModal={handleModal}
                setLoadedSample={setLoadedSample}
              />
            )}
            {modalState.modal.title === 'survey-sync' && (
              <SurveySync
                handleModal={handleModal}
                setFetchingData={setFetchingData}
                applyFilter={applyFilter}
              />
            )}
            {modalState.modal.title === 'view-samples' && (
              <ViewSamples applyFilter={applyFilter} setLoadedSample={setLoadedSample} />
            )}
            {modalState.modal.title === 'view-emails' && (
              <ViewEmails snackbarFunctions={snackbarFunctions} applyFilter={applyFilter} />
            )}
            {modalState.modal.title === 'view-sms' && (
              <ViewSms
                snackbarFunctions={snackbarFunctions}
                applyFilter={applyFilter}
                handleModal={handleModal}
              />
            )}
            {modalState.modal.title === 'upload-data' && (
              <UploadFile snackbarFunctions={snackbarFunctions} handleModal={handleModal} />
            )}
            {modalState.modal.title === 'upload-options' && (
              <UploadFileOptions
                snackbarFunctions={snackbarFunctions}
                handleModal={handleModal}
                data={modalState.modal.data}
              />
            )}
            {modalState.modal.title === 'scheduled-jobs' && (
              <ScheduledJobs snackbarFunctions={snackbarFunctions} handleModal={handleModal} />
            )}
            {modalState.modal.title === 'set-sample' && (
              <SetSample
                snackbarFunctions={snackbarFunctions}
                saveSample={saveSample}
                handleModal={handleModal}
                setLoadedSample={setLoadedSample}
              />
            )}
          </React.Fragment>
        </Modal>
      )}
      {
        <FieldingLayout
          filterN={stateData ? stateData.length : 0}
          fetchNewData={fetchNewData}
          data={data}
          handleModal={handleModal}
          snackbarFunctions={snackbarFunctions}
          loadedSample={loadedSample}
          filterHeaders={filterHeaders}
          setFilterHeaders={setFilterHeaders}
        >
          <React.Fragment>
            {overviewData && overviewData[0] && (
              <DataCard
                handleModal={handleModal}
                title="Overview"
                download={downloadSample}
                data={{
                  emails: overviewData[0].emails || 'NA',
                  users: overviewData[0].users || 'NA',
                  uniqueRecentSubmits: overviewData[0].uniqueRecentSubmits || 'NA',
                  openRate: overviewData[0].openRate || 'NA',
                  clickRate: overviewData[0].clickRate || 'NA',
                  phones: overviewData[0].phones || 'NA',
                }}
              />
            )}

            {data && data[0] && (
              <div>
                <Table handleModal={handleModal} data={data} download={downloadSample} />
              </div>
            )}
          </React.Fragment>
        </FieldingLayout>
      }
    </React.Fragment>
  )
}

export default Fielding
