import React, { useState } from 'react'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import PropTypes from 'prop-types'
import api from '../../api'

const SurveySync = ({ handleModal, applyFilter, setFetchingData }) => {
  const [surveyID, setSurveyID] = useState('')
  const [loading, setLoading] = useState(false)

  const submitID = async () => {
    setLoading(true)
    setFetchingData(true)

    try {
      let data = await api.survey.getTargeted(surveyID)
      if (data) applyFilter(data.filters, data.filterAndOr)
    } catch (err) {
      setLoading(false)
      setFetchingData(false)
    }
  }

  return (
    <React.Fragment>
      <div className="box has-background-white send-email-container">
        <div className="field">
          <label className="has-text-weight-light	is-marginless">
            <small>Survey ID</small>
          </label>
          <div className="control">
            <input onChange={e => setSurveyID(e.target.value)} className="input" />
          </div>
        </div>

        <AnimatedButton
          onClick={submitID}
          disabled={loading || !surveyID}
          className={`is-fullwidth button is-success ${loading && 'is-loading'}`}
        >
          Get Schools
        </AnimatedButton>
      </div>
    </React.Fragment>
  )
}

SurveySync.propTypes = {
  handleModal: PropTypes.func,
  setFetchingData: PropTypes.func,
  applyFilter: PropTypes.func,
}

export default SurveySync
