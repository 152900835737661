import request from './request'
import prefix from './prefix'
import { formatFilters } from './filters'

export const sendSms = ({
  filters,
  andor,
  campaignName,
  templateId,
  messageLimit,
  subject,
  selectedSchoolId,
  message,
  surveyId,
  maxSms,
  maxSmsPerSchool,
  reTargetPeriod,
  globalVariables,
  onlyUsers,
  onlyEdVisors,
  onlyExactData,
  allowAlumni,
  filterSets,
  ...rest
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Format data
      const formattedQuery = formatFilters(filterSets)

      if (selectedSchoolId) {
        formattedQuery.push([{ variable: 'school', value: selectedSchoolId }])
      }

      let data = {
        filters: formattedQuery,
        campaignName,
        templateId,
        messageLimit,
        subject,
        message,
        surveyId,
        maxSms,
        maxSmsPerSchool,
        reTargetPeriod,
        globalVariables,
        onlyUsers,
        onlyEdVisors,
        onlyExactData,
        allowAlumni,
        ...rest,
      }
      let res = await request.post(prefix + '/api/panel/send-sms-campaign').send(data)

      if (res?.body?.error) {
        return reject(res.body.error)
      }

      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const smsCampaignNames = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + '/api/panel/sms-campaign-names')

      if (res?.body?.error) return reject(res.body.error)
      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}

export const sendSmsToSelf = ({
  campaignName,
  templateId,
  selfNumber,
  surveyId,
  globalVariables,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = {
        campaignName: campaignName,
        templateId: templateId,
        selfNumber: selfNumber,
        surveyId: surveyId,
        globalVariables,
      }

      let res = await request.post(prefix + '/api/panel/sms-send2self').send(data)
      if (res?.body?.error) return reject(res.body.error)
      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const getSms = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const d1 = new Date()
      let res = await request
        .get(prefix + '/api/panel/sms-campaigns')
        .query({ lastXCampaigns: '10' })

      const d2 = new Date()

      console.log(`TIME TO GET CAMPAIGNS: ${(d2.getTime() - d1.getTime()) / 1000}s`)

      if (res?.body?.error) return reject(res.body.error)

      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}
