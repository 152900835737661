import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import PropTypes from 'prop-types'

const FieldingLayout = ({
  filterN,
  fetchNewData,
  handleModal,
  children,
  snackbarFunctions,
  filterHeaders,
  setFilterHeaders,
}) => {
  return (
    <React.Fragment>
      <aside className="has-background-primary">
        <Sidebar
          filterN={filterN}
          fetchNewData={fetchNewData}
          handleModal={handleModal}
          snackbarFunctions={snackbarFunctions}
          filterHeaders={filterHeaders}
          setFilterHeaders={setFilterHeaders}
        />
      </aside>
      <main className="box">{children}</main>
    </React.Fragment>
  )
}

FieldingLayout.propTypes = PropTypes.object.isRequired

export default FieldingLayout
