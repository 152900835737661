import request from './request'
import prefix from './prefix'
import _ from 'lodash'
import { Promise } from 'bluebird' //used for mapSeries

export const uploadEmails = async (data, setProgress) => {
  let { options, emails: allEmails } = data

  // Reset progress state
  setProgress(0)

  // Map over emails 1000 at a time in series.
  return await Promise.mapSeries(_.chunk(allEmails, 1000), (current, index, length) => {
    return new Promise((resolve, reject) => {
      request
        .post(prefix + '/api/panel/emails')
        .send({ options, emails: current })
        .then(res => {
          setProgress(index / length)
          resolve(res)
        })
        .catch(err => reject(err))
    })
  })
}
