import request from './request'
import prefix from './prefix'
import storage from '../lib/storage'

export const uploadEmails = data => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.post(prefix + '/api/panel/emails').send(data)
      if (res && res.body && res.body.error) return reject(res.body.error)
      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}

export const getFilterCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + '/api/panel/filters/categories')
      return resolve(res.body)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getFilterOptions = category => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + `/api/panel/filters/options/${category}`)
      return resolve(res.body)
    } catch (err) {
      return reject(err)
    }
  })
}

export const downloadSampleLink = filterSets =>
  `${prefix}/api/panel/csv-download?filters=${JSON.stringify(
    formatFilters(filterSets)
  )}&token=${storage.get('token')}`

export const getDataFromFilters = function (filterSets, filterHeaders) {
  return new Promise(async (resolve, reject) => {
    try {
      // Format data
      const formattedQuery = formatFilters(filterSets)

      // Send data
      console.log('FILTER HEADERS: ', filterHeaders)
      let res = await request.get(prefix + '/api/panel/sample').query({
        filters: JSON.stringify(formattedQuery),
        school_breakdown: filterHeaders.schoolBreakdown,
      })
      return resolve(res.body)
    } catch (err) {
      return reject(err)
    }
  })
}

export const getFeasibilityDataFromFilters = function (filterSets) {
  return new Promise(async (resolve, reject) => {
    try {
      // Format data
      const formattedQuery = formatFilters(filterSets)
      // Send data
      let res = await request.get(prefix + '/api/panel/feasibility-sample').query({
        filters: JSON.stringify(formattedQuery),
      })
      return resolve(res.body)
    } catch (err) {
      return reject(err)
    }
  })
}

export function formatFilters(filterSets) {
  let formattedQuery = []

  filterSets.forEach((fset, findex) => {
    let currentFilterSet = []
    fset.filter.forEach((x, i) => {
      if (!x || !x.category || !x.option) {
        return
      }
      let formattedFilter = {
        variable: x.category.value,
        value: x.option.value,
      }

      currentFilterSet.push(formattedFilter)
    })

    formattedQuery.push(currentFilterSet)
  })

  return formattedQuery
}
