import React from 'react'
import './Navbar.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import useAuth from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import useMessage from '../../context/MessageContext'

const Navbar = ({ loadedSample }) => {
  const { logout, user } = useAuth()
  const location = useLocation()
  const pageLocation = location.pathname
  const { loadingMessage } = useMessage()
  console.log('LOADING MESSAGE: ', loadingMessage)

  const handleSignOut = () => {
    logout()
  }

  return (
    <nav className="navbar is-fixed-top has-background-info">
      <div className="navbar-brand">
        <a href="/" className="navbar-item">
          <span className="is-centered">
            <img src="/pulselogo.png" alt="logo"></img>
            <h1 className="title is-4 has-text-light is-spaced">Panel Management</h1>
          </span>
        </a>
        {loadedSample && (
          <h1 className="title is-6 has-text-light is-centered">"{loadedSample}"</h1>
        )}
      </div>
      <div className="navbar-menu">
        {user && (
          <div className="navbar-end is-centered">
            {loadingMessage && (
              <div className="has-text-success is-centered loading-container">
                <span>{loadingMessage}</span> <img alt="loading" src="/loading.gif" />
              </div>
            )}
            <Link
              to={'/outreach'}
              className={`button is-height-100 is-radiusless ${
                pageLocation === '/outreach' ? 'is-light' : 'is-success'
              }`}
            >
              <strong>Outreach</strong>
            </Link>
            <Link
              to={'/feasibility'}
              className={`button is-height-100 is-radiusless ${
                pageLocation === '/feasibility' ? 'is-light' : 'is-success'
              }`}
              style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
            >
              <strong>Feasibility</strong>
            </Link>
            <Link
              to={'/'}
              className={`button is-height-100 is-radiusless ${
                pageLocation === '/' ? 'is-light' : 'is-success'
              }`}
              style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
            >
              <strong>Fielding</strong>
            </Link>
            <Link
              to={'/bigbox'}
              className={`button is-height-100 is-radiusless ${
                pageLocation === '/bigbox' ? 'is-light' : 'is-success'
              }`}
              style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
            >
              <strong>Big Box</strong>
            </Link>
            <button
              className="button is-success is-height-100 is-radiusless"
              style={{ borderLeft: 'solid 1px rgb(222, 222, 222)' }}
              onClick={() => handleSignOut()}
            >
              <strong>Log out</strong>
            </button>
          </div>
        )}
      </div>
    </nav>
  )
}

// Navbar.propTypes = {
//   loadingMessage: PropTypes.string,
// }

export default Navbar
