import React, { useEffect, useCallback } from 'react'
import FilterRow from './FilterRow'
import api from '../../api'
import './Filter.scss'
import PropTypes from 'prop-types'

import useFilter from '../../context/FilterContext'
import useMessage from '../../context/MessageContext'

const Filter = ({ filters, fetchNewData, snackbarFunctions, findex }) => {
  const { setFilterCategories, setFilterSets, filterCategories, filterSets } = useFilter()
  const { loadingMessage, setLoadingMessage } = useMessage()

  // Deconstruct the newSnackbar function so snackbarFunctions.snackbars does not trigger re-render.
  const { newSnackbar } = snackbarFunctions
  const filterGetCategories = useCallback(async () => {
    setLoadingMessage('Fetching Categories')
    // Get all categories for left dropdown
    let filterCategories
    try {
      filterCategories = await api.filters.getFilterCategories()
    } catch (err) {
      newSnackbar({ message: 'Error getting categories', type: 'danger' })
      console.log(err)
      return
    }
    setLoadingMessage()

    // Format for React-Select
    let formattedCategories = []
    filterCategories.forEach(item => {
      // Create our new item
      let newItem = {
        value: item.category,
        label: item.category,
        group: item.type,
      }

      // Check if the group exists, then add the item to the group or new group
      if (formattedCategories.find(e => e.label === newItem.group)) {
        formattedCategories.find(e => e.label === newItem.group).options.push(newItem)
      } else {
        formattedCategories.push({
          label: newItem.group,
          options: [newItem],
        })
      }
    })

    // Set the categories
    setFilterCategories(formattedCategories)
  }, [setFilterCategories, newSnackbar, setLoadingMessage])

  useEffect(() => {
    filterGetCategories()
  }, [filterGetCategories]) // Linting error is useless

  const addFilter = () => {
    // Add a new filter and an empty options array
    let currentFilterSets = [...filterSets]
    currentFilterSets[findex].filter = [...currentFilterSets[findex].filter, {}]
    currentFilterSets[findex].options = [...currentFilterSets[findex].options, {}]
    setFilterSets(currentFilterSets)
  }

  const removeFilter = i => {
    // Remove a filter and it's options
    let currentFilterSets = [...filterSets]
    currentFilterSets[findex].filter = [
      ...currentFilterSets[findex].filter.slice(0, i),
      ...currentFilterSets[findex].filter.slice(i + 1),
    ]

    currentFilterSets[findex].options = [
      ...currentFilterSets[findex].options.slice(0, i),
      ...currentFilterSets[findex].options.slice(i + 1),
    ]

    if (currentFilterSets[findex].filter.length === 0) {
      currentFilterSets.splice(findex, 1)
    }
    setFilterSets(currentFilterSets)
  }

  const filterGetOptions = async (category, index) => {
    setLoadingMessage('Fetching Options')
    // Get the options for the selected category
    let newOptions
    try {
      newOptions = await api.filters.getFilterOptions(category)
    } catch (err) {
      snackbarFunctions.newSnackbar({ message: 'Error getting options', type: 'danger' })
      console.log(err)
      return
    }

    setLoadingMessage()

    // Set the old array of values
    let currentFilterSets = [...filterSets]
    currentFilterSets[findex].options[index] = newOptions
    setFilterSets(currentFilterSets)
  }

  const adjustFilter = (i, selection, value) => {
    // Handle changes to a filter
    let currentFilters = [...filters]
    currentFilters[i][selection] = value
    let currentFilterSets = [...filterSets]
    currentFilterSets[findex].filter = currentFilters
    setFilterSets(currentFilterSets)
  }

  return (
    <div className="has-padding filter-container">
      <div className="block columns">
        <p className="column is-vertical-center">ANY of the following are true:</p>
      </div>
      {/* Loop over each of the existing config options and represent them in an async select*/}
      {/* We probably want to have two groups: demographics, and questions */}
      {filters.map((filter, i) => {
        return (
          <FilterRow
            filter={filter}
            key={i}
            index={i}
            filterCategories={filterCategories}
            filterOptions={filterSets[findex].options[i]}
            filterGetOptions={filterGetOptions}
            removeFilter={removeFilter}
            adjustFilter={adjustFilter}
          />
        )
      })}
      <div className="columns">
        <div className="column">
          <button className="button" onClick={addFilter}>
            + Add a Condition Group
          </button>
        </div>
        <div className="column">
          <button
            className={`button is-success is-fullwidth ${
              loadingMessage ? 'is-loading' : undefined
            }`}
            onClick={fetchNewData}
            disabled={loadingMessage}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  )
}

Filter.propTypes = {
  filters: PropTypes.array.isRequired,
  fetchNewData: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
  findex: PropTypes.number.isRequired,
}

export default Filter
