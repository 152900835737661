import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const Modal = ({ children, handleModal, hidden }) => {
  /*
    How to use Modal:

    In an upper-level component (IE: Layout) manage the state
    const [modalOpen, handleModal] = useState(false)

    Pass handleModal to the Modal as well as some child element to include inside.
  */

  useEffect(() => {
    document.onkeydown = function (evt) {
      evt = evt || window.event
      if (evt.keyCode === 27) {
        // ESC key
        console.log('Key pressed')
        handleModal(null)
      }
    }

    return function cleanup() {
      document.onkeydown = null
    }
  }, [handleModal])

  return (
    <div className={`modal ${hidden ? '' : 'is-active'}`}>
      <div className="modal-background has-background-primary"></div>

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="modal-content"
        style={{ maxWidth: '90%', width: 1200 }}
      >
        {children}
      </motion.div>
      <button
        onClick={() => handleModal(null)}
        className="modal-close is-large is-success"
        aria-label="close"
      ></button>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  handleModal: PropTypes.func.isRequired,
}

export default Modal
