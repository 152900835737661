import React from 'react'
import { motion } from 'framer-motion'

const loadingContainerVariants = {
  start: {
    marginLeft: 0,
    marginRight: '90%',
    transition: {
      staggerChildren: 0.2,
      duration: 5,
      ease: 'easeInOut',
      yoyo: Infinity,
    },
  },
  end: {
    marginLeft: '90%',
    marginRight: 0,
    transition: {
      staggerChildren: 0.2,
      duration: 5,
      ease: 'easeInOut',
      yoyo: Infinity,
    },
  },
}

const loadingCircleVariants = {
  start: {
    y: '-50%',
  },
  end: {
    y: '50%',
  },
}

const loadingCircleTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: 'easeInOut',
}

const spinningBall = key => {
  return (
    <motion.div
      variants={loadingCircleVariants}
      transition={loadingCircleTransition}
      className="spinning-box"
      key={key}
    />
  )
}

const LoadingBox = () => {
  return (
    <motion.div
      className="data-loading-status"
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      {[...Array(3).keys()].map(key => spinningBall(key))}
    </motion.div>
  )
}

export default LoadingBox
