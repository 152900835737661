import React, { useState } from 'react'
import './SetSample.scss'
import api from '../../api'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import PropTypes from 'prop-types'

const SetSample = ({ handleModal, snackbarFunctions }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [notes, setNotes] = useState('')
  const [ipedsTextInput, setIpedsTextInput] = useState('')
  const [schoolList, setSchoolList] = useState([])
  const [schoolNames, setSchoolNames] = useState('')

  // Set sample, for saving. We need to normalizethe data in the following form
  // [ { filter: [ { category: { label: asd01, value: asd02 }, option: { label: asd11, value: asd12 } }, ... ] options: ... }, ... ]
  // We are interested in the filter array, which will be used to create a sample set.
  // In order to re-use existing functions, we will re-create the object structure.
  // Given that we care only about or statements, we will create the following array
  // [
  //    {
  //     filter:
  //           [
  //            { category: { value: 'unitid' }, option: { value: 111111 } },
  //            { category: { value: 'unitid' }, option: { value: 222222 } },
  //            ...
  //           ]
  //    }
  // ]
  const handleSave = async () => {
    // Set loading
    setIsLoading(true)

    // Save the sample
    try {
      const filterSets = [
        {
          filter: schoolList.map(s => ({
            category: { value: 'unitid' },
            option: { value: s.unitid },
          })),
        },
      ]

      await api.samples.newSample(title, filterSets, notes)

      snackbarFunctions.newSnackbar({ message: 'Saved Sample Set', type: 'success' })
    } catch (err) {
      snackbarFunctions.newSnackbar({ message: 'Error saving sample', type: 'danger' })
      console.log(err)
    }

    // Close the modal
    setIsLoading(false)
    handleModal(null)
  }

  const getSchoolNamesByIds = async () => {
    setIsLoading(true)
    const processedIds = ipedsTextInput.split('\n').map(id => id.trim().split(',').join(''))

    const schoolList = await api.demographics.schoolNamesForIds(processedIds)
    setSchoolList(schoolList)

    // Set school Names, for displaying only
    const schoolNames = schoolList.map(s => s.name).join('\n')
    setSchoolNames(schoolNames)
    setIsLoading(false)
  }

  const renderSchoolIdLookupContainer = () => {
    return (
      <div className="schoolLookupContainer">
        <label className="label">Enter IPEDS</label>
        <p className="text-description">
          Enter schools by <strong>IPEDS Unit Id</strong>. Separate ids by newline. Comma at end of
          each line is optional—will work with or without it. Don't forget to press{' '}
          <strong>Save Sample</strong> to save this sample set.
        </p>
        <div className="look-up-box">
          <textarea
            placeholder={'353700\n341800\n178500\n711300\n195300'}
            value={ipedsTextInput}
            onChange={ev => setIpedsTextInput(ev.target.value)}
          />
          <div className="butn-box">
            <AnimatedButton
              className={`button is-success ${isLoading && 'is-loading'}`}
              onClick={() => {
                getSchoolNamesByIds()
              }}
            >
              Add Schools To Sample
            </AnimatedButton>
          </div>
          <textarea
            readOnly={true}
            placeholder={
              'School Name 1\nSchool Name 2\nSchool Name 3\nSchool Name 4\nSchool Name 5'
            }
            value={schoolNames}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="box">
      <div className="field">
        <label className="label">Title</label>
        <div className="control">
          <input
            onChange={e => setTitle(e.target.value)}
            className="input"
            type="text"
            placeholder="Title of Dataset"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Notes</label>
        <div className="control">
          <textarea
            onChange={e => setNotes(e.target.value)}
            className="textarea"
            placeholder="This dataset is used for the FIRE project..."
          />
        </div>
      </div>
      {renderSchoolIdLookupContainer()}
      <AnimatedButton
        disabled={isLoading || !title || !schoolNames}
        onClick={() => handleSave()}
        className={`button is-success is-fullwidth ${isLoading && 'is-loading'}`}
      >
        Save Sample
      </AnimatedButton>
    </div>
  )
}

SetSample.propTypes = {
  handleModal: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.func.isRequired,
}

export default SetSample
