import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import './UploadFile.scss'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ValidInvalidCountsDisplay from './demographicsLinkingHelpers/ValidInvalidCountsDisplay'
import valueTransformerForDem from './demographicsLinkingHelpers/valueTransformerForDem'

const DemographicsLinkingSection = ({
  data,
  demographicsConfig,
  demographicColumns,
  setDemographicColumns,
  demographicColumnValueMaps,
  setDemographicColumnValueMaps,
}) => {
  /*
   * Update helper for demographicColumns.
   * demographicColumns link demographic categories to csv columns
   * Object of type { demographic category => CSV column index }
   * For every demographic in demographicColumnsConfig (year and gender right now)
   * store a state variable pointing to a column in the CSV that represents an
   * email's value for that demographic. These are optional. Initialize to null.
   * Example Use: If a user wants to link a column with header "EmailGender" and index 4 to be the column
   * representing an email's gender, they will use a dropdown below to set { gender: 4 }
   * in demographicColumns object. We then use that in the sendData function to format emails
   */
  const updateDemographicValue = (dem, value) => {
    const newDemColumnState = {
      ...demographicColumns,
      [dem]: value || 'Blank',
    }
    setDemographicColumns(newDemColumnState)
  }

  /*
   * Update helper for demographicColumnValueMaps
   * demographicColumnValueMaps create a mapping of CSV cell values to demographic options
   * Object of type { demographic category => csv cell value => internal demographic option }
   * Once we link a specific column in the CSV to a demographic using the demographicColumns
   * state variable above, we may also want to create a mapping for specific values in that
   * column to our own demographic options.
   * Example Use: Continuing example above, say we have linked "EmailGender" column
   * to gender demographic by setting `demographicColumns.gender = 4`, but the values in the
   * EmailGender column of the CSV are all "F", "M", and "N". Our backend won't understand
   * how to interpret those, since our demographics config uses "female", "male", and "nonbinary"
   * as the values for gender. That's where demographicColumnValueMaps comes in. We use
   * the dropdowns to link the CSV values to demographic values. demographicColumnValueMaps will
   * look like this: { gender: { "F": "female", "M": "male", "N": "nonbinary" }}
   */
  const updateDemographicMapValue = (category, csvValue, mapToValue) => {
    const newDemColumnState = {
      ...demographicColumnValueMaps,
      [category]: {
        ...(demographicColumnValueMaps[category] || {}),
        [csvValue]: mapToValue,
      },
    }

    setDemographicColumnValueMaps(newDemColumnState)
  }

  /*
   * uniqueColumnValues
   * For the dropdowns for mapping CSV cell values to demographics, we want to show
   * every unique value in the column (other than those that directly match a demographic)
   */
  const [uniqueColumnValues, setUniqueColumnValues] = useState({})
  useEffect(() => computeUniqueValuesInColumns({ data, setUniqueColumnValues }), [data])

  return (
    <React.Fragment>
      <label className="label">Demographics</label>
      {Object.keys(demographicColumns).map((demographic, i) => (
        <div className="field" key={i}>
          <div className="field">
            <label className="label">{demographic} (Optional)</label>
            <div className="control">
              <Select
                options={data[0].map((header, i) => ({ value: i, label: header }))}
                onChange={val => updateDemographicValue(demographic, val)}
                value={demographicColumns[demographic]}
              />
            </div>
            {!!demographicColumns[demographic] && (
              <ValidInvalidCountsDisplay
                demographicCategory={demographic}
                valueTransformer={valueTransformerForDem(demographicColumnValueMaps, demographic)}
                columnIndex={demographicColumns[demographic].value}
                data={data}
                demographicsConfig={demographicsConfig}
              />
            )}
          </div>
          {!!demographicColumns[demographic] && (
            <React.Fragment>
              <p>Custom Mappings For {demographic}</p>
              {Object.entries(demographicColumnValueMaps[demographic] || {}).map(
                ([csvValue, mapToValue], i) => (
                  <div className="field is-horizontal" key={i}>
                    <div className="field-body">
                      <div className="field">
                        <Select
                          options={uniqueColumnValues[demographicColumns[demographic]?.value]
                            ?.filter(value => {
                              if (!demographicsConfig || !demographicsConfig[demographic]) {
                                return false
                              }
                              // ensure that this value does not match a demographic exactly
                              const validDemOptions = demographicsConfig[demographic].options
                              return !validDemOptions.includes(value)
                            })
                            .map(value => ({
                              value,
                              label: value,
                            }))}
                          onChange={val => updateDemographicMapValue(demographic, val.value, null)}
                          value={{ value: csvValue, label: csvValue }}
                        />
                      </div>
                      <div className="field">
                        <Select
                          options={demographicsConfig[demographic]?.options?.map(value => ({
                            value,
                            label: value,
                          }))}
                          onChange={val =>
                            updateDemographicMapValue(demographic, csvValue, val.value)
                          }
                          value={{ value: mapToValue, label: mapToValue }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <Select
                      options={uniqueColumnValues[demographicColumns[demographic]?.value]
                        ?.filter(value => {
                          if (!demographicsConfig || !demographicsConfig[demographic]) {
                            return false
                          }
                          // ensure that this value does not match a demographic exactly
                          const validDemOptions = demographicsConfig[demographic].options
                          return !validDemOptions.includes(value)
                        })
                        .map(value => ({
                          value,
                          label: value,
                        }))}
                      onChange={val => updateDemographicMapValue(demographic, val.value, null)}
                      value={null}
                    />
                  </div>
                  <div className="field">
                    <Select isDisabled />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      ))}
    </React.Fragment>
  )
}

const computeUniqueValuesInColumns = ({ data, setUniqueColumnValues }) => {
  const emailIndex = data?.[0]?.findIndex(header => header?.toLowerCase() === 'email')
  const ipedsIndex = data?.[0]?.findIndex(
    header => header?.toLowerCase() === 'ipeds' || header?.toLowerCase() === 'ipedsid'
  )
  // compute the unique values in each column of the CVS (except for email column and ipeds)
  const nonEmailAndIpedsColumns = data?.[0]
    ?.map((rowHeader, i) => [rowHeader, i])
    .filter(([, i]) => i !== emailIndex && i !== ipedsIndex)
  const uniqueColumnValuesObj = nonEmailAndIpedsColumns.reduce(
    (acc, [, columnIndex]) => ({
      ...acc,
      [columnIndex]: _.uniq(_.compact(data.slice(1).map(row => _.trim(row[columnIndex])))),
    }),
    {}
  )
  setUniqueColumnValues(curr => ({
    ...curr,
    ...uniqueColumnValuesObj,
  }))
}

DemographicsLinkingSection.propTypes = {
  data: PropTypes.array.isRequired,
  demographicColumns: PropTypes.object,
  demographicsConfig: PropTypes.object,
  demographicColumnValueMaps: PropTypes.object.isRequired,
  setDemographicColumns: PropTypes.func.isRequired,
  setDemographicColumnValueMaps: PropTypes.func.isRequired,
}

export default DemographicsLinkingSection
