import React, { useEffect, useState } from 'react'
import Promise from 'bluebird'
import _ from 'lodash'
import Select from 'react-select'
import api from '../../api'
import '../../styles/Outreach.scss'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import {
  ReusableTextInput,
  ReusableDropDownInput,
} from '../ReusableComponents/ReusableInputComponents'

const EMAIL_TEMPLATES = [
  { name: 'Ambassador Reminder - New', value: 'ambassadorReminder-new' },
  { name: 'Ambassador Reminder - Participants', value: 'ambassadorReminder-participants' },
]

const SEND_CHANNELS = [
  { name: 'Email', value: 'email' },
  { name: 'SMS', value: 'sms' },
]

const AmbassadorReminder = () => {
  const [error, setError] = useState(null)
  const [selectedInstitutions, setSelectedInstitutions] = useState([])
  const [activeInstitutions, setActiveInstitutions] = useState([
    {
      value: 'all',
      label: 'Target All',
    },
  ])

  const [inputs, setInputs] = useState({
    templateName: EMAIL_TEMPLATES[0].value, // default template Id for snowball reminer
    subject: '', // only on email
    campaignName: '', // optional
    surveyId: '',
    maxEmails: '',
    maxSms: '',
    message: null,
    surveyTopic: '',
    sendType: 'ambassadorReminder',
    sendChannel: 'email',
  })

  const [userCount, setUserCount] = useState(null)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    if (inputs?.surveyId && inputs?.sendType) {
      loadActiveInstitutions({
        surveyId: inputs.surveyId,
        sendType: inputs.sendType,
        setActiveInstitutions,
        setError,
      })
    }
  }, [inputs])

  const handleInput = (input, value) => {
    setInputs({ ...inputs, [input]: value })
  }

  const handleTemplateChange = e => {
    handleInput('templateName', e.target.value)
  }

  const handleSendChannelChange = e => {
    handleInput('sendChannel', e.target.value)
  }

  const handleOutreachSend = async () => {
    setSending(true)
    console.log('SENDING WITH INPUTS BABY: ', inputs)
    await api.outreach.send({
      ...inputs,
      selectedInstitutionsList: selectedInstitutions?.map(i => i.value) || null,
    })

    setTimeout(() => {
      setSending(false)
    }, 5000)
  }

  const handleGetUsers = async () => {
    const res = await api.outreach.getTargets({
      surveyId: inputs.surveyId,
      sendType: inputs.sendType,
      templateName: inputs.templateName,
      selectedInstitutionsList: selectedInstitutions?.map(i => i.value) || null,
      sendChannel: inputs.sendChannel,
    })
    setUserCount(res)
  }

  return (
    <React.Fragment>
      <div className="outreach-page box has-background-white">
        <h1>Ambassador Reminder</h1>
        <ReusableDropDownInput
          label={'Send Channel'}
          handleChange={handleSendChannelChange}
          selectionList={SEND_CHANNELS}
        />
        {inputs.sendChannel === 'email'
          ? emailForm({
            handleTemplateChange,
            handleInput,
            activeInstitutions,
            userCount,
            handleGetUsers,
            inputs,
            handleOutreachSend,
            sending,
            error,
            selectedInstitutions,
            setSelectedInstitutions,
          })
          : smsForm({
            handleTemplateChange,
            handleInput,
            activeInstitutions,
            userCount,
            handleGetUsers,
            inputs,
            handleOutreachSend,
            sending,
            error,
            setSelectedInstitutions,
          })}
      </div>
    </React.Fragment>
  )
}

const emailForm = ({
  handleTemplateChange,
  handleInput,
  activeInstitutions,
  userCount,
  handleGetUsers,
  inputs,
  handleOutreachSend,
  sending,
  error,
  setSelectedInstitutions,
}) => {
  return (
    <React.Fragment>
      <ReusableDropDownInput
        label={'Email Template'}
        handleChange={handleTemplateChange}
        selectionList={EMAIL_TEMPLATES}
      />
      <ReusableTextInput
        label={'SurveyId'}
        placeholder={'Enter surveyId'}
        inputKey={'surveyId'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <p>Targeted Institutions</p>
      <Select
        options={activeInstitutions}
        isMulti
        isSearchable={true}
        onChange={options => {
          setSelectedInstitutions(options)
        }}
        placeholder="Select Institutions"
        defaultValue={[activeInstitutions[0]]}
        // styles={selectSchoolsStyles}
        noOptionsMessage={() =>
          'Invalid/Missing Survey Id or no valid schools found for this survey'
        }
      />
      <ReusableTextInput
        label={'Subject'}
        placeholder={'Enter subject line'}
        inputKey={'subject'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <ReusableTextInput
        label={'Survey Topic'}
        placeholder={'Enter survey topic'}
        inputKey={'surveyTopic'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <ReusableTextInput
        label={'Max Emails'}
        placeholder={'Max emails to send'}
        inputKey={'maxEmails'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      {typeof userCount === 'number' ? (
        <div className="center-button">
          <p>{'There are ' + userCount + ' users eligible for this outreach'}</p>
        </div>
      ) : (
        ''
      )}
      <div className="center-button" style={{ marginBottom: '1rem' }}>
        <AnimatedButton
          onClick={handleGetUsers}
          disabled={!inputs.surveyId || !inputs.templateName}
          className={`level-item button is-success is-set-height ${false && 'is-loading'}`}
          style={{ width: '300px' }}
        >
          Get User Count
        </AnimatedButton>
      </div>
      <div className="center-button">
        <AnimatedButton
          onClick={handleOutreachSend}
          disabled={
            !inputs.subject ||
            !inputs.surveyId ||
            !inputs.templateName ||
            !inputs.surveyTopic ||
            !inputs.maxEmails ||
            sending
          }
          className={`level-item button is-success is-set-height ${false && 'is-loading'}`}
          style={{ width: '300px' }}
        >
          {sending ? 'Yes Mary, It\'s Sending...' : 'Send Outreach'}
        </AnimatedButton>
      </div>
      {error ? <p style={{ color: 'red' }}>There was an ERROR: {error?.message}</p> : null}
    </React.Fragment>
  )
}

const smsForm = ({
  handleTemplateChange,
  handleInput,
  activeInstitutions,
  userCount,
  handleGetUsers,
  inputs,
  handleOutreachSend,
  sending,
  error,
  setSelectedInstitutions,
}) => {
  return (
    <React.Fragment>
      <ReusableDropDownInput
        label={'Email Template'}
        handleChange={handleTemplateChange}
        selectionList={EMAIL_TEMPLATES}
      />
      <ReusableTextInput
        label={'SurveyId'}
        placeholder={'Enter surveyId'}
        inputKey={'surveyId'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <p>Targeted Institutions</p>
      <Select
        options={activeInstitutions}
        isMulti
        isSearchable={true}
        onChange={options => {
          setSelectedInstitutions(options)
        }}
        placeholder="Select Institutions"
        defaultValue={[activeInstitutions[0]]}
        // styles={selectSchoolsStyles}
        noOptionsMessage={() =>
          'Invalid/Missing Survey Id or no valid schools found for this survey'
        }
      />
      <ReusableTextInput
        label={'Survey Topic'}
        placeholder={'Enter survey topic'}
        inputKey={'surveyTopic'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      <ReusableTextInput
        label={'Max Sms'}
        placeholder={'Max sms to send'}
        inputKey={'maxSms'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />
      {typeof userCount === 'number' ? (
        <div className="center-button">
          <p>{'There are ' + userCount + ' users eligible for this outreach'}</p>
        </div>
      ) : (
        ''
      )}
      <div className="center-button" style={{ marginBottom: '1rem' }}>
        <AnimatedButton
          onClick={handleGetUsers}
          disabled={!inputs.surveyId || !inputs.templateName}
          className={`level-item button is-success is-set-height ${false && 'is-loading'}`}
          style={{ width: '300px' }}
        >
          Get User Count
        </AnimatedButton>
      </div>
      <div className="center-button">
        <AnimatedButton
          onClick={handleOutreachSend}
          disabled={
            !inputs.surveyId ||
            !inputs.templateName ||
            !inputs.surveyTopic ||
            !inputs.maxSms ||
            sending
          }
          className={`level-item button is-success is-set-height ${false && 'is-loading'}`}
          style={{ width: '300px' }}
        >
          {sending ? 'Yes Mary, It\'s Sending...' : 'Send Outreach'}
        </AnimatedButton>
      </div>
      {error ? <p style={{ color: 'red' }}>There was an ERROR: {error?.message}</p> : null}
    </React.Fragment>
  )
}

const loadActiveInstitutions = async ({ surveyId, sendType, setActiveInstitutions, setError }) => {
  try {
    const activeInstitutions = await api.outreach.getSchoolsNeedingOutreach({
      surveyId,
      sendType,
    })

    const activeInstitutionsIds = activeInstitutions.map(institution => institution.institution)

    let institutionChunks = _.chunk(activeInstitutionsIds, 10)
    let institutionsWithNames = await Promise.reduce(
      institutionChunks,
      async (acc, chunk) => {
        let names = await api.demographics.schoolNamesForIds(chunk)
        return acc.concat(names)
      },
      []
    )

    institutionsWithNames = institutionsWithNames.map(institution => ({
      value: institution._id,
      label: institution.name,
    }))

    institutionsWithNames = [
      ...[
        {
          value: 'all',
          label: 'Target All',
        },
      ],
      ...institutionsWithNames,
    ]

    setActiveInstitutions(institutionsWithNames)
  } catch (err) {
    handleErrors(err, setError)
  }
}

const handleErrors = (err, setError) => {
  console.log('err: ', err)
  console.log('err.response: ', err.response.body.name)
  switch (err.response.body.name) {
  case 'InvalidSurveyIdError':
    console.log('I Got here, so now i need to set the error')
    setError({ message: 'Invalid survey Id' })
    break
  default:
    setError({ message: 'Something went wrong!' })
    break
  }
}

export default AmbassadorReminder
