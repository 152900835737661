import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import api from '../../api'
import './ViewSms.scss'
import PropTypes from 'prop-types'
import {
  StatusCardHeader,
  StatusCardBody,
  StatusCardFooter,
  StatusCardError,
} from '../ReusableComponents/Card/StatusCard'
import LoadingBox from '../LoadingBox/LoadingBox'

const ViewSms = ({ applyFilter, snackbarFunctions, handleModal }) => {
  const [smsCampaigns, setSmsCampaigns] = useState(null)

  // Fetch all smsCampaigns
  useEffect(() => {
    const getSmsCampaigns = async () => {
      try {
        let res = await api.sms.getSms()
        if (!res.body) {
          throw new Error('No SMS Campaingns fond!')
        }

        setSmsCampaigns(res.body.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)))
      } catch (err) {
        console.log(err)
        snackbarFunctions.newSnackbar({
          message: err.message || 'Error getting sms',
          type: 'danger',
        })
        handleModal(null)
      }
    }
    getSmsCampaigns()
  }, [])

  const renderCard = email => {
    const cardData = [
      [
        { title: 'Survey Title:', value: `${email?.surveyTitle || 'N/A'}` },
        {
          title: 'Total Completes:',
          value: `${email?.smsCompleteCount || 'N/A'}`,
        },
        {
          title: 'Rejected Responses:',
          value: `${email?.smsRejectCount || 'N/A'}`,
        },
        {
          title: 'Accepted Responses:',
          value: `${email?.smsConversionCount || 'N/A'}`,
        },
      ],

      [
        { title: 'Survey Id:', value: `${email?.surveyId || 'N/A'}` },

        {
          title: 'Complete Rate:',
          value: `${email?.smsCompleteRate || 'N/A'} %`,
        },
        {
          title: 'Rejected Rate:',
          value: `${email?.smsRejectRate || 'N/A'} %`,
        },
        {
          title: 'Accept Rate:',
          value: `${email?.smsConversionRate || 'N/A'} %`,
        },
      ],
      [
        {
          title: 'Cost Of Campaign :',
          value: `$ ${email?.smsCampaignCost || 'N/A'}`,
        },
        {
          title: 'Cost Per Complete :',
          value: `$ ${email?.smsCostPerComplete || 'N/A'}`,
        },
        {
          title: '',
          value: '',
        },
        {
          title: 'Cost Per Accept:',
          value: `$ ${email?.smsCostPerAcceptedComplete || 'N/A'}`,
        },
      ],
    ]
    return (
      <div className="box" key={email.updatedAt}>
        <StatusCardHeader
          title={email.campaignName}
          status={email?.smsSentCount ? `${email.smsSentCount} SMS Sent` : 'SMS Failed'}
          statusColor={`${email?.smsSentCount ? 'success' : 'danger'}`}
        />
        <StatusCardBody cardData={cardData} />
        <StatusCardFooter
          leftLabel={'Created on:'}
          leftValue={email.createdAt}
          rightValue={email.updatedAt}
          rightLabel={'Updated on:'}
        />
        <StatusCardError email={email} />

        {/*
    // TODO(Zaprin): Currently we do not have the ability to grab the filters from a sms-campaign model. 
    //  I need to add the filters to the sms-campaign model and eventually add this feature like for email.
    <AnimatedButton
      // onClick={() => applyFilter([{ filter: email.filters, options: email.filterAndOr }])}
      className="is-primary"
    >
      View Sample
    </AnimatedButton> */}
      </div>
    )
  }

  console.log(smsCampaigns)
  return !smsCampaigns ? (
    <div className="box view-sms-container has-backround-lighter-gray">
      {!smsCampaigns && <LoadingBox />}
      {smsCampaigns && smsCampaigns.map(email => renderCard(email))}
    </div>
  ) : (
    <motion.div
      className="box view-sms-container has-backround-lighter-gray"
      transition={{ duration: 0.5, ease: 'easeIn' }}
      animate={{ height: 'auto' }}
    >
      {smsCampaigns && smsCampaigns.map(email => renderCard(email))}
    </motion.div>
  )
}

ViewSms.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
}

export default ViewSms
