import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import './SendEmail.scss'

import { SchedulingCalendarsListBox } from '../ReusableComponents/SchedulingCalendar'
import { campaignNames } from '../../api/email'
import DataCard from '../DataCard/DataCard'
import SendEmailForm from './SendEmailForm'

const SendEmail = ({
  data,
  sendEmail,
  sendSelf,
  scheduleEmails,
  snackbarFunctions,
  saveSample,
  visible,
  generateEstimate,
}) => {
  const [sendLoading, setSendLoading] = useState(false)
  const [hasMessage, setHasMessage] = useState(false)
  const [saveSampleBox, setSaveSampleBox] = useState(false)
  const [onlyUsers, setOnlyUsers] = useState(false)
  const [onlyEdVisors, setOnlyEdVisors] = useState(false)
  const [onlyPersonal, setOnlyPersonal] = useState(false)
  const [allowAlumni, setAllowAlumni] = useState(false)
  const [startScheduling, setStartScheduling] = useState(false)
  const [estimateLoading, setEstimateLoading] = useState(false)
  const [estimateInformation, setEstimateInformation] = useState(null)
  const [useShortLink, setUseShortLink] = useState(false)
  const [inputs, setInputs] = useState({
    templateId: '27488124', // Postmark default
    provider: 'postmark',
    sender: 'mary@students.collegepulse.com',
    subject: '',
    campaignName: '',
    surveyId: '',
    maxEmails: null,
    maxEmailsPerSchool: null,
    message: null,
    surveyTopic: '',
    studentsLeft: 2000,
    list: '',
    growthChannelCustom: null,
  })
  const [prevCampaigns, setPrevCampaigns] = useState([])
  const [noOverlapCampaigns, setNoOverlapCampaigns] = useState([])
  const [schedules, setSchedules] = useState([moment(), moment()])

  useEffect(() => {
    setSendLoading(false)

    const setCampaigns = async () => {
      try {
        const campaigns = await campaignNames()
        setPrevCampaigns(campaigns.map(c => ({ label: c, value: c })))
      } catch (e) {
        setPrevCampaigns([])
      }
    }
    setCampaigns()
  }, [visible])

  const handleInput = (input, value) => {
    setInputs({ ...inputs, [input]: value })
  }

  const handleProviderChange = e => {
    if (e.target.value === 'postmark') {
      setInputs({ ...inputs, templateId: '27488124', provider: 'postmark' })
    } else if (e.target.value === 'mailjet') {
      setInputs({ ...inputs, templateId: '1474521', provider: 'mailjet' })
    } else if (e.target.value === 'sendgrid') {
      setInputs({
        ...inputs,
        templateId: 'd-97f7fb7efaad4c5f9e1a42ff02f16b18',
        provider: 'sendgrid',
      })
    } else if (e.target.value === 'elasticemail') {
      setInputs({
        ...inputs,
        templateId: 'direct-incentive',
        provider: 'elasticemail',
      })
    }
  }

  const handleTemplateChange = e => {
    if (e.target[e.target.selectedIndex].getAttribute('data-message') && !hasMessage) {
      setHasMessage(true)
    } else {
      setHasMessage(false)
    }

    handleInput('templateId', e.target.value)
  }

  const handleSenderChange = e => {
    handleInput('sender', e.target.value)
  }

  const handleEstimate = async () => {
    setEstimateInformation(null)
    setEstimateLoading(true)
    let counts = await generateEstimate({
      campaignName: inputs.campaignName,
      templateId: inputs.templateId,
      messageLimit: inputs.messageLimit,
      subject: inputs.subject,
      selectedSchoolId: data.data.institution,
      message: inputs.message,
      surveyId: inputs.surveyId,
      maxEmails: inputs.maxEmails,
      maxEmailsPerSchool: inputs.maxEmailsPerSchool,
      onlyUsers,
      onlyEdVisors,
      allowAlumni,
      list: inputs.list,
      globalVariables: {
        studentsLeft: inputs.studentsLeft,
        surveyTopic: inputs.surveyTopic,
      },
      deduplicateAgainst: null,
      provider: inputs.provider,
      onlyPersonal,
    })
    if (counts === null || counts.error) {
      setEstimateLoading(false)
      setEstimateInformation(null)
    }
    setEstimateInformation(counts)
    setEstimateLoading(false)
  }

  const handleSubmit = async () => {
    if (saveSampleBox) {
      try {
        await saveSample(inputs.campaignName, inputs.subject, data.data.institution)

        snackbarFunctions.newSnackbar({ message: 'Saved as a sample set!', type: 'success' })
      } catch (err) {
        snackbarFunctions.newSnackbar({ message: err || 'Failed to save!', type: 'danger' })
        console.log(err)
      }
    }

    sendEmail({
      campaignName: inputs.campaignName,
      templateId: inputs.templateId,
      messageLimit: inputs.messageLimit,
      subject: inputs.subject,
      selectedSchoolId: data.data.institution,
      message: inputs.message,
      surveyId: inputs.surveyId,
      maxEmails: inputs.maxEmails,
      maxEmailsPerSchool: inputs.maxEmailsPerSchool,
      onlyUsers,
      onlyEdVisors,
      allowAlumni,
      list: inputs.list,
      globalVariables: {
        studentsLeft: inputs.studentsLeft,
        surveyTopic: inputs.surveyTopic,
      },
      deduplicateAgainst: noOverlapCampaigns.map(campaign => campaign.value),
      provider: inputs.provider,
      onlyPersonal,
      sender: inputs.sender,
      growthChannelCustom: inputs.growthChannelCustom,
      useShortLink,
    })

    setSendLoading(true)
  }

  const handleSubmitSchedules = async () => {
    if (saveSampleBox) {
      try {
        await saveSample(inputs.campaignName, inputs.subject, data.data.institution)

        snackbarFunctions.newSnackbar({ message: 'Saved as a sample set!', type: 'success' })
      } catch (err) {
        snackbarFunctions.newSnackbar({ message: err || 'Failed to save!', type: 'danger' })
        console.log(err)
      }
    }

    scheduleEmails({
      schedules,
      campaignName: inputs.campaignName,
      templateId: inputs.templateId,
      messageLimit: inputs.messageLimit,
      subject: inputs.subject,
      selectedSchoolId: data.data.institution,
      message: inputs.message,
      surveyId: inputs.surveyId,
      maxEmails: inputs.maxEmails,
      maxEmailsPerSchool: inputs.maxEmailsPerSchool,
      onlyUsers,
      onlyEdVisors,
      allowAlumni,
      list: inputs.list,
      globalVariables: {
        studentsLeft: inputs.studentsLeft,
        surveyTopic: inputs.surveyTopic,
      },
      deduplicateAgainst: noOverlapCampaigns.map(campaign => campaign.value),
      provider: inputs.provider,
      onlyPersonal,
      sender: inputs.sender,
      growthChannelCustom: inputs.growthChannelCustom,
      useShortLink,
    })

    setSendLoading(true)
  }

  const handleSubmitSelf = async () => {
    sendSelf({
      campaignName: inputs.campaignName,
      templateId: inputs.templateId,
      messageLimit: inputs.messageLimit,
      subject: inputs.subject,
      message: inputs.message,
      surveyId: inputs.surveyId,
      provider: inputs.provider,
      globalVariables: {
        studentsLeft: inputs.studentsLeft,
        surveyTopic: inputs.surveyTopic,
      },
      sender: inputs.sender,
      growthChannelCustom: inputs.growthChannelCustom,
      useShortLink,
    })
  }

  return (
    <React.Fragment>
      {data && <DataCard preview={data.preview} title={data.title} data={data.data} />}
      <div
        className="box has-background-white send-email-container"
        style={visible ? {} : { display: 'none' }}
      >
        <SendEmailForm
          handleTemplateChange={handleTemplateChange}
          handleProviderChange={handleProviderChange}
          handleInput={handleInput}
          handleEstimate={handleEstimate}
          hasMessage={hasMessage}
          estimateInformation={estimateInformation}
          estimateLoading={estimateLoading}
          noOverlapCampaigns={noOverlapCampaigns}
          setNoOverlapCampaigns={setNoOverlapCampaigns}
          prevCampaigns={prevCampaigns}
          handleSubmit={handleSubmit}
          sendLoading={sendLoading}
          handleSubmitSelf={handleSubmitSelf}
          setStartScheduling={setStartScheduling}
          startScheduling={startScheduling}
          inputs={inputs}
          setOnlyUsers={setOnlyUsers}
          onlyUsers={onlyUsers}
          setAllowAlumni={setAllowAlumni}
          allowAlumni={allowAlumni}
          setOnlyEdVisors={setOnlyEdVisors}
          onlyEdVisors={onlyEdVisors}
          setSaveSampleBox={setSaveSampleBox}
          saveSampleBox={saveSampleBox}
          onlyPersonal={onlyPersonal}
          setOnlyPersonal={setOnlyPersonal}
          handleSenderChange={handleSenderChange}
          setUseShortLink={setUseShortLink}
          useShortLink={useShortLink}
        />
        <SchedulingCalendarsListBox
          startScheduling={startScheduling}
          schedules={schedules}
          setSchedules={setSchedules}
          handleSubmitSchedules={handleSubmitSchedules}
          sendLoading={sendLoading}
          inputs={inputs}
        />
      </div>
    </React.Fragment>
  )
}

SendEmail.propTypes = {
  data: PropTypes.object,
  sendEmail: PropTypes.func.isRequired,
  sendSelf: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
  saveSample: PropTypes.func.isRequired,
}

export default SendEmail
