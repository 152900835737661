import React, { useState } from 'react'
import './NewBigbox.scss'
import 'react-datetime/css/react-datetime.css'
import DateTime from 'react-datetime'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import api from '../../api'

const bigBoxOptions = [
  {
    type: 'string',
    property: 'name',
    label: 'Name',
    placeholder: 'Acta Survey 1',
    help: 'The name of the bigbox campaign. This will be used to handle the campaignNames and identify this bigbox.',
  },
  {
    type: 'string',
    property: 'survey',
    label: 'Survey ID',
    placeholder: '123abc123abc123abc',
    help: 'The survey ID we are targeting.',
  },
  {
    type: 'date',
    property: 'startDate',
    label: 'Start Date',
    help: 'The first date BigBox will launch email sends and begin warming up.',
  },
  {
    type: 'date',
    property: 'endDate',
    label: 'End Date',
    help: 'The ending date for BigBox to stop sending emails. BigBox will begin slowing down email sends before this date.',
  },
  {
    type: 'select',
    property: 'scalingAlgorithm',
    label: 'Scaling Algorithm',
    options: [
      // Linear
      'linear-slow', // 75(day)2 : Linear - Slow : 50k a day per email server after 33 days
      'linear-medium', // 150(day)2 : Linear - Medium : 50k a day per email server after 17 days
      'linear-fast', // 250(day)2 : Linear - Fast : 50k a day per email server after 10 days

      // Exponential
      'exponential-slow', // 1.25^n + 100 : (38 day ramp) : 50k a day per email server after 38 days
      'exponential-medium', // 1.45^n + 100 : (23 day ramp) : 50k a day per email server after 23 days
      'exponential-fast', // 1.74^n + 1000 : (15 day ramp) : 50k a day per email server after 15 days
    ],
    help: 'The scaling algorithm to use for this campaign. This will determine how many emails are sent per day. Linear scaling is recommended.',
  },
  {
    type: 'number',
    property: 'maximumDailyCount',
    label: 'Maximum Daily Count',
    placeholder: 50000,
    help: 'The maximum number of emails to send per day. This is the maximum number of emails that will be sent per day, regardless of the scaling algorithm. This is useful if you want to limit the number of emails sent per day.',
  },
  {
    type: 'multi-string',
    property: 'campaignConfiguration.subjects',
    label: 'Subjects',
    help: 'The subject lines to use for this campaign. These will be randomly selected for each email sent.',
  },
  {
    type: 'multi-string',
    property: 'campaignConfiguration.bodies',
    label: 'Topics',
    help: 'The topics to use for this campaign. These will be randomly selected for each email sent.',
  },
]

const NewBigbox = ({ handleModal }) => {
  const handleNewBigbox = async () => {
    await api.bigbox.createBigBox(formatState())
    handleModal(null)
  }

  const [bigBoxSettings, setBigBoxSettings] = useState({
    name: '',
    survey: '',
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    scalingAlgorithm: 'linear-slow',
    'campaignConfiguration.subjects': ['Come take this survey!', 'We have questions for you!'],
    'campaignConfiguration.bodies': ['the political climate', 'your college experience'],
    maximumDailyCount: 50000,
  })

  const formatState = () => {
    let bbs = { ...bigBoxSettings }
    let bodies = bbs['campaignConfiguration.bodies']
    bbs.startDate = new Date(bbs.startDate)
    bbs.endDate = new Date(bbs.endDate)
    bbs.maximumDailyCount = parseInt(bbs.maximumDailyCount)
    let subjects = bbs['campaignConfiguration.subjects']
    delete bbs['campaignConfiguration.bodies']
    delete bbs['campaignConfiguration.subjects']
    return {
      ...bbs,
      campaignConfiguration: {
        subjects,
        bodies,
      },
    }
  }

  const handleChange = (option, value, date) => {
    console.log(formatState())

    if (date) {
      setBigBoxSettings(prev => ({
        ...prev,
        [option.property]: date.toDate(),
      }))
    } else {
      setBigBoxSettings(prev => ({
        ...prev,
        [option.property]: value,
      }))
    }
  }

  return (
    <div className="bigbox">
      <div className="columns is-multiline">
        <div className="column is-12">
          <h1 className="title is-4 has-text-centered">Create New BigBox</h1>
        </div>
        <div className="column is-12">
          {bigBoxOptions.map(
            (option, index) =>
              (option.type === 'string' && (
                <div className="field" key={option.property}>
                  <label className="label">{option.label}</label>
                  <label className="light">
                    <small>{option.help}</small>
                  </label>
                  <div className="control">
                    <input
                      onChange={e => {
                        e.preventDefault()
                        handleChange(option, e.target.value)
                      }}
                      value={bigBoxSettings[option.property]}
                      className="input"
                      type="text"
                      placeholder={option.placeholder}
                    />
                  </div>
                </div>
              )) ||
              (option.type === 'date' && (
                <div className="field" key={option.property}>
                  <label className="label">{option.label}</label>
                  <label className="light">
                    <small>{option.help}</small>
                  </label>
                  <div className="control">
                    <DateTime
                      onChange={e => {
                        handleChange(option, null, e)
                      }}
                      value={bigBoxSettings[option.property]}
                      closeOnSelect={true}
                      timeFormat={false}
                      className="input dateinput"
                      //type="text"
                      //placeholder={option.placeholder}
                    />
                  </div>
                </div>
              )) ||
              (option.type === 'select' && (
                <div className="field" key={option.property}>
                  <label className="label">{option.label}</label>
                  <label className="light">
                    <small>{option.help}</small>
                  </label>
                  <div className="control">
                    <div className="select">
                      <select
                        onChange={e => {
                          e.preventDefault()
                          handleChange(option, e.target.value)
                        }}
                        value={bigBoxSettings[option.property]}
                      >
                        {option.options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )) ||
              (option.type === 'number' && (
                <div className="field" key={option.property}>
                  <label className="label">{option.label}</label>
                  <label className="light">
                    <small>{option.help}</small>
                  </label>
                  <div className="control">
                    <input
                      onChange={e => {
                        e.preventDefault()
                        handleChange(option, e.target.value)
                      }}
                      value={bigBoxSettings[option.property]}
                      className="input"
                      type="number"
                      placeholder={option.placeholder}
                    />
                  </div>
                </div>
              )) ||
              (option.type === 'multi-string' && (
                <div className="field" key={option.property}>
                  <label className="label level-add">
                    {option.label}{' '}
                    <button
                      className="add"
                      onClick={() => {
                        setBigBoxSettings(prev => ({
                          ...prev,
                          [option.property]: [...prev[option.property], ''],
                        }))
                      }}
                    >
                      +1
                    </button>
                  </label>
                  <label className="light">
                    <small>{option.help}</small>
                  </label>
                  <div className="control">
                    {bigBoxSettings[option.property].map((value, index) => (
                      <div className="multi-string" key={index}>
                        <input
                          onChange={e => {
                            e.preventDefault()
                            const newValues = [...bigBoxSettings[option.property]]
                            newValues[index] = e.target.value
                            handleChange(option, newValues)
                          }}
                          value={bigBoxSettings[option.property][index]}
                          className="input"
                          type="text"
                          style={{ marginBottom: '0.5rem' }}
                          placeholder={option.placeholder}
                        />
                        <button
                          className="remove"
                          onClick={() => {
                            const newValues = [...bigBoxSettings[option.property]]
                            newValues.splice(index, 1)
                            handleChange(option, newValues)
                          }}
                        >
                          -
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))
          )}
        </div>
        <div className="column is-12">
          <AnimatedButton
            className={`button is-success is-fullwidth`}
            onClick={() => {
              handleNewBigbox()
            }}
          >
            Create BigBox
          </AnimatedButton>
        </div>
      </div>
    </div>
  )
}

export default NewBigbox
