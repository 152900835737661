import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const ValidInvalidCountsDisplay = ({
  demographicCategory,
  valueTransformer,
  columnIndex,
  data,
  demographicsConfig,
}) => {
  const [demographicValidCount, setDemographicsValidCounts] = useState(0)
  useEffect(() => {
    if (!demographicsConfig || !data) {
      return
    }
    const updateValidVsInvalidDemographicCounts = () => {
      const validDems = new Set(demographicsConfig[demographicCategory].options)
      const valid = data.slice(1).filter(row => {
        return validDems.has(valueTransformer(row[columnIndex]))
      })

      setDemographicsValidCounts(valid.length)
    }

    updateValidVsInvalidDemographicCounts()
  }, [data, demographicsConfig, columnIndex, valueTransformer, demographicCategory])

  return (
    <p className="help">
      <strong className="has-text-success">{demographicValidCount}</strong> Rows have valid values.{' '}
      <strong className="has-text-danger">{data.length - 1 - demographicValidCount}</strong> don't
      match our demographic options. Please map those manually below.
    </p>
  )
}

ValidInvalidCountsDisplay.propTypes = {
  data: PropTypes.array.isRequired,
  demographicsConfig: PropTypes.object.isRequired,
  demographicCategory: PropTypes.string.isRequired,
  valueTransformer: PropTypes.func.isRequired,
  columnIndex: PropTypes.number.isRequired,
}

export default ValidInvalidCountsDisplay
