import request from './request'
import storage from '../lib/storage'
import { isSignedIn, saveNewToken } from './signin'
import prefix from './prefix'

export const setPasswordAndValidate = function (password, userId, securityKey) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/signup/link-validation')
      .send({ password: password, id: userId, securityKey: securityKey, web: true })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        storage.set('user', res.body.user)
        storage.set('token', res.body.token, true)

        isSignedIn()
        return resolve(res)
      })
  })
}

export const signup = function (email, password, code) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/signup')
      .send({ email, password, code, web: true })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

export const initiateVerification = function (
  email,
  userIdentifier,
  surveyDetails,
  verificationKey,
  domainByPassKey
) {
  console.log(domainByPassKey)
  surveyDetails = surveyDetails || {}
  const { surveyTitle, surveyPointValue, surveyId } = surveyDetails
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/signup/initiate-verification')
      .send({
        email,
        userIdentifier,
        surveyTitle,
        surveyPointValue,
        surveyId,
        verificationKey,
        domainByPassKey,
      })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

export const completeVerification = function (userId, password, code, verificationKey) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/signup/complete-verification')
      .send({ userId, password, code, verificationKey })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        storage.set('user', res.body.user)
        // storage.set('token', res.body.token, true)
        saveNewToken(res.body.token)

        isSignedIn()
        return resolve(res)
      })
  })
}

export const requestNewCode = function (email, password) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/signup/resend')
      .send({ email: email, password: password })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve()
      })
  })
}

export const validate = function (email, code) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/signup/validate')
      .send({ email: email, code: code })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        isSignedIn()
        return resolve(res.user)
      })
  })
}

export const magicSignup = function (email, code) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/email-code-auth')
      .send({ email, code })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        storage.set('user', res.body.user)
        // storage.set('token', res.body.token, true)
        saveNewToken(res.body.token)

        isSignedIn()
        return resolve(res)
      })
  })
}
