import React, { useState, useCallback, useEffect } from 'react'
import Modal from './Modal/Modal'
import SnackBar from './SnackBar/SnackBar'
import api from '../api'
import useMessage from '../context/MessageContext'
import BigBoxLayout from '../layout/BigBoxLayout'
import NewBigbox from './BigBox/NewBigbox'
import BigBoxCard from './BigBox/BigBoxCard'
import BigBoxConfig from './BigBox/BigBoxConfig'

const activeBigBox = [
  {
    name: 'Fire Bigbox',
    startDate: new Date('2022-11-01'),
    endDate: new Date('2022-12-15'),
    _id: '123abc123abc123abc123abc',
    status: 'warming-up',
    dayN: 10,
    scalingAlgorithm: 'linear-slow',
    currentCampaignName: 'BigBox-fireBigBox-2022-11-01',
    maximumDailyCount: 10000,
    survey: 'amnsbdjkahsdkjhakjshd',
    campaignConfiguration: {
      subjects: ['Come take this survey', 'How can this happen?'],
      bodies: ['Campus Climate', 'Health on Campus'],
    },
    dailyStats: [
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
    ],
    allCampaignNames: ['A', 'B', 'C'],
  },
  {
    name: 'WSJ Bigbox',
    startDate: new Date('2022-11-01'),
    endDate: new Date('2023-01-15'),
    _id: '321abc321abc321abc321abc',
    status: 'warm',
    dayN: 15,
    scalingAlgorithm: 'linear-slow',
    currentCampaignName: 'BigBox-wsjBigbox-2022-11-01',
    maximumDailyCount: 10000,
    survey: 'amnsbdjkahsdkjhakjshd',
    campaignConfiguration: {
      subjects: ['Come take this survey', 'How can this happen?'],
      bodies: ['Campus Climate', 'Health on Campus'],
    },
    dailyStats: [
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
      {
        actualSends: 1999,
      },
    ],
    allCampaignNames: ['A', 'B', 'C'],
  },
]

const BigBox = () => {
  const { setLoadingMessage } = useMessage()
  const [loadedSample, setLoadedSample] = useState()
  const [stateData, setStateData] = useState()

  const [bigBoxData, setBigBoxData] = useState([])

  /*
  Modal Logic
  Other components in the app use handleModal and pass it a string to enable specific modals.
  IE: onClick={() => handleModal('save-sample')}
  The string passed determines which modal child content is loaded into the Modal.
  */
  const [modalState, setModalState] = useState({ open: false, modal: null })
  const handleModal = useCallback((modal, passedData) => {
    setModalState(modalState =>
      (modalState.modal && modal === modalState.modal.title) || !modal
        ? { open: false, modal: null }
        : { open: true, modal: { title: modal, data: passedData } }
    )
  }, [])

  /**
   * Get all bigboxes
   * Runs at startup and any time the modal is updated.
   */
  useEffect(() => {
    setLoadingMessage('Loading Big Boxes')
    api.bigbox.getActiveBigBoxes().then(res => {
      setBigBoxData(res)
    })
    setLoadingMessage(null)
  }, [modalState])

  /*
    Snackbars

    Everything is passed in one variable called snackbarFunctions
    To create a new snackbar, pass snackbarFunctions to the component and run
    snackbarFunctions.newSnackbar({message: "My Message", type: "danger"})

    Type uses bulma color variables to change the background of the snackbar.

    Functions are wrapped inside of useCallback so they are memoized and not re-created
    each re-render.
  */

  const [snackbars, setSnackbars] = useState([])
  const snackbarFunctions = {
    newSnackbar: useCallback(snack => {
      let index = Math.random()
      setSnackbars(prevState =>
        prevState.concat({
          message: snack.message,
          type: snack.type,
          random: index,
        })
      )
      setTimeout(() => {
        setSnackbars(prevState => prevState.filter(e => e.random !== index))
      }, 4000)
    }, []),
    removeSnackbar: useCallback(index => {
      setSnackbars(prevState => {
        let newState = [...prevState]
        newState.splice(index, 1)
        return newState
      })
    }, []),
    snackbars: snackbars,
  }

  return (
    <React.Fragment>
      {modalState.open && (
        <Modal handleModal={handleModal}>
          {modalState.modal.title === 'new-bigbox' && <NewBigbox handleModal={handleModal} />}
          {modalState.modal.title === 'bigBoxConfig' && (
            <BigBoxConfig handleModal={handleModal} bigBox={modalState.modal.data} />
          )}
        </Modal>
      )}
      <SnackBar snackbarFunctions={snackbarFunctions} />
      <BigBoxLayout snackbarFunctions={snackbarFunctions} handleModal={handleModal}>
        {bigBoxData.map((bigBox, index) => (
          <BigBoxCard handleModal={handleModal} bigBox={bigBox} />
        ))}
      </BigBoxLayout>
    </React.Fragment>
  )
}

export default BigBox
