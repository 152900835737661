import request from './request'
import prefix from './prefix'

export const getTargeted = surveyID => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + `/api/survey/targeted-schools/${surveyID}`)

      // If body returns an error, quit
      if (res && res.body && res.body.error) return reject('No targeting on survey')

      let formattedFilters = []

      await res.body.forEach(inner => {
        formattedFilters.push({
          category: {
            value: 'school',
            label: 'school',
          },
          option: {
            value: inner,
            label: inner,
          },
        })
      })

      let formattedData = {
        filters: [{ filter: formattedFilters, options: [] }],
      }

      return resolve(formattedData)
    } catch (err) {
      reject('No targeting on survey')
    }
  })
}
