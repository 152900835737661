import request from './request'
import prefix from './prefix'
import { formatFilters } from './filters'

export const getSamples = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + '/api/panel/saved-samples')

      // If body returns an error, quit
      if (res && res.body && res.body.error) return reject(res.body.error)

      // Format samples for front-end
      let formattedData = await res.body.map(e => {
        let formattedFilters = []
        JSON.parse(e.filters).forEach(outer => {
          let filterSet = {
            filter: [],
            options: [],
          }
          outer.forEach(inner => {
            filterSet.filter.push({
              category: {
                value: inner.variable,
                label: inner.variable,
              },
              option: {
                value: inner.value,
                label: inner.value,
              },
            })
          })

          formattedFilters.push(filterSet)
        })

        // console.dir({
        //   formatted: formattedFilters,
        //   raw: res.body,
        // })

        return {
          name: e.name,
          notes: e.notes,
          filters: formattedFilters,
        }
      })

      return resolve(formattedData)
    } catch (err) {
      reject(err)
    }
  })
}

export const newSample = (name, filters, notes) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formattedQuery = formatFilters(filters)
      // if (filters && filters[0] && filters[0].category && andor) {

      // Create the sending value
      let data = {
        name: name,
        notes: notes,
        filters: JSON.stringify(formattedQuery),
      }

      // Send Data
      let res = await request.post(prefix + '/api/panel/saved-samples').send(data)
      if (res && res.body && res.body.error) return reject(res.body.error)
      return resolve(res)
    } catch (err) {
      reject(err)
    }
  })
}
