import React from 'react'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import './BigBoxCard.scss'
import _ from 'lodash'
import api from '../../api'

const BigBoxCard = ({ bigBox, handleModal }) => {
  // Calculate days remaining until bigBox endDate
  const daysRemaining = Math.floor((new Date(bigBox.endDate) - new Date()) / (1000 * 60 * 60 * 24))

  const handleCancelBigBox = async () => {
    await api.bigbox.closeBigBox({ _id: bigBox._id })
    handleModal(null)
  }

  return (
    <div className="card box">
      <h1 className="bigbox-title">{bigBox.name}</h1>
      <p className="bigBox-time">
        {new Date(bigBox.startDate).toLocaleDateString()} -{' '}
        {new Date(bigBox.endDate).toLocaleDateString()}
      </p>
      {/** Line */}
      <div className="columns has-space-between data-boxes is-multiline">
        <div className="column data-box">
          <p className="subtitle is-6">Status:</p>
          <label>{bigBox.status}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Scaling:</p>
          <label>{bigBox.scalingAlgorithm}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Current Campaign:</p>
          <label>{bigBox.currentCampaignName || 'No Campaign Yet'}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Maximum Daily Sends:</p>
          <label>{bigBox.maximumDailyCount}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Current Day:</p>
          <label>{bigBox.dayN}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Survey:</p>
          <label>
            <a
              href={`https://create.collegepulse.com/create/survey/metadata/${bigBox.survey}`}
              target="_blank"
            >
              {bigBox.survey}
            </a>
          </label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Days Remaining:</p>
          <label>{daysRemaining}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">Campaigns Used:</p>
          <label>{bigBox.allCampaignNames.length}</label>
        </div>
        <div className="column data-box">
          <p className="subtitle is-6">
            Total Emails Sent <em>(Updates Daily)</em>:
          </p>
          <label>{_.sumBy(bigBox.dailyStats, 'actualSends')}</label>
        </div>
      </div>
      {/** Line */}
      <div className="columns has-space-between data-boxes is-multiline">
        <div className="column">
          <AnimatedButton
            onClick={() => handleModal('bigBoxConfig', bigBox)}
            className="is-success is-fullwidth"
          >
            Big Box Configuration
          </AnimatedButton>
        </div>
        <div className="column">
          <AnimatedButton
            className="is-danger is-fullwidth"
            onClick={() => {
              handleCancelBigBox()
            }}
          >
            Cancel Big Box
          </AnimatedButton>
        </div>
      </div>
    </div>
  )
}
export default BigBoxCard
