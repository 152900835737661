import React from 'react'
import './Sidebar.scss'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import Filter from '../Filter/Filter'
import PropTypes from 'prop-types'

import useFilter from '../../context/FilterContext'
import { useLocation } from 'react-router'

const Sidebar = ({
  handleModal,
  filterN,
  fetchNewData,
  snackbarFunctions,
  filterHeaders,
  setFilterHeaders,
}) => {
  const { setFilterSets, filterSets } = useFilter()
  const location = useLocation()
  const fieldingPage = location.pathname === '/'
  const feasibilityPage = location.pathname === '/feasibility'
  const bigBoxPage = location.pathname === '/bigbox'
  const outreachPage = location.pathname === '/outreach'

  return (
    <div
      className="sidebar is-full-height has-text-white"
      style={outreachPage ? { justifyContent: 'flex-start' } : {}}
    >
      <div className="filter-component" style={outreachPage ? { flex: 'none' } : {}}>
        <header
          className="has-background-success has-padding"
          style={{ marginRight: '2rem', borderRadius: '0 50px 50px 0' }}
        >
          <span className="is-flex">
            {fieldingPage && (
              <React.Fragment>
                <h1 className="title is-4 has-text-white filter-header-text">Fielding</h1> |
                <p className="is-paddingless filter-header-subtext">Data Rows = {filterN || 0}</p>
              </React.Fragment>
            )}
            {feasibilityPage && (
              <h1 className="title is-4 has-text-white filter-header-text">Feasibility</h1>
            )}
            {bigBoxPage && (
              <h1 className="title is-4 has-text-white filter-header-text">Big Box</h1>
            )}
            {outreachPage && (
              <h1 className="title is-4 has-text-white filter-header-text">Outreach</h1>
            )}
          </span>
        </header>
        {fieldingPage && (
          <div className="has-padding" style={{ paddingBottom: 0 }}>
            <h5>
              <strong style={{ color: 'white' }}>Sample Statisitics Options</strong>
            </h5>
            <label style={{ width: '100%' }} className="checkbox">
              <input
                checked={filterHeaders.schoolBreakdown}
                onChange={() =>
                  setFilterHeaders(prev => ({ ...prev, schoolBreakdown: !prev.schoolBreakdown }))
                }
                type="checkbox"
              />
              <span className="filterHeader-checkbox-label">School Breakdown</span>
            </label>

            <label style={{ width: '100%' }} className="checkbox">
              <input
                checked={filterHeaders.loadStatistics}
                onChange={() =>
                  setFilterHeaders(prev => ({ ...prev, loadStatistics: !prev.loadStatistics }))
                }
                type="checkbox"
              />
              <span className="filterHeader-checkbox-label">Load Statistics</span>
            </label>
          </div>
        )}
        {(fieldingPage || feasibilityPage) &&
          filterSets &&
          filterSets.map((fset, findex) => (
            <React.Fragment key={findex}>
              {findex > 0 && (
                <div
                  style={{
                    width: '100%',
                    borderBottom: '2px solid #00000050',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    style={{
                      margin: 'auto',
                      marginBottom: '-1.05em',
                      padding: '0.25rem',
                      backgroundColor: '#34495e',
                    }}
                  >
                    AND
                  </span>
                </div>
              )}
              <Filter
                filters={fset.filter}
                findex={findex}
                filterAndOr={fset.filterAndOr}
                fetchNewData={fetchNewData}
                snackbarFunctions={snackbarFunctions}
              />
            </React.Fragment>
          ))}

        {(fieldingPage || feasibilityPage) && (
          <button
            onClick={() =>
              setFilterSets(prev => [
                ...prev,
                { filter: [{}], filterAndOr: { value: 'and', label: 'All' }, options: [] },
              ])
            }
            className="button is-success"
            style={{ width: 'calc(100% - 4rem)', marginLeft: '2rem' }}
          >
            Add an AND block
          </button>
        )}
      </div>
      {fieldingPage && (
        <div className="has-padding is-bottom-container columns is-multiline">
          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('scheduled-jobs')}
              className="button is-success is-fullwidth"
            >
              View Scheduled Jobs
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('survey-sync')}
              className="button is-success is-fullwidth"
            >
              Sync from Survey
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('save-sample')}
              className="button is-success is-fullwidth"
            >
              Save Sample
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('view-samples')}
              className="button is-success is-fullwidth"
            >
              View Saved Samples
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('view-sms')}
              className="button is-success is-fullwidth"
            >
              View Past SMS
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('view-emails')}
              className="button is-success is-fullwidth"
            >
              View Past Emails
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('upload-data')}
              className="button is-success is-fullwidth"
            >
              Upload Data
            </AnimatedButton>
          </div>

          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('set-sample')}
              className="button is-success is-fullwidth"
            >
              Set Sample
            </AnimatedButton>
          </div>
        </div>
      )}
      {feasibilityPage && (
        <div className="has-padding is-bottom-container columns is-multiline">
          <div className="column is-6">
            <AnimatedButton
              onClick={() => handleModal('download-csv')}
              className="button is-success is-fullwidth"
            >
              Download CSV
            </AnimatedButton>
          </div>
        </div>
      )}
      {bigBoxPage && (
        <div className="has-padding is-bottom-container columns is-multiline">
          <div className="column">
            <AnimatedButton
              onClick={() => handleModal('new-bigbox')}
              className="button is-success is-fullwidth"
            >
              Create new BigBox
            </AnimatedButton>
          </div>
        </div>
      )}
      {outreachPage && (
        <div>
          <p style={{ padding: '1rem' }}>
            NOTE: The ONLY OUTREACH CHANNEL that works with non-incentivized surveys, or surveys
            that target 'all' institutions, is SNOWBALL REMINDER.
          </p>
          <div className="has-padding is-bottom-container columns is-multiline">
            <div className="column is-6">
              <AnimatedButton
                onClick={() => handleModal('snowball-reminder')}
                className="button is-success is-fullwidth"
              >
                Snowball Reminder
              </AnimatedButton>
            </div>
            <div className="column is-6">
              <AnimatedButton
                onClick={() => handleModal('ambassador-invite')}
                className="button is-success is-fullwidth"
              >
                Ambassador Invite
              </AnimatedButton>
            </div>
            <div className="column is-6">
              <AnimatedButton
                onClick={() => handleModal('ambassador-reminder')}
                className="button is-success is-fullwidth"
              >
                Ambassador Reminder
              </AnimatedButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Sidebar.propTypes = {
  handleModal: PropTypes.func,
  filterN: PropTypes.number,
  fetchNewData: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
  filterHeaders: PropTypes.object,
  setFilterHeaders: PropTypes.func,
}

export default Sidebar
