import React, { useState } from 'react'
import Papa from 'papaparse'
import './UploadFile.scss'
import PropTypes from 'prop-types'

const UploadFile = ({ handleModal, snackbarFunctions }) => {
  const [fileHovering, setFileHovering] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessed, setIsProcessed] = useState(false)
  const [displayError, setError] = useState(null)

  const dropHandler = e => {
    e.preventDefault()
    setFileHovering(false)
    setIsProcessing(true)

    let file
    // Get the file
    if (e.dataTransfer) {
      // File was dragged methods
      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file
        file = e.dataTransfer.items[0].getAsFile()
      } else {
        // Use DataTransfer interface to access the file
        file = e.dataTransfer.files[0]
      }
    } else {
      // File was clicked method
      file = e.target.files[0]
    }

    // Validate if file is a CSV
    if (!(file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
      setIsProcessing(false)
      if (snackbarFunctions)
        snackbarFunctions.newSnackbar({ message: 'File must be a CSV', type: 'warning' })
      else setError('File must be a CSV!')
      return
    }

    Papa.parse(file, {
      complete: e => {
        setIsProcessed(true)

        // Move to next screen
        handleModal('upload-options', e.data)
      },
    })
  }

  return (
    <div className="box is-centered upload-file-box">
      <div className={`file is-boxed is-relative ${fileHovering && 'file-hovering'}`}>
        <input
          onDragEnter={() => {
            setFileHovering(true)
          }}
          onDragLeave={() => {
            setFileHovering(false)
          }}
          onDragExit={() => {
            setFileHovering(false)
          }}
          onDrop={e => dropHandler(e)}
          onChange={e => dropHandler(e)}
          className="file-input is-fullwidth is-centered"
          type="file"
          name="csv"
        />

        <label
          style={{ width: '100%', height: 300 }}
          htmlFor="csv"
          className="file-cta is-centered"
        >
          {!isProcessing ? (
            <React.Fragment>
              <span className="file-icon">
                <img alt="Upload Icon" src="/upload-solid.svg" />
              </span>
              <span className="label">
                {fileHovering ? 'Let go of the file' : 'Choose or drop a file'}
              </span>
              <span className="label">Notes</span>
              <span>Must be CSV file. Header row must be included as first row.</span>
              <span style={{ color: 'red' }}>{displayError ? displayError : ''}</span>
            </React.Fragment>
          ) : (
            <h3 className="title is-4">
              {!isProcessed ? 'Uploading File...' : 'Success! Uploaded.'}
            </h3>
          )}
        </label>
      </div>
    </div>
  )
}

UploadFile.propTypes = {
  handleModal: PropTypes.func.isRequired,
  snackbarFunctions: PropTypes.object.isRequired,
}

export default UploadFile
