import React, { useEffect, useState, useCallback } from 'react'
import api from '../../api'
import './ScheduledJobs.scss'
import PropTypes from 'prop-types'
import {
  StatusCardHeader,
  StatusCardBody,
  StatusCardFooter,
} from '../ReusableComponents/Card/StatusCard'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import { cancelJobs } from '../../api/scheduledJobs'

// Upcoming: Blue
// Canceled: Red
// Failed: Dark Red
// Performed: Green
// This component loads all job and can sort them by:
//    - job type
//      - email
//      - sms
//      - email-priority-check
//    - status
//      - new
//      - completed
//      - canceled
//TODO(Zap): Add a box to show when no messages load to give a no messages loaded message to the user
//TODO(Zap): For all email jobs, get their statistics, aka email sent etc. like in View Past Emails
//TODO(Zap): add a parameter, in the past 2 weeks etc. Initially load only in the past 14 days.
//TODO: Deprecate current send sms and send email, and make all the flows go through
// shecudled jobs??? This way we will decrease our logic by a lot and also reuse a lot of code.
// This will make it easier for us to debug and modify sms and email systems and all jobs in general.

const ScheduledJobs = ({ snackbarFunctions, handleModal }) => {
  const [cancelSurveyId, setCancelSurveyId] = useState('')
  const [cancelCampaignName, setCancelCampaignName] = useState('')
  const [cancelPressed, setCancelPressed] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [scheduledJobs, setScheduledJobs] = useState([])
  // const [emailPriorityJobs, setEmailPriorityJobs] = useState(false)
  const [latestDataFilters, setLatestDataFilters] = useState({
    jobType: {
      emailJobs: true,
      smsJobs: true,
      emailPriorityJobs: false,
    },
    modeObject: {
      new: true,
      completed: false,
      canceled: false,
    },
  })

  // Helpers and Filter's Map Definitions
  const jobTypeMap = {
    emailJobs: 'email-job',
    smsJobs: 'sms-job',
    emailPriorityJobs: 'email-priority-check',
  }

  const modeTypeMap = modeObject => {
    let backendMode = 'new'

    if (modeObject.new && modeObject.completed && modeObject.canceled) {
      backendMode = 'all'
    } else if (modeObject.new && modeObject.completed) {
      backendMode = 'new-completed'
    } else if (modeObject.new && modeObject.canceled) {
      backendMode = 'new-canceled'
    } else if (modeObject.completed && modeObject.canceled) {
      backendMode = 'old'
    } else if (modeObject.completed) {
      backendMode = 'completed'
    } else if (modeObject.canceled) {
      backendMode = 'canceled'
    }
    return backendMode
  }

  const filtersToQuery = filters => {
    const mode = modeTypeMap(filters.modeObject)

    const typesToGet = Object.keys(filters.jobType)
      .map(key => (filters.jobType[key] ? jobTypeMap[key] : null))
      .filter(obj => obj)

    const query = {
      mode,
      typesToGet,
    }
    return query
  }

  // Api Functions
  const loadJobs = async filters => {
    const { modeObject } = filters
    try {
      let query = filtersToQuery(filters)
      let res = await api.scheduledJobs.getAll(query)

      // If we want only completed get all 'old' that have performed = true and canceled
      if (modeObject.completed && !modeObject.canceled) {
        res = res.filter(event => {
          if (event.jobType === 'email-priority-check' && event.canceled) {
            return false
          } else {
            return true
          }
        })
      }

      setScheduledJobs(res.sort((a, b) => new Date(a.jobDate) - new Date(b.jobDate)))
    } catch (err) {
      console.log(err)
      snackbarFunctions.newSnackbar({ message: err || 'Error getting emails', type: 'danger' })
    }
  }

  const cancelJob = useCallback(jobId => {
    const cancelSelectedJob = async jobId => {
      await api.scheduledJobs.cancel(jobId)
      //TODO(Zap): On delete, update the job to canceled and reflect
      // the change on the front end!
      handleModal(null)
    }
    cancelSelectedJob(jobId)
  }, [])

  //export const cancelJobs = ({ campaignName = null, surveyId = null }) => {
  const cancelScheduledJobs = async () => {
    setCancelLoading(true)
    setCancelPressed(true)

    await cancelJobs({ campaignName: cancelCampaignName, surveyId: cancelSurveyId })
    setCancelLoading(false)
  }

  const loadData = useCallback(
    ({ type, modeObjectVal }) => {
      //type = emailJobs, smsJobs, emailPriorityJobs
      const newFilter = latestDataFilters
      if (type) {
        newFilter.jobType[type] = !latestDataFilters.jobType[type]
      }
      if (modeObjectVal) {
        newFilter.modeObject[modeObjectVal] = !newFilter.modeObject[modeObjectVal]
      }
      setLatestDataFilters(newFilter)
      loadJobs(newFilter)
    },
    [setScheduledJobs]
  )

  // Initially get only upcoming sms-jobs, email-jobs. Decreases load up time
  useEffect(() => {
    loadJobs(latestDataFilters)
  }, [snackbarFunctions])

  // Toolbar Component
  const toolBar = () => {
    return (
      <div className="sheduled-jobs-toolbar-box box">
        <div className="sheduled-jobs-toolbar">
          <h2 className="is-size-4 has-text-weight-bold is-paddingless sheduled-jobs-toolbar-header">
            Selected Jobs to View (30)
          </h2>
          <div className="sheduled-jobs-toolbar-row-outer">
            <div className="sheduled-jobs-toolbar-row">
              <h2 className="is-6 has-text-weight-bold is-paddingless sheduled-jobs-toolbar-header-row">
                Job Types:
              </h2>
              <div className="checkbox-box">
                <label className="checkbox">
                  <input
                    onChange={() => loadData({ type: 'emailJobs' })}
                    checked={latestDataFilters.jobType.emailJobs}
                    type="checkbox"
                  />
                </label>
                <small className="has-padding-left">Email</small>
              </div>
              <div className="checkbox-box">
                <label className="checkbox">
                  <input
                    onChange={() => loadData({ type: 'smsJobs' })}
                    checked={latestDataFilters.jobType.smsJobs}
                    type="checkbox"
                  />
                </label>
                <small className="has-padding-left">Sms</small>
              </div>
              <div className="checkbox-box">
                <label className="checkbox">
                  <input
                    onChange={() => loadData({ type: 'emailPriorityJobs' })}
                    checked={latestDataFilters.jobType.emailPriorityJobs}
                    type="checkbox"
                  />
                </label>
                <small className="has-padding-left">Email Priority</small>
              </div>
            </div>
          </div>
          <div className="sheduled-jobs-toolbar-row-outer">
            <div className="sheduled-jobs-toolbar-row">
              <h2 className="is-6 has-text-weight-bold is-paddingless sheduled-jobs-toolbar-header-row">
                Job Status:
              </h2>
              <div className="checkbox-box">
                <label className="checkbox">
                  <input
                    onChange={() => loadData({ modeObjectVal: 'new' })}
                    checked={latestDataFilters.modeObject.new}
                    type="checkbox"
                  />
                </label>
                <small className="has-padding-left">Upcoming</small>
              </div>
              <div className="checkbox-box">
                <label className="checkbox">
                  <input
                    onChange={() => loadData({ modeObjectVal: 'completed' })}
                    checked={latestDataFilters.modeObject.completed}
                    type="checkbox"
                  />
                </label>
                <small className="has-padding-left">Completed</small>
              </div>
              <div className="checkbox-box">
                <label className="checkbox">
                  <input
                    onChange={() => loadData({ modeObjectVal: 'canceled' })}
                    checked={latestDataFilters.modeObject.canceled}
                    type="checkbox"
                  />
                </label>
                <small className="has-padding-left">Canceled</small>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5rem',
                backgroundColor: 'white',
                borderRadius: '0.25rem',
                marginTop: '2rem',
              }}
            >
              <div style={{ fontWeight: 900 }}>Cancel Scheduled Jobs</div>

              <input
                style={{ width: '25%' }}
                className="input"
                type="text"
                placeholder="Optional: Survey ID"
                value={cancelSurveyId}
                onChange={e => {
                  setCancelSurveyId(e.target.value)
                }}
              />

              <input
                style={{ width: '25%' }}
                className="input"
                type="text"
                placeholder="Optional: Campaign Name"
                value={cancelCampaignName}
                onChange={e => {
                  setCancelCampaignName(e.target.value)
                }}
              />

              <div>
                <AnimatedButton
                  className="is-danger"
                  disabled={cancelPressed}
                  loading={cancelLoading}
                  onClick={() => {
                    cancelScheduledJobs()
                  }}
                >
                  Cancel Scheduled Jobs
                </AnimatedButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Job Card and Card Components

  const getStatus = job => {
    const jobStatus = job.canceled
      ? 'Canceled'
      : new Date(job?.jobDate) > new Date()
        ? 'Upcoming'
        : job.performed
          ? 'Performed'
          : 'Failed'

    const statusColor =
      jobStatus === 'Performed'
        ? 'success'
        : jobStatus === 'Canceled'
          ? 'danger'
          : jobStatus === 'Failed'
            ? 'danger-dark'
            : 'link'

    return { jobStatus, statusColor }
  }

  const jobTitle = job => {
    let title = 'N/A'

    if (
      job.jobType === 'email-job' ||
      job.jobType === 'email-priority-check' ||
      job.jobType === 'sms-job'
    ) {
      title = job?.jobData?.campaignName || job?._id || 'N/A'
    }

    return title
  }

  const jobCard = ({ job, statusColor, jobStatus, i }) => {
    const cardData = [
      [
        {
          title: ' ',
          value: ' ',
        },
        {
          title: 'Job type:',
          value: job?.jobType || 'N/A',
        },
        {
          title: 'Job Id:',
          value: job?._id || 'N/A',
        },
        {
          title: ' ',
          value: ' ',
        },
      ],

      [
        {
          title: ' ',
          value: ' ',
        },
        {
          title: 'Subject:',
          value: job?.jobData?.subject || 'N/A',
        },
        {
          title: 'Survey topic:',
          value: job?.jobData?.globalVariables?.surveyTopic || 'N/A',
        },
        {
          title: ' ',
          value: ' ',
        },
      ],
    ]
    return (
      <div className="box" key={i}>
        <StatusCardHeader
          title={jobTitle(job)}
          status={jobStatus}
          statusColor={statusColor}
          jobId={job._id}
          cancelJob={cancelJob}
          jobStatus={getStatus(job).jobStatus}
        />
        <StatusCardBody cardData={cardData} />
        <StatusCardFooter
          leftLabel={'Created on:'}
          leftValue={job?.createdAt}
          rightValue={job?.jobDate}
          rightLabel={'Scheduled for:'}
        ></StatusCardFooter>
      </div>
    )
  }

  const jobCardList = ({ scheduledJobs }) => {
    const cardList = scheduledJobs => {
      return (
        scheduledJobs &&
        scheduledJobs.map((job, i) => {
          const { statusColor, jobStatus } = getStatus(job)
          return jobCard({ job, statusColor, jobStatus, i })
        })
      )
    }

    return <div>{cardList(scheduledJobs)}</div>
  }

  return (
    <div className="box view-jobs-container handleModals-backround-lighter-gray">
      {toolBar()}
      {jobCardList({ scheduledJobs })}
    </div>
  )
}

ScheduledJobs.propTypes = {
  snackbarFunctions: PropTypes.object.isRequired,
}

export default ScheduledJobs
