import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'
import { postTokenToCP } from './cross-origin-iframes'
import queryString from 'query-string'

/******************************** exported ************************************/

export const signin = function (username, password) {
  return new Promise((resolve, reject) => {
    if (storage.get('token')) {
      return resolve(storage.get('user'))
    }

    request
      .post(prefix + '/api/signin')
      .send({ username: username, password: password })
      .end((err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        storage.set('user', res.body.user)
        saveNewToken(res.body.token)

        isSignedIn()
        return resolve(res)
      })
  })
}

export const signout = function () {
  storage.remove('token')
  storage.remove('user')
  removeAllCookies()
}

export const isSignedIn = function () {
  const token = storage.get('token')

  const queryParams = queryString.parse(window.location.search)

  if (queryParams.token) {
    saveNewToken(queryParams.token)
    return queryParams.token
  }

  const tokenExists = token !== undefined && token !== 'undefined'

  // if token exists return true
  if (tokenExists) {
    return token
  }

  var cook = decodeURIComponent(document.cookie)
  if (cook.indexOf('credentials') > -1) {
    var json = JSON.parse(cook.split('j:')[1])
    storage.set('user', json.user)
    // storage.set('token', json.token, true)
    saveNewToken(json.token)
    return json.token
  }
  return null
}

export const processTokenDirectly = function (token) {
  if (token !== undefined && token !== 'undefined') {
    // storage.set('token', token, true)
    saveNewToken(token)
    return true
  }
  return false
}

/********************************* local *************************************/

export function saveNewToken(token) {
  storage.set('token', token, true)
  postTokenToCP(token)
}

export function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export function removeAllCookies() {
  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) eraseCookie(cookies[i].split('=')[0])
}
