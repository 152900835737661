import React from 'react'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'
import {
  ReusableTextInput,
  ReusableCheckboxColumn,
  ReusableDropDownInput,
  CommonFields,
} from '../ReusableComponents/ReusableInputComponents'
import PropTypes from 'prop-types'

// This array holds all the Sms Template values.
const EMAIL_PROVIDERS = [
  { name: 'Postmark - Yayy', value: 'postmark' },
  { name: 'Mailjet - Eww', value: 'mailjet' },
  { name: 'SendGrid - Uhh', value: 'sendgrid' },
  { name: 'ElasticEmail - Wow', value: 'elasticemail' },
]

const EMAIL_SENDERS = [
  { name: 'Mary - mary@students.collegepulse.com', value: 'mary@students.collegepulse.com' },
  {
    name: 'Jaxon - jaxon.smith@students.collegepulse.com',
    value: 'jaxon.smith@students.collegepulse.com',
  },
  { name: 'Terren - terren.h.klein.17@dartmouth.edu', value: 'terren.h.klein.17@dartmouth.edu' },
  { name: 'Jaxon - jaxon.smith@collegepulse.com', value: 'jaxon.smith@collegepulse.com' },
]

const MAILJET_TEMPLATES = [
  { name: 'Direct Incentive', value: '1474521' },
  { name: '[ALUMNI] Direct Incentive', value: '1703196' },
  { name: 'Faculty Direct Incentive', value: '3089394' },
  { name: 'Plain Text: Mary Email', value: '3732513' },
]

const POSTMARK_TEMPLATES = [
  { name: 'Direct Incentive', value: '27488124' },
  { name: 'Mary Direct', value: '28043001' },
  { name: 'Mary Non-Direct (From students)', value: '30998559' },
  { name: 'From Mary - B (from students)', value: '31083641' },
  { name: 'From Mary Casual - B', value: '31083522' },
  { name: 'Chat GPT - AB Test', value: '30998536' },
  { name: 'Chat GPT - B', value: '31083644' },
  { name: 'Incentive Focus - B', value: '31083514' },
  { name: 'Raffle Incentive - B', value: '31083647' },
  { name: 'Unfinished Survey Retarget - A', value: '31261124' },
  { name: 'Unfinished Survey Retarget - B', value: '31261128' },
  { name: 'Coffee Incentive - A (Casual)', value: '31306005' },
  { name: 'Coffee Incentive - B (DI copy)', value: '31306007' },
  { name: 'Direct Incentive - (university) (subject)', value: '31316951' },
  { name: 'Open Retarget - A (Coffee)', value: '31424923' },
  { name: 'Open Retarget - B (Make a difference)', value: '31424945' },
  { name: 'CGPTJune5-Version1', value: '32024909' },
  { name: 'CGPTV5', value: '32059774' },
  { name: 'CGPTV6', value: '32059505' },
  { name: 'CGPTV7', value: '32059776' },
  { name: 'CGPTV8', value: '32059778' },
  { name: 'CGPTV9', value: '32059508' },
  { name: 'CGPTV10', value: '32059509' },
  { name: 'Jaxon Direct - 1 - Fire', value: '32115614' },
  { name: 'Jaxon Direct - 2 - Fire', value: '32114871' },
  { name: 'Jaxon Direct - 3 - Fire', value: '32115615' },
]

const SENDGRID_TEMPLATES = [
  { name: 'Direct Incentive', value: 'd-97f7fb7efaad4c5f9e1a42ff02f16b18' },
  { name: 'CGPTJune5-Version2', value: 'd-304445e553044b20beaebf992b1dd600' },
  { name: 'CGPTJune5-Version3', value: 'd-4f922045c7d841d3aa8105d05d6d1b83' },
]

const ELASTICEMAIL_TEMPLATES = [{ name: 'Direct Incentive', value: 'direct-incentive' }]

const SendEmailForm = ({
  handleTemplateChange,
  handleProviderChange,
  handleInput,
  handleEstimate,
  hasMessage,
  estimateInformation,
  estimateLoading,
  noOverlapCampaigns,
  setNoOverlapCampaigns,
  prevCampaigns,
  handleSubmit,
  sendLoading,
  handleSubmitSelf,
  setStartScheduling,
  startScheduling,
  inputs,
  setOnlyUsers,
  onlyUsers,
  setAllowAlumni,
  allowAlumni,
  setOnlyEdVisors,
  onlyEdVisors,
  setSaveSampleBox,
  saveSampleBox,
  onlyPersonal,
  setOnlyPersonal,
  handleSenderChange,
  growthChannel,
  setUseShortLink,
  useShortLink,
}) => {
  // This is an array that hoplds the variables needed for each checkboxObject
  // to render the checkbox list.
  const checkboxRows = [
    {
      label: 'Use Pulse Short Link on Survey Link',
      handleOnChange: () => setUseShortLink(e => !e),
      value: useShortLink,
    },
    {
      label: 'Only Send to Users',
      handleOnChange: () => setOnlyUsers(e => !e),
      value: onlyUsers,
    },
    {
      label: 'Only Send to EdVisor Emails',
      handleOnChange: () => setOnlyEdVisors(e => !e),
      value: onlyEdVisors,
    },
    {
      label: 'Allow send to alumni',
      handleOnChange: () => setAllowAlumni(e => !e),
      value: allowAlumni,
    },
    {
      label: 'Only Send to Personal Emails',
      handleOnChange: () => setOnlyPersonal(e => !e),
      value: onlyPersonal,
    },
    {
      label: 'Save Sample?',
      handleOnChange: () => setSaveSampleBox(e => !e),
      value: saveSampleBox,
    },
  ]

  return (
    <>
      <ReusableDropDownInput
        label={'Provider Selection'}
        handleChange={handleProviderChange}
        selectionList={EMAIL_PROVIDERS}
      />

      <ReusableDropDownInput
        label={`Template Selection ${inputs.provider}`}
        handleChange={handleTemplateChange}
        selectionList={
          inputs.provider === 'mailjet'
            ? MAILJET_TEMPLATES
            : inputs.provider === 'sendgrid'
              ? SENDGRID_TEMPLATES
              : inputs.provider === 'elasticemail'
                ? ELASTICEMAIL_TEMPLATES
                : inputs.provider === 'postmark'
                  ? POSTMARK_TEMPLATES
                  : []
        }
      />

      {(inputs.provider === 'postmark' ||
        inputs.provider === 'sendgrid' ||
        inputs.provider === 'elasticemail') && (
        <ReusableDropDownInput
          label={'Sender Selection'}
          handleChange={handleSenderChange}
          selectionList={EMAIL_SENDERS}
        />
      )}

      <ReusableTextInput
        label={'Growth Channel'}
        placeholder={`email-${
          inputs.provider === 'mailjet' ? 'mj' : inputs.provider === 'sendgrid' ? 'sg' : 'pm'
        }-<your input>`}
        inputKey={'growthChannelCustom'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />

      <ReusableTextInput
        label={'Subject'}
        placeholder={'Subject Line'}
        inputKey={'subject'}
        inputType={'text'}
        className={'field'}
        handleChange={handleInput}
      />

      <CommonFields
        handleInput={handleInput}
        noOverlapCampaigns={noOverlapCampaigns}
        prevCampaigns={prevCampaigns}
        setNoOverlapCampaigns={setNoOverlapCampaigns}
      />

      {/* I am not sure what this is doing  */}
      {hasMessage && (
        <div className="field">
          <label className="label">Message</label>
          <div className="control">
            <textarea
              onChange={e => handleInput('message', e.target.value)}
              className="textarea"
              placeholder="This is the message that will be included in the template!"
            />
          </div>
        </div>
      )}

      <div className="field">
        <label className="label">Select From the Following Options</label>
      </div>

      <div className="columns">
        <ReusableCheckboxColumn checkboxRows={checkboxRows} />
      </div>

      <div className="columns">
        <ReusableTextInput
          label={'Max Emails Per School (optional)'}
          placeholder={'100'}
          inputKey={'maxEmailsPerSchool'}
          inputType={'number'}
          className={'field is-marginless column'}
          handleChange={handleInput}
        />
        <ReusableTextInput
          label={'Max Emails'}
          placeholder={'100'}
          inputKey={'maxEmails'}
          inputType={'number'}
          className={'field is-marginless column'}
          handleChange={handleInput}
        />
      </div>

      <div className="level">
        <AnimatedButton
          onClick={handleEstimate}
          disabled={
            sendLoading ||
            !inputs.subject ||
            !inputs.campaignName ||
            !inputs.surveyId ||
            estimateLoading
          }
          className={`level-item button is-success is-set-height ${
            estimateLoading && 'is-loading'
          }`}
        >
          Generate Estimate
        </AnimatedButton>
      </div>
      {estimateInformation && (
        <>
          <div className="level">
            <p>
              <strong>Total Email Count: </strong>
              {estimateInformation.totalEmailCount}
            </p>
            <p>
              <strong>Estimate count: </strong>
              {estimateInformation.adjusted}
            </p>
            <p>
              <strong>Estimate time to complete: </strong>
              {estimateInformation.estimatedTime} minutes
            </p>
          </div>
          <div className="level">
            <p>
              <strong>Engaged: </strong>
              {estimateInformation.breakdown.engaged}
            </p>
            <p>
              <strong>Reengage: </strong>
              {estimateInformation.breakdown.reengage}
            </p>
            
          </div>
        </>
      )}

      <div className="level send-buttons-box">
        <AnimatedButton
          onClick={handleSubmit}
          disabled={
            sendLoading ||
            !inputs.subject ||
            !inputs.campaignName ||
            !inputs.surveyId ||
            !inputs.maxEmails ||
            !inputs.templateId
          }
          className={`level-item button is-success is-set-height ${sendLoading && 'is-loading'}`}
        >
          Send Email
        </AnimatedButton>
        <AnimatedButton
          onClick={handleSubmitSelf}
          disabled={sendLoading || !inputs.subject || !inputs.campaignName || !inputs.surveyId}
          className={`level-item button is-primary is-set-height ${sendLoading && 'is-loading'}`}
        >
          Send to self
        </AnimatedButton>
        <AnimatedButton
          onClick={() => setStartScheduling(true)}
          className={`level-item button is-primary is-set-height ${sendLoading && 'is-loading'}`}
          disabled={startScheduling}
        >
          {startScheduling ? 'Scroll Down' : 'Start Scheduling'}
        </AnimatedButton>
      </div>
    </>
  )
}

SendEmailForm.propTypes = {
  handleTemplateChange: PropTypes.func.isRequired,
  handleProviderChange: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleEstimate: PropTypes.func.isRequired,
  setOnlyEdVisors: PropTypes.func.isRequired,
  setSaveSampleBox: PropTypes.func.isRequired,
  setNoOverlapCampaigns: PropTypes.func.isRequired,
  handleSubmitSelf: PropTypes.func.isRequired,
  setStartScheduling: PropTypes.func.isRequired,
  startScheduling: PropTypes.func.isRequired,
  setOnlyUsers: PropTypes.func.isRequired,
  setAllowAlumni: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onlyUsers: PropTypes.bool.isRequired,
  allowAlumni: PropTypes.bool.isRequired,
  onlyEdVisors: PropTypes.bool.isRequired,
  saveSampleBox: PropTypes.bool.isRequired,
  sendLoading: PropTypes.bool.isRequired,
  hasMessage: PropTypes.bool.isRequired,
  estimateInformation: PropTypes.bool.isRequired,
  estimateLoading: PropTypes.bool.isRequired,
  noOverlapCampaigns: PropTypes.array.isRequired,
  prevCampaigns: PropTypes.array.isRequired,
  inputs: PropTypes.object.isRequired,
  onlyPersonal: PropTypes.bool.isRequired,
  setOnlyPersonal: PropTypes.bool.isRequired,
  handleSenderChange: PropTypes.func.isRequired,
  growthChannel: PropTypes.string.isRequired,
  setUseShortLink: PropTypes.func.isRequired,
  useShortLink: PropTypes.bool.isRequired,
}

export default SendEmailForm
