import React from 'react'
import './StatusCard.scss'
import AnimatedButton from '../../AnimatedButton/AnimatedButton'

export const StatusCardHeader = ({
  title,
  status,
  statusColor,
  email,
  applyFilter,
  jobId,
  cancelJob,
  jobStatus,
}) => {
  let buttonPresent = false
  let buttonFunc = null
  let buttonName = ''
  if (email && applyFilter) {
    buttonFunc = () => applyFilter([{ filter: email.filters, options: email.filterAndOr }])
    buttonName = 'View Sample'
    buttonPresent = true
  } else if (jobId && cancelJob && jobStatus === 'Upcoming') {
    buttonFunc = () => cancelJob(jobId)
    buttonName = 'Cancel'
    buttonPresent = true
  }
  return (
    <div className="status-card-header columns">
      {buttonPresent ? (
        <div className="status-card-header-button cloumn">
          <AnimatedButton
            onClick={() => buttonFunc()}
            className="is-primary"
            style={{ width: '100%', height: '46px' }}
          >
            {buttonName}
          </AnimatedButton>
        </div>
      ) : (
        <div className="status-card-header-button cloumn" />
      )}
      <div className="status-card-header-title cloumn">
        <h1 className="is-5 has-text-weight-bold">{title}</h1>
      </div>
      <div
        className={`status-card-header-stauts has-text-centered is-one-third column has-text-white has-text-weight-bold has-background-${statusColor}`}
      >
        <p>{status}</p>
      </div>
    </div>
  )
}

export const StatusCardRow = ({ title, value }) => {
  return (
    <div className="status-card-parameter-row is-6 has-text-weight-light is-paddingless">
      <div>
        <b>{title}</b>
      </div>
      <div>{value}</div>
    </div>
  )
}

export const StatusCardParameterBox = rows => {
  return <div className="status-card-parameter-box">{rows.map(row => StatusCardRow(row))}</div>
}

export const StatusCardBody = ({ cardData }) => {
  return (
    <div className="status-card-parameter-box-outer columns">
      {cardData.map(rows => StatusCardParameterBox(rows))}
    </div>
  )
}

export const StatusCardFooter = props => {
  const { leftLabel, leftValue, rightLabel, rightValue } = props
  return (
    <div className="columns status-card-footer is-one-third">
      <div className="column">
        <p className={`${props.children ? 'footer-left-val' : ''}`}>
          <em>
            {leftLabel} {new Date(leftValue).toLocaleString()}
          </em>
        </p>
      </div>
      <div className="column is-one-third">{props.children}</div>
      <div className="column has-text-right is-one-third">
        <p className={`${props.children ? 'footer-right-val' : ''}`}>
          <em>
            {rightLabel} {new Date(rightValue).toLocaleString()}
          </em>
        </p>
      </div>
    </div>
  )
}

export const StatusCardError = ({ email }) => {
  return (
    <>
      {email.message && (
        <p>
          <small>{email.message}</small>
        </p>
      )}
      {email.failedReason && (
        <p className="has-text-danger">
          <small>{email.failedReason}</small>
        </p>
      )}
    </>
  )
}
