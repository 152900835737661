let prefix

if (window.location.host === 'panel.collegepulse.com') {
  prefix = 'https://env-datascience-pulse-1.aws.collegepulse.com'
} else if (window.location.host === 'panel-staging.collegepulse.com') {
  prefix = 'https://dartmouthpulsestaging.herokuapp.com'
} else {
  const arr = window.location.href.split(':')
  prefix = `${arr[0]}:${arr[1]}:3001`
}
//prefix = 'https://datascience-worker.herokuapp.com'

export default prefix
