import prefix from './prefix'
import request from './request'

export const getConfig = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/demographics/config').end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

export const schoolNamesForIds = function (_ids) {
  const [firstId] = _ids
  if (!firstId) {
    return []
  }
  const _idType = firstId.length === 6 ? 'unitid' : firstId.length === 24 ? '_id' : null
  if (!_idType) {
    throw new Error('Unknown _id type')
  }

  return new Promise((resolve, reject) => {
    request
      .get(prefix + `/api/demographics/schoolnames/${_idType}`)
      .query({ school_id: _ids })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}
