import React from 'react'
import PropTypes from 'prop-types'
import Sidebar from '../components/Sidebar/Sidebar'

const FeasibilityLayout = ({
  setFilterN,
  fetchNewData,
  children,
  snackbarFunctions,
  downloadToCsv,
  handleModal,
}) => {
  return (
    <React.Fragment>
      <aside className="has-background-primary">
        <Sidebar
          setFilterN={setFilterN}
          fetchNewData={fetchNewData}
          snackbarFunctions={snackbarFunctions}
          downloadToCsv={downloadToCsv}
          handleModal={handleModal}
        />
      </aside>
      <main
        className="box"
        style={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '1rem',
          paddingTop: '0',
        }}
      >
        {children}
      </main>
    </React.Fragment>
  )
}

FeasibilityLayout.propTypes = PropTypes.object.isRequired

export default FeasibilityLayout
