import React from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import { AnimatedButton } from '../AnimatedButton/AnimatedButton'

import './SchedulingCalendar.scss'
import './DatetimeCustomStyle.scss'

export const SchedulingCalendarsListBox = ({
  startScheduling,
  schedules,
  setSchedules,
  handleSubmitSchedules,
  sendLoading,
  inputs,
}) => {
  return (
    <div className="margin-top">
      {startScheduling && (
        <>
          <div className={'scheduled-calendars-list'}>
            {schedules &&
              schedules.map((schedule, idx) => (
                <SchedulingCalendar
                  key={idx}
                  schedule={schedule}
                  idx={idx}
                  schedules={schedules}
                  setSchedules={setSchedules}
                />
              ))}
          </div>

          <div className="level">
            <AnimatedButton
              onClick={() => {
                setSchedules(s => [...s, moment()])
              }}
              className={'level-item button is-primary is-set-height'}
            >
              Add another schedule
            </AnimatedButton>

            <AnimatedButton
              onClick={handleSubmitSchedules}
              disabled={sendLoading || !inputs.campaignName || !inputs.surveyId || !schedules}
              className={`level-item button is-success is-set-height ${
                sendLoading && 'is-loading'
              }`}
            >
              Schedule Sms
            </AnimatedButton>
          </div>
        </>
      )}
    </div>
  )
}

const SchedulingCalendar = ({ schedule, idx, schedules, setSchedules }) => {
  return (
    <div key={idx} className="calendar-box">
      <div className="calendar-job-number">{`${idx + 1}`}</div>
      <Datetime
        // className="scheduling-calendar"
        onChange={d => {
          let s = [...schedules]
          s[idx] = d
          setSchedules(s)
        }}
        value={schedule}
        open={true}
        input={false}
      />
      <div
        className="calendar-cancel-job-button"
        onClick={() => {
          setSchedules(s => {
            let temp = [...s]
            temp.splice(idx, 1)
            return temp
          })
        }}
      >
        X
      </div>
    </div>
  )
}

export default SchedulingCalendar
