/*
 * These are the demographics we allow users to tie to emails for now.
 * If the CSV has a column called "year" or "class year" we auto link
 * that to the year demographic.
 */
export default [
  {
    name: 'year',
    autoMatchingColumnTitles: ['year', 'class year', 'classyear', 'gradyear'],
  },
  {
    name: 'gender',
    autoMatchingColumnTitles: ['gender', 'sex'],
  },
  {
    name: 'studentType',
    autoMatchingColumnTitles: ['student type', 'studenttype', 'type', 'degree'],
  },
]
