import React, { createContext, useState, useEffect, useContext, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import api from '../api'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingInitial, setLoadingInitial] = useState(true)

  const history = useHistory()
  const location = useLocation()

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null)
  }, [location.pathname])

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    const token = api.signin.isSignedIn()
    if (token) {
      setUser({ token: token })
    }
    setLoadingInitial(false)
  }, [])

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  const login = async ({ username, password }) => {
    setLoading(true)
    api.signin
      .signin(username, password)
      .then(res => {
        setUser({ token: res?.body?.token })
        history.push('/')
        setError(null)
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  const logout = () => {
    api.signin.signout()
    setUser(undefined)
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
    }),
    [user, loading, error]
  )

  return (
    <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>
  )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext)
}
