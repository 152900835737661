import React, { useState, useEffect } from 'react'
import './BigBoxConfig.scss'
import './NewBigbox.scss'
import DateTime from 'react-datetime'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import api from '../../api'

const BigBoxConfig = ({ bigBox: previousBigBox, handleModal }) => {
  const handleUpdateBigbox = async () => {
    await api.bigbox.updateBigBox({ _id: bigBox._id, propertiesToUpdate: bigBoxUpdate })
    handleModal(null)
  }

  const scalingOptions = [
    // Linear
    'linear-slow', // 75(day)2 : Linear - Slow : 50k a day per email server after 33 days
    'linear-medium', // 150(day)2 : Linear - Medium : 50k a day per email server after 17 days
    'linear-fast', // 250(day)2 : Linear - Fast : 50k a day per email server after 10 days

    // Exponential
    'exponential-slow', // 1.25^n + 100 : (38 day ramp) : 50k a day per email server after 38 days
    'exponential-medium', // 1.45^n + 100 : (23 day ramp) : 50k a day per email server after 23 days
    'exponential-fast', // 1.74^n + 1000 : (15 day ramp) : 50k a day per email server after 15 days
  ]

  // Current full bigBox object
  const [bigBox, setBigBox] = useState(previousBigBox)

  // Properties that need to be updated + _id for identification
  const [bigBoxUpdate, setBigBoxUpdate] = useState({
    _id: bigBox._id,
  })

  // Automatically update bigBoxUpdate when bigBox changes
  useEffect(() => {
    // Get a list of variable names that have been updated
    let updateVariables = Object.keys(bigBox).filter(key => bigBox[key] !== previousBigBox[key])

    // Create a new object with only the updated variables
    let update = updateVariables.reduce((acc, cur) => {
      acc[cur] = bigBox[cur]
      return acc
    }, {})

    // Update the "BigBoxUpdate" object with the new variables
    setBigBoxUpdate(prev => ({ ...prev, ...update }))
  }, [bigBox])

  // Debug State
  const [debug, setDebug] = useState(false)

  if (bigBox) {
    return (
      <div className="card box bigbox-config bigbox">
        <h1>Big Box Configuration</h1>
        <h2>{bigBox.name}</h2>

        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={bigBox.name}
              onChange={e => setBigBox({ ...bigBox, name: e.target.value })}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Start Date:</label>
          <div className="control">
            <DateTime
              onChange={e => {
                setBigBox({ ...bigBox, startDate: e.toDate() })
              }}
              value={bigBox.startDate}
              closeOnSelect={true}
              timeFormat={false}
              className="input dateinput"
              //type="text"
              //placeholder={option.placeholder}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">End Date:</label>
          <div className="control">
            <DateTime
              onChange={e => {
                setBigBox({ ...bigBox, endDate: e.toDate() })
              }}
              value={bigBox.endDate}
              closeOnSelect={true}
              timeFormat={false}
              className="input dateinput"
              //type="text"
              //placeholder={option.placeholder}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">Maximum Daily Count:</label>
          <div className="control">
            <input
              className="input"
              type="number"
              value={bigBox.maximumDailyCount}
              onChange={e => setBigBox({ ...bigBox, maximumDailyCount: e.target.value })}
            />
          </div>
        </div>

        <div className="field">
          <label className="label level-add">
            Subjects
            <button
              className="add"
              onClick={() => {
                let cc = {
                  campaignConfiguration: {
                    ...bigBox.campaignConfiguration,
                    subjects: [...bigBox.campaignConfiguration.subjects, ''],
                  },
                }
                setBigBox(prev => ({
                  ...prev,
                  ...cc,
                }))
              }}
            >
              +1
            </button>
          </label>
          <div className="control">
            {bigBox.campaignConfiguration.subjects.map((value, index) => (
              <div className="multi-string" key={index}>
                <input
                  onChange={e => {
                    e.preventDefault()
                    const newValues = [...bigBox.campaignConfiguration.subjects]
                    newValues[index] = e.target.value
                    let cc = {
                      campaignConfiguration: {
                        ...bigBox.campaignConfiguration,
                        subjects: [...newValues],
                      },
                    }
                    setBigBox(prev => ({
                      ...prev,
                      ...cc,
                    }))
                  }}
                  value={bigBox.campaignConfiguration.subjects[index]}
                  className="input"
                  type="text"
                  style={{ marginBottom: '0.5rem' }}
                />
                <button
                  className="remove"
                  onClick={() => {
                    const newValues = [...bigBox.campaignConfiguration.subjects]
                    newValues.splice(index, 1)
                    let cc = {
                      campaignConfiguration: {
                        ...bigBox.campaignConfiguration,
                        subjects: [...newValues],
                      },
                    }
                    setBigBox(prev => ({
                      ...prev,
                      ...cc,
                    }))
                  }}
                >
                  -
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="field">
          <label className="label level-add">
            Topics
            <button
              className="add"
              onClick={() => {
                let cc = {
                  campaignConfiguration: {
                    ...bigBox.campaignConfiguration,
                    bodies: [...bigBox.campaignConfiguration.bodies, ''],
                  },
                }
                setBigBox(prev => ({
                  ...prev,
                  ...cc,
                }))
              }}
            >
              +1
            </button>
          </label>
          <div className="control">
            {bigBox.campaignConfiguration.bodies.map((value, index) => (
              <div className="multi-string" key={index}>
                <input
                  onChange={e => {
                    e.preventDefault()
                    const newValues = [...bigBox.campaignConfiguration.bodies]
                    newValues[index] = e.target.value
                    let cc = {
                      campaignConfiguration: {
                        ...bigBox.campaignConfiguration,
                        bodies: [...newValues],
                      },
                    }
                    setBigBox(prev => ({
                      ...prev,
                      ...cc,
                    }))
                  }}
                  value={bigBox.campaignConfiguration.bodies[index]}
                  className="input"
                  type="text"
                  style={{ marginBottom: '0.5rem' }}
                />
                <button
                  className="remove"
                  onClick={() => {
                    const newValues = [...bigBox.campaignConfiguration.bodies]
                    newValues.splice(index, 1)
                    let cc = {
                      campaignConfiguration: {
                        ...bigBox.campaignConfiguration,
                        bodies: [...newValues],
                      },
                    }
                    setBigBox(prev => ({
                      ...prev,
                      ...cc,
                    }))
                  }}
                >
                  -
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="field">
          <label className="label">Scaling Algo:</label>
          <div className="control">
            <div className="select">
              <select
                onChange={e => {
                  e.preventDefault()
                  setBigBox(prev => ({
                    ...prev,
                    scalingAlgorithm: e.target.value,
                  }))
                }}
                value={bigBox.scalingAlgorithm}
              >
                {scalingOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <AnimatedButton
              onClick={() => {
                handleUpdateBigbox()
              }}
              className="is-success is-fullwidth"
              disabled={Object.keys(bigBoxUpdate).length < 2}
            >
              Update BigBox
            </AnimatedButton>
          </div>
          <div className="column">
            <AnimatedButton
              onClick={() => {
                setDebug(!debug)
              }}
              className="is-warning is-fullwidth"
            >
              Show Debug Variables
            </AnimatedButton>
          </div>
        </div>
        {debug && (
          <>
            <pre>{JSON.stringify(bigBox, null, 2)}</pre>
            <pre>{JSON.stringify(bigBoxUpdate, null, 2)}</pre>
          </>
        )}
      </div>
    )
  }
}

export default BigBoxConfig
