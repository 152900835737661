import React, { useState, useCallback } from 'react'
import OutreachLayout from '../layout/OutreachLayout'
import '../styles/Outreach.scss'
import SnowballReminder from './Outreach/SnowballReminder'
import AmbassadorInvite from './Outreach/AmbassadorInvite'
import AmbassadorReminder from './Outreach/AmbassadorReminder'

const isProd = process.env.NODE_ENV === 'production'

const Outerach = () => {
  /*
  Modal Logic
  Other components in the app use handleModal and pass it a string to enable specific modals.
  IE: onClick={() => handleModal('save-sample')}
  The string passed determines which modal child content is loaded into the Modal.
  */
  const [pageState, setPageState] = useState({ open: false, name: null })
  const handleModal = useCallback(pageName => {
    setPageState(pageState =>
      pageState?.name === pageName || !pageName
        ? { open: false, name: null }
        : { open: true, name: pageName }
    )
  }, [])

  // snackbarFunctions={snackbarFunctions}
  return (
    <OutreachLayout handleModal={handleModal}>
      {pageState?.name === 'snowball-reminder' && <SnowballReminder />}
      {pageState?.name === 'ambassador-invite' && <AmbassadorInvite />}
      {pageState?.name === 'ambassador-reminder' && <AmbassadorReminder />}
    </OutreachLayout>
  )
}

export default Outerach
