import React from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import useAuth, { AuthProvider } from './context/AuthContext'
import { FilterProvider } from './context/FilterContext'
import { MessageProvider } from './context/MessageContext'

import Navbar from './components/Navbar/Navbar'
import Fielding from './components/Fielding'
import Feasibility from './components/Feasibility'
import BigBox from './components/BigBox'
import Outreach from './components/Outreach'
import SignIn from './components/SignIn/SignIn'
import './styles/main.scss'

// TODO: Share loading messages from the Feasability
// and Fielding to the Navbar. As they will dynamically send messages that
// need to be loaded and portray on the Navbar. Create Context for messages ?
// OR set state in app and pass info to components ?

const App = () => {
  // As the router is wrapped with the provider,
  // we can use our hook to check for a logged in user.
  function AuthenticatedRoute({ roles, ...props }) {
    const { user } = useAuth()
    if (!user || Object.keys(user).length === 0) return <Redirect to="/login" />

    return <Route {...props} />
  }
  return (
    <BrowserRouter>
      <AuthProvider>
        <MessageProvider>
          <FilterProvider>
            <Navbar />
            <AuthenticatedRoute exact path="/" component={Fielding} />
            <AuthenticatedRoute path="/feasibility" component={Feasibility} />
            <AuthenticatedRoute path="/bigbox" component={BigBox} />
            <AuthenticatedRoute path="/outreach" component={Outreach} />
            <Route path="/login" component={SignIn} />
          </FilterProvider>
        </MessageProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
