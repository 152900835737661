import React, { useState, useEffect } from 'react'
import './ViewSamples.scss'
import api from '../../api'

const ViewSamples = ({ applyFilter, setLoadedSample }) => {
  const [search, setSearch] = useState('')
  const [samples, setSamples] = useState([])

  useEffect(() => {
    const getSamples = async () => {
      let response = await api.samples.getSamples()
      setSamples(response)
    }
    getSamples()
  }, [setSamples])

  let filteredData = search
    ? samples.filter(item => item.name.includes(search) || item.notes.includes(search))
    : samples

  return (
    <React.Fragment>
      <div className="box">
        <div>
          <label className="label">Search</label>
          <div className="control">
            <input
              onChange={e => setSearch(e.target.value)}
              className="input"
              type="text"
              placeholder="Fire"
            />
          </div>
        </div>
      </div>

      <div
        className="box"
        style={{
          paddingRight: '1.2rem',
          height: 'calc(75vh - 40px)',
          overflow: 'auto',
        }}
      >
        {filteredData &&
          filteredData.map(sample => (
            <div className="card box has-background-grey-lighter test" key={sample.name}>
              <div className="columns">
                <h1 className="title is-4 column has-background-success has-text-white">
                  <strong>{sample.name}</strong>
                </h1>
                <p className="column">{sample.notes && sample.notes}</p>
                <button
                  onClick={() => {
                    applyFilter(sample.filters)
                    setLoadedSample(sample.name)
                  }}
                  className="column is-1 has-text-black right-arrow"
                >
                  <strong>{String.fromCharCode(8594)}</strong>
                </button>
              </div>
            </div>
          ))}

        {filteredData.length < 1 && <h1 className="title is-5">Couldn't find any results...</h1>}
      </div>
    </React.Fragment>
  )
}

export default ViewSamples
